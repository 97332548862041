import { useMutation, useQuery } from 'react-query';

const { ReviewService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListReview = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['review-list', params], () => ReviewService.listReview({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddReview = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReviewService.addReview, {
    onSuccess,
    onError,
  });
};

const useViewReview = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('review-view', () => ReviewService.viewReview({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateReview = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReviewService.updateReview, {
    onSuccess,
    onError,
  });
};

const useStatusChangeReview = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReviewService.statusChangeReview, {
    onSuccess,
    onError,
  });
};

const useDeleteReview = (onSuccess, onError = onDefaultError) => {
  return useMutation(ReviewService.deleteReview, {
    onSuccess,
    onError,
  });
};

export {
  useListReview,
  useAddReview,
  useViewReview,
  useUpdateReview,
  useStatusChangeReview,
  useDeleteReview,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
const AboutTab = ({ t }) => {
  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/setting/about/about-banner">
            {t('page.general_setting_tab_about_banner_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/about/our-mission">
            {t('page.general_setting_tab_about_our_mission_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/about/our-vision">
            {t('page.general_setting_tab_about_our_vision_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/about/our-team">
            {t('page.general_setting_tab_our_team_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/about/our-offices">
            {t('page.general_setting_tab_our_offices_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/about/about-seo">
            {t('page.general_setting_tab_about_seo_section')}
          </NavLink>
        </Nav.Item>
      </Nav>
    </>
  );
};
AboutTab.propTypes = {
  t: PropTypes.func,
};

export { AboutTab };

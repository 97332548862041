import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
const ContactTab = ({ t }) => {
  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/setting/contact/general">{t('page.general_setting_about_section')}</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/contact/contact-seo">
            {t('page.general_setting_tab_contact_seo_section')}
          </NavLink>
        </Nav.Item>
      </Nav>
    </>
  );
};
ContactTab.propTypes = {
  t: PropTypes.func,
};

export { ContactTab };

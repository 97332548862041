import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { usePlacesWidget } from 'react-google-autocomplete';
import { toast } from 'react-toastify';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { useGetContactSettingData, useStoreSettingData } from 'hooks';
import 'assets/scss/page/_alert_confirm.scss';
import 'assets/scss/page/_edit_profile.scss';
import validationSchema from './ContactSettingValidation';
import { TNButton, AlertCommon } from 'common';
import { hideLoader } from 'store/features/loaderSlice';
import { SETTING_CONTACT_US } from 'config';
import { ContactTab } from './ContactTab';
import { DASHBOARD } from 'config';
const languages = ['en', 'sp'];

const ContactSettingPage = ({ t }) => {
  const bannerImageRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contactSettingData, setContactSettingData] = useState(null);

  const handleCancel = () => {
    if (formik.dirty && formik.dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(DASHBOARD);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(DASHBOARD);
    }
  };

  const { ref: firstENAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        // about_setting_about_our_offices_one_address
        formik.setFieldValue(
          'contact_setting_contact_info_one_en_address',
          place.formatted_address
        );
        formik.setFieldValue('contact_setting_contact_info_one_en_address_lat', latitude);
        formik.setFieldValue('contact_setting_contact_info_one_en_address_long', longitude);
      } else {
        formik.setFieldValue(
          'contact_setting_contact_info_one_en_address',
          formik.values.contact_setting_contact_info_one_en_address
        );
        formik.setFieldValue(
          'contact_setting_contact_info_one_en_address_lat',
          formik.values.contact_setting_contact_info_one_en_address_lat
        );
        formik.setFieldValue(
          'contact_setting_contact_info_one_en_address_long',
          formik.values.contact_setting_contact_info_one_en_address_long
        );
      }
    },
    options: {
      language: 'en',
      types: ['geocode'],
    },
  });

  const { ref: firstSPAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        // about_setting_about_our_offices_one_address
        formik.setFieldValue(
          'contact_setting_contact_info_one_sp_address',
          place.formatted_address
        );
        formik.setFieldValue('contact_setting_contact_info_one_sp_address_lat', latitude);
        formik.setFieldValue('contact_setting_contact_info_one_sp_address_long', longitude);
      } else {
        formik.setFieldValue(
          'contact_setting_contact_info_one_sp_address',
          formik.values.contact_setting_contact_info_one_sp_address
        );
        formik.setFieldValue(
          'contact_setting_contact_info_one_sp_address_lat',
          formik.values.contact_setting_contact_info_one_sp_address_lat
        );
        formik.setFieldValue(
          'contact_setting_contact_info_one_sp_address_long',
          formik.values.contact_setting_contact_info_one_sp_address_long
        );
      }
    },
    options: {
      language: 'es',
      types: ['geocode'],
    },
  });

  const { ref: secondENAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        formik.setFieldValue(
          'contact_setting_contact_info_two_en_address',
          place.formatted_address
        );
        formik.setFieldValue('contact_setting_contact_info_two_en_address_lat', latitude);
        formik.setFieldValue('contact_setting_contact_info_two_en_address_long', longitude);
      } else {
        formik.setFieldValue(
          'contact_setting_contact_info_two_en_address',
          formik.values.contact_setting_contact_info_two_en_address
        );
        formik.setFieldValue(
          'contact_setting_contact_info_two_en_address_lat',
          formik.values.contact_setting_contact_info_two_en_address_lat
        );
        formik.setFieldValue(
          'contact_setting_contact_info_two_en_address_long',
          formik.values.contact_setting_contact_info_two_en_address_long
        );
      }
    },
    options: {
      language: 'en',
      types: ['geocode'],
    },
  });

  const { ref: secondSPAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        formik.setFieldValue(
          'contact_setting_contact_info_two_sp_address',
          place.formatted_address
        );
        formik.setFieldValue('contact_setting_contact_info_two_sp_address_lat', latitude);
        formik.setFieldValue('contact_setting_contact_info_two_sp_address_long', longitude);
      } else {
        formik.setFieldValue(
          'contact_setting_contact_info_two_sp_address',
          formik.values.contact_setting_contact_info_two_sp_address
        );
        formik.setFieldValue(
          'contact_setting_contact_info_two_sp_address_lat',
          formik.values.contact_setting_contact_info_two_sp_address_lat
        );
        formik.setFieldValue(
          'contact_setting_contact_info_two_sp_address_long',
          formik.values.contact_setting_contact_info_two_sp_address_long
        );
      }
    },
    options: {
      language: 'es',
      types: ['geocode'],
    },
  });

  const { refetch } = useGetContactSettingData(
    {
      tag: 'contact_setting_contact',
    },
    ({ data: setting }) => {
      const dataStore = {};
      formik.values.contact_setting_contact_banner_image =
        setting?.contact_setting_contact_banner_image;
      // contact_setting_contact_banner_en_title:
      //   setting?.contact_setting_contact_banner_title['en'],
      // contact_setting_contact_banner_sp_title:
      //   setting?.contact_setting_contact_banner_title['sp'],
      // contact_setting_contact_info_request_en_title:
      //   setting?.contact_setting_contact_info_request_title['en'],
      // contact_setting_contact_info_request_sp_title:
      //   setting?.contact_setting_contact_info_request_title['sp'],
      // contact_setting_contact_info_en_description:
      //   setting?.contact_setting_contact_info_description['en'],
      // contact_setting_contact_info_sp_description:
      //   setting?.contact_setting_contact_info_description['sp'],
      formik.values.contact_setting_contact_info_one_en_address =
        setting?.contact_setting_contact_info_one_address?.['en'] || '';
      formik.values.contact_setting_contact_info_one_sp_address =
        setting?.contact_setting_contact_info_one_address?.['sp'] || '';

      formik.values.contact_setting_contact_info_one_en_address_lat =
        setting?.contact_setting_contact_info_one_address_lat?.['en'] || 36.6588474;

      formik.values.contact_setting_contact_info_one_sp_address_lat =
        setting?.contact_setting_contact_info_one_address_lat?.['sp'] || -4.5811173;

      formik.values.contact_setting_contact_info_one_en_address_long =
        setting?.contact_setting_contact_info_one_address_long?.['sp'] || 36.6588474;

      formik.values.contact_setting_contact_info_one_sp_address_long =
        setting?.contact_setting_contact_info_one_address_long?.['sp'] || -4.5811173;

      formik.values.contact_setting_contact_info_two_en_address =
        setting?.contact_setting_contact_info_two_address?.['en'] || '';

      formik.values.contact_setting_contact_info_two_sp_address =
        setting?.contact_setting_contact_info_two_address?.['sp'] || '';

      // contact_setting_contact_info_two_address_lat:
      //   setting?.contact_setting_contact_info_two_address_lat || 41.3306391,
      // contact_setting_contact_info_two_address_long:
      //   setting?.contact_setting_contact_info_two_address_long || 2.0022707,

      formik.values.contact_setting_contact_info_two_en_address_lat =
        setting?.contact_setting_contact_info_two_address_lat?.['en'] || 41.3306391;
      formik.values.contact_setting_contact_info_two_sp_address_lat =
        setting?.contact_setting_contact_info_two_address_lat?.['sp'] || 2.0022707;

      formik.values.contact_setting_contact_info_two_en_address_long =
        setting?.contact_setting_contact_info_two_address_long?.['sp'] || 41.3306391;
      formik.values.contact_setting_contact_info_two_sp_address_long =
        setting?.contact_setting_contact_info_two_address_long?.['sp'] || 2.0022707;

      formik.values.contact_setting_contact_info_phone =
        setting?.contact_setting_contact_info_phone;
      formik.values.contact_setting_contact_info_email =
        setting?.contact_setting_contact_info_email;

      languages.forEach((lang) => {
        formik.values[`contact_setting_contact_one_address_${lang}_title`] =
          setting.contact_setting_contact_one_address_title?.[lang] || '';
        formik.values[`contact_setting_contact_two_address_${lang}_title`] =
          setting.contact_setting_contact_two_address_title?.[lang] || '';

        formik.values[`contact_setting_contact_banner_${lang}_title`] =
          setting.contact_setting_contact_banner_title?.[lang] || '';
        formik.values[`contact_setting_contact_info_request_${lang}_title`] =
          setting.contact_setting_contact_info_request_title?.[lang] || '';
        formik.values[`contact_setting_contact_info_${lang}_description`] =
          setting.contact_setting_contact_info_description?.[lang] || '';
      });

      setContactSettingData(dataStore);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      contact_setting_contact_banner_image: '',
      // contact_setting_contact_banner_en_title:
      //   contactSettingData?.contact_setting_contact_banner_en_title,
      // contact_setting_contact_banner_sp_title:
      //   contactSettingData?.contact_setting_contact_banner_sp_title,
      // contact_setting_contact_info_request_en_title:
      //   contactSettingData?.contact_setting_contact_info_request_en_title,
      // contact_setting_contact_info_request_sp_title:
      //   contactSettingData?.contact_setting_contact_info_request_sp_title,
      // contact_setting_contact_info_en_description:
      //   contactSettingData?.contact_setting_contact_info_en_description,
      // contact_setting_contact_info_sp_description:
      //   contactSettingData?.contact_setting_contact_info_sp_description,
      contact_setting_contact_info_one_en_address: '',
      contact_setting_contact_info_one_sp_address: '',

      contact_setting_contact_info_one_en_address_lat: '',
      contact_setting_contact_info_one_en_address_long: '',

      contact_setting_contact_info_one_sp_address_lat: '',
      contact_setting_contact_info_one_sp_address_long: '',

      contact_setting_contact_info_one_address_long: '',

      contact_setting_contact_info_two_en_address: '',
      contact_setting_contact_info_two_sp_address: '',
      contact_setting_contact_info_phone: '',
      contact_setting_contact_info_email: '',

      ...languages.reduce((acc, lang) => {
        acc[`contact_setting_contact_one_address_${lang}_title`] = '';
        acc[`contact_setting_contact_two_address_${lang}_title`] = '';

        acc[`contact_setting_contact_banner_${lang}_title`] = '';
        acc[`contact_setting_contact_info_request_${lang}_title`] = '';
        acc[`contact_setting_contact_info_${lang}_description`] = '';
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      let tranFormValue = {
        contact_setting_contact_banner_image: values.contact_setting_contact_banner_image,
        contact_setting_contact_banner_title: {},
        contact_setting_contact_info_request_title: {},
        contact_setting_contact_info_description: {},
        contact_setting_contact_one_address_title: {},
        contact_setting_contact_two_address_title: {},

        contact_setting_contact_info_one_address: {
          ['en']: values.contact_setting_contact_info_one_en_address,
          ['sp']: values.contact_setting_contact_info_one_sp_address,
        },
        contact_setting_contact_info_two_address: {
          ['en']: values.contact_setting_contact_info_two_en_address,
          ['sp']: values.contact_setting_contact_info_two_sp_address,
        },
        contact_setting_contact_info_one_address_lat: {
          ['en']: values.contact_setting_contact_info_one_en_address_lat || 36.6588474,
          ['sp']: values.contact_setting_contact_info_one_sp_address_lat || 36.6588474,
        },
        contact_setting_contact_info_two_address_lat: {
          ['en']: values.contact_setting_contact_info_two_en_address_lat || 41.3306391,
          ['sp']: values.contact_setting_contact_info_two_sp_address_lat || 41.3306391,
        },

        contact_setting_contact_info_one_address_long: {
          ['en']: values.contact_setting_contact_info_one_en_address_long || -4.5811173,
          ['sp']: values.contact_setting_contact_info_one_sp_address_long || -4.5811173,
        },
        contact_setting_contact_info_two_address_long: {
          ['en']: values.contact_setting_contact_info_two_en_address_long || 2.0022707,
          ['sp']: values.contact_setting_contact_info_two_sp_address_long || 2.0022707,
        },

        contact_setting_contact_info_phone: values.contact_setting_contact_info_phone,
        contact_setting_contact_info_email: values.contact_setting_contact_info_email,

        // contact_setting_contact_info_one_en_address_lat:
        //   values.contact_setting_contact_info_one_en_address_lat,
        // contact_setting_contact_info_one_en_address_long:
        //   values.contact_setting_contact_info_one_en_address_long,

        // contact_setting_contact_info_one_sp_address_lat:
        //   values.contact_setting_contact_info_one_sp_address_lat,
        // contact_setting_contact_info_one_sp_address_long:
        //   values.contact_setting_contact_info_one_sp_address_long,

        // contact_setting_contact_info_two_address_lat:
        //   values.contact_setting_contact_info_two_address_lat,
        // contact_setting_contact_info_two_address_long:
        //   values.contact_setting_contact_info_two_address_long,
      };

      languages.forEach((lang) => {
        tranFormValue.contact_setting_contact_banner_title[lang] =
          values[`contact_setting_contact_banner_${lang}_title`] || '';
        tranFormValue.contact_setting_contact_info_request_title[lang] =
          values[`contact_setting_contact_info_request_${lang}_title`] || '';
        tranFormValue.contact_setting_contact_info_description[lang] =
          values[`contact_setting_contact_info_${lang}_description`] || '';
        tranFormValue.contact_setting_contact_one_address_title[lang] =
          values[`contact_setting_contact_one_address_${lang}_title`] || '';
        tranFormValue.contact_setting_contact_two_address_title[lang] =
          values[`contact_setting_contact_two_address_${lang}_title`] || '';

        // formik.values[`contact_setting_contact_one_address_${lang}_title`] = '';
        // formik.values[`contact_setting_contact_two_address_${lang}_title`] = '';

        // formik.values[`contact_setting_contact_info_request_${lang}_title`] =
        //   values[`contact_setting_contact_info_request_title`];
        // formik.values[`contact_setting_contact_info_${lang}_description`] =
        //   values[contact_setting_contact_info_description];
      });

      // if (
      //   values.contact_setting_contact_info_one_address_lat ==
      //     contactSettingData?.contact_setting_contact_info_one_address_lat &&
      //   values.contact_setting_contact_info_one_address_long ==
      //     contactSettingData?.contact_setting_contact_info_one_address_long
      // ) {
      //   tranFormValue.contact_setting_contact_info_one_address =
      //     contactSettingData?.contact_setting_contact_info_one_address;
      // } else {
      //   tranFormValue.contact_setting_contact_info_one_address =
      //     values.contact_setting_contact_info_one_address;
      // }

      // if (
      //   values.contact_setting_contact_info_two_address_lat ==
      //     contactSettingData?.contact_setting_contact_info_two_address_lat &&
      //   values.contact_setting_contact_info_two_address_long ==
      //     contactSettingData?.contact_setting_contact_info_two_address_long
      // ) {
      //   tranFormValue.contact_setting_contact_info_two_address =
      //     contactSettingData?.contact_setting_contact_info_two_address;
      // } else {
      //   tranFormValue.contact_setting_contact_info_two_address =
      //     values.contact_setting_contact_info_two_address;
      // }
      doStoreSettingData(tranFormValue);
    },
  });

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    // refetch();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  });

  const imagePreviewFormik = (file) => {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  };

  return (
    <>
      <ContactTab t={t} />
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.contact_setting_contact_banner_title_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`contact_setting_contact_banner_${lang}_title`] &&
                            formik.errors[`contact_setting_contact_banner_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[`contact_setting_contact_banner_${lang}_title`] &&
                                !formik.errors[`contact_setting_contact_banner_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`contact_setting_contact_banner_${lang}_title`}
                          placeholder={t('page.contact_setting_contact_banner_title_placeholder')}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `contact_setting_contact_banner_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`contact_setting_contact_banner_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.contact_setting_contact_banner_en_title &&
                            formik.errors.contact_setting_contact_banner_en_title && (
                              <div>{t(formik.errors.contact_setting_contact_banner_en_title)}</div>
                            )} */}
                          {formik.touched[`contact_setting_contact_banner_${lang}_title`] &&
                          formik.errors[`contact_setting_contact_banner_${lang}_title`] ? (
                            <div>
                              {t(formik.errors[`contact_setting_contact_banner_${lang}_title`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_banner_title_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_banner_sp_title &&
                        formik.errors.contact_setting_contact_banner_sp_title
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_banner_sp_title
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_banner_sp_title"
                      placeholder={t('page.contact_setting_contact_banner_title_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_banner_sp_title',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_banner_sp_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_banner_sp_title &&
                        formik.errors.contact_setting_contact_banner_sp_title && (
                          <div>{t(formik.errors.contact_setting_contact_banner_sp_title)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                </Col>
                <Col lg={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_banner_image_label')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={bannerImageRef}
                      name="contact_setting_contact_banner_image"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        const allowedFileTypes = [
                          'image/jpeg',
                          'image/jpg',
                          'image/png',
                          'image/svg+xml',
                        ];
                        if (file && allowedFileTypes.includes(file.type)) {
                          formik.setFieldValue(
                            'contact_setting_contact_banner_image',
                            event.currentTarget.files[0]
                          );
                        } else {
                          toast.error(t('page.invalid_upload_format_of_image'));
                          event.target.value = '';
                        }
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => bannerImageRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.contact_setting_contact_banner_image && (
                        <div>
                          <img
                            src={imagePreviewFormik(
                              formik.values.contact_setting_contact_banner_image
                            )}
                            width="100px"
                            alt="bannerImageRef"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('contact_setting_contact_banner_image', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.contact_setting_contact_banner_image &&
                    formik.errors.contact_setting_contact_banner_image ? (
                      <div>{t(formik.errors.contact_setting_contact_banner_image)}</div>
                    ) : null}
                  </div>
                </Col>

                {languages.map((lang) => (
                  <>
                    <Form.Group>
                      <Form.Label className="field-label field-label-top">
                        {t('page.contact_setting_contact_info_request_title_label')} [
                        {lang.toUpperCase()}]
                      </Form.Label>
                      <Form.Control
                        className={
                          'form-field-height ' +
                          (formik.touched[`contact_setting_contact_info_request_${lang}_title`] &&
                          formik.errors[`contact_setting_contact_info_request_${lang}_title`]
                            ? 'form-field-error'
                            : formik.touched[
                                `contact_setting_contact_info_request_${lang}_title`
                              ] &&
                              !formik.errors[`contact_setting_contact_info_request_${lang}_title`]
                            ? 'form-field-success'
                            : '')
                        }
                        type="text"
                        name={`contact_setting_contact_info_request_${lang}_title`}
                        placeholder={t(
                          'page.contact_setting_contact_info_request_title_placeholder'
                        )}
                        onChange={(e) => {
                          const value = e.target.value;
                          formik.setFieldValue(
                            `contact_setting_contact_info_request_${lang}_title`,
                            value.trimStart()
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values[`contact_setting_contact_info_request_${lang}_title`]}
                      />
                      <div className="form-field-error-text">
                        {/* {formik.touched.contact_setting_contact_info_request_en_title &&
                          formik.errors.contact_setting_contact_info_request_en_title && (
                            <div>
                              {t(formik.errors.contact_setting_contact_info_request_en_title)}
                            </div>
                          )} */}
                        {formik.touched[`contact_setting_contact_info_request_${lang}_title`] &&
                        formik.errors[`contact_setting_contact_info_request_${lang}_title`] ? (
                          <div>
                            {t(formik.errors[`contact_setting_contact_info_request_${lang}_title`])}
                          </div>
                        ) : null}
                      </div>
                    </Form.Group>
                  </>
                ))}
                {/* <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_request_title_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_info_request_sp_title &&
                        formik.errors.contact_setting_contact_info_request_sp_title
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_request_sp_title
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_request_title"
                      placeholder={t('page.contact_setting_contact_info_request_title_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_request_sp_title',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_request_sp_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_request_sp_title &&
                        formik.errors.contact_setting_contact_info_request_sp_title && (
                          <div>
                            {t(formik.errors.contact_setting_contact_info_request_sp_title)}
                          </div>
                        )}
                    </div>
                  </Form.Group>
                </Col> */}

                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.contact_setting_contact_info_description_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`contact_setting_contact_info_${lang}_description`] &&
                            formik.errors[`contact_setting_contact_info_${lang}_description`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `contact_setting_contact_info_${lang}_description`
                                ] &&
                                !formik.errors[`contact_setting_contact_info_${lang}_description`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`contact_setting_contact_info_${lang}_description`}
                          placeholder={t(
                            'page.contact_setting_contact_info_description_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `contact_setting_contact_info_${lang}_description`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`contact_setting_contact_info_${lang}_description`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.contact_setting_contact_info_en_description &&
                            formik.errors.contact_setting_contact_info_en_description && (
                              <div>
                                {t(formik.errors.contact_setting_contact_info_en_description)}
                              </div>
                            )} */}

                          {formik.touched[`contact_setting_contact_info_${lang}_description`] &&
                          formik.errors[`contact_setting_contact_info_${lang}_description`] ? (
                            <div>
                              {t(formik.errors[`contact_setting_contact_info_${lang}_description`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_description_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_info_sp_description &&
                        formik.errors.contact_setting_contact_info_sp_description
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_sp_description
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_sp_description"
                      placeholder={t('page.contact_setting_contact_info_description_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_sp_description',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_sp_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_sp_description &&
                        formik.errors.contact_setting_contact_info_sp_description && (
                          <div>{t(formik.errors.contact_setting_contact_info_sp_description)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                </Col>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.contact_setting_contact_info_one_address_title')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`contact_setting_contact_one_address_${lang}_title`] &&
                            formik.errors[`contact_setting_contact_one_address_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `contact_setting_contact_one_address_${lang}_title`
                                ] &&
                                !formik.errors[`contact_setting_contact_one_address_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`contact_setting_contact_one_address_${lang}_title`}
                          placeholder={t(
                            'page.contact_setting_contact_info_one_address_title_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `contact_setting_contact_one_address_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`contact_setting_contact_one_address_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.contact_setting_contact_info_en_description &&
                            formik.errors.contact_setting_contact_info_en_description && (
                              <div>
                                {t(formik.errors.contact_setting_contact_info_en_description)}
                              </div>
                            )} */}

                          {formik.touched[`contact_setting_contact_one_address_${lang}_title`] &&
                          formik.errors[`contact_setting_contact_one_address_${lang}_title`] ? (
                            <div>
                              {t(
                                formik.errors[`contact_setting_contact_one_address_${lang}_title`]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}
                </Col>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_one_address_label')} [EN]
                    </Form.Label>
                    <Form.Control
                      ref={firstENAddress}
                      className={
                        formik.touched.contact_setting_contact_info_one_en_address &&
                        formik.errors.contact_setting_contact_info_one_en_address
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_one_en_address
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_one_en_address"
                      placeholder={t('page.contact_setting_contact_info_one_address_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_one_en_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_one_en_address}
                      defaultValue="C/ Reserva, 16. Alhaurin de la Torre. 29130 Málaga"
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_one_en_address &&
                        formik.errors.contact_setting_contact_info_one_en_address && (
                          <div>{t(formik.errors.contact_setting_contact_info_one_en_address)}</div>
                        )}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_one_address_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      ref={firstSPAddress}
                      className={
                        formik.touched.contact_setting_contact_info_one_sp_address &&
                        formik.errors.contact_setting_contact_info_one_sp_address
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_one_sp_address
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_one_sp_address"
                      placeholder={t('page.contact_setting_contact_info_one_address_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_one_sp_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_one_sp_address}
                      defaultValue="C/ Reserva, 16. Alhaurin de la Torre. 29130 Málaga"
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_one_sp_address &&
                        formik.errors.contact_setting_contact_info_one_sp_address && (
                          <div>{t(formik.errors.contact_setting_contact_info_one_sp_address)}</div>
                        )}
                    </div>
                  </Form.Group>
                </Col>

                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.contact_setting_contact_info_two_address_title')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`contact_setting_contact_two_address_${lang}_title`] &&
                            formik.errors[`contact_setting_contact_two_address_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `contact_setting_contact_two_address_${lang}_title`
                                ] &&
                                !formik.errors[`contact_setting_contact_two_address_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`contact_setting_contact_two_address_${lang}_title`}
                          placeholder={t(
                            'page.contact_setting_contact_info_two_address_title_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `contact_setting_contact_two_address_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`contact_setting_contact_two_address_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.contact_setting_contact_info_en_description &&
                            formik.errors.contact_setting_contact_info_en_description && (
                              <div>
                                {t(formik.errors.contact_setting_contact_info_en_description)}
                              </div>
                            )} */}

                          {formik.touched[`contact_setting_contact_two_address_${lang}_title`] &&
                          formik.errors[`contact_setting_contact_two_address_${lang}_title`] ? (
                            <div>
                              {t(
                                formik.errors[`contact_setting_contact_two_address_${lang}_title`]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}
                </Col>

                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_two_address_label')} [EN]
                    </Form.Label>
                    <Form.Control
                      ref={secondENAddress}
                      className={
                        formik.touched.contact_setting_contact_info_two_en_address &&
                        formik.errors.contact_setting_contact_info_two_en_address
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_two_en_address
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_two_en_address"
                      placeholder={t('page.contact_setting_contact_info_two_address_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_two_en_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_two_en_address}
                      defaultValue="Travessía Prat de la Riba, 118, 08849 Barcelona"
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_two_en_address &&
                        formik.errors.contact_setting_contact_info_two_en_address && (
                          <div>{t(formik.errors.contact_setting_contact_info_two_en_address)}</div>
                        )}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_two_address_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      ref={secondSPAddress}
                      className={
                        formik.touched.contact_setting_contact_info_two_sp_address &&
                        formik.errors.contact_setting_contact_info_two_sp_address
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_two_sp_address
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_two_sp_address"
                      placeholder={t('page.contact_setting_contact_info_two_address_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_two_sp_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_two_sp_address}
                      defaultValue="Travessía Prat de la Riba, 118, 08849 Barcelona"
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_two_sp_address &&
                        formik.errors.contact_setting_contact_info_two_sp_address && (
                          <div>{t(formik.errors.contact_setting_contact_info_two_sp_address)}</div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_phone_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_info_phone &&
                        formik.errors.contact_setting_contact_info_phone
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_phone
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_phone"
                      placeholder={t('page.contact_setting_contact_info_phone_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_phone',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_phone}
                      onInput={(e) => {
                        // var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        // e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        var value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
                        var formattedValue = '';

                        // Check for the different possible formats and apply the mask
                        if (value.length > 0) {
                          formattedValue = '(' + value.substring(0, 3);
                          if (value.length > 3) {
                            formattedValue += ') ' + value.substring(3, 6);
                            if (value.length > 6) {
                              formattedValue += '-' + value.substring(6, 10);
                            }
                          }
                        }
                        e.target.value = formattedValue;
                      }}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_phone &&
                        formik.errors.contact_setting_contact_info_phone && (
                          <div>{t(formik.errors.contact_setting_contact_info_phone)}</div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_info_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_info_email &&
                        formik.errors.contact_setting_contact_info_email
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_info_email
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_info_email"
                      placeholder={t('page.contact_setting_contact_info_email_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_info_email',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_info_email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_info_email &&
                        formik.errors.contact_setting_contact_info_email && (
                          <div>{t(formik.errors.contact_setting_contact_info_email)}</div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
ContactSettingPage.propTypes = {
  t: PropTypes.func,
};

export { ContactSettingPage };

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import validationSchema from './HomeSeoSettingValidation';
import { useFormik } from 'formik';
import { useStoreSettingData, useGetHomeSettingData } from 'hooks';
import { addSetting } from 'store/features/settingSlice';
import { TNButton } from 'common/components/TNButton';
import { confirmAlert } from 'react-confirm-alert';
import { AlertCommon } from 'common/components/AlertCommon';
import 'assets/scss/page/_generalsetting.scss';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
const languages = ['en', 'sp']; // Add more languages here if needed

const HomeSeoSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [homeSettingData, setHomeSettingData] = useState(null);
  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);
  useGetHomeSettingData(
    {
      tag: 'home_setting_seo',
    },
    ({ data: setting }) => {
      const dataStore = {};
      languages.forEach((lang) => {
        dataStore[`home_setting_seo_${lang}_title`] = setting.home_setting_seo_title?.[lang] || '';
        dataStore[`home_setting_seo_${lang}_description`] =
          setting.home_setting_seo_description?.[lang] || '';
      });
      setHomeSettingData(dataStore);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    const dataStore = {};
    languages.forEach((lang) => {
      dataStore[`home_setting_seo_${lang}_title`] =
        response.data.home_setting_seo_title?.[lang] || '';
      dataStore[`home_setting_seo_${lang}_description`] =
        response.data.home_setting_seo_description?.[lang] || '';
    });
    dispatch(addSetting(dataStore));
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  });

  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: {
      // Dynamically setting initial values for each language
      ...languages.reduce((acc, lang) => {
        acc[`home_setting_seo_${lang}_title`] =
          homeSettingData?.[`home_setting_seo_${lang}_title`] || '';
        acc[`home_setting_seo_${lang}_description`] =
          homeSettingData?.[`home_setting_seo_${lang}_description`] || '';
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        home_setting_seo_title: {},
        home_setting_seo_description: {},
      };

      // Loop over languages and dynamically build the transformedValues object
      languages.forEach((lang) => {
        transformedValues.home_setting_seo_title[lang] =
          values[`home_setting_seo_${lang}_title`] || '';
        transformedValues.home_setting_seo_description[lang] =
          values[`home_setting_seo_${lang}_description`] || '';
      });

      console.log('Transformed Values:', transformedValues);

      doStoreSettingData(transformedValues);
    },
  });

  useEffect(() => {
    if (homeSettingData) {
      // Prepare an object to hold the initial form values
      const initialValues = {};

      // Loop through each language and dynamically set form values
      languages.forEach((lang) => {
        initialValues[`home_setting_seo_${lang}_title`] =
          homeSettingData?.[`home_setting_seo_${lang}_title`] || '';
        initialValues[`home_setting_seo_${lang}_description`] =
          homeSettingData?.[`home_setting_seo_${lang}_description`] || '';
      });

      // Set the values in Formik
      formik.setValues(initialValues);
    }
  }, [homeSettingData]);

  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/setting/home/home-banner">
            {t('page.general_setting_tab_home_banner_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-motorcycle-lineup">
            {t('page.general_setting_tab_home_motorcycle_lineup_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-why-choose-us">
            {t('page.general_setting_tab_home_why_choose_us_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-footer">
            {t('page.general_setting_tab_home_footer_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-seo">
            {t('page.general_setting_tab_home_seo_info_section')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group key={lang}>
                        <Form.Label className="field-label field-label-top">
                          {t('page.home_setting_seo_title_label')} [{lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched[`home_setting_seo_${lang}_title`] &&
                            formik.errors[`home_setting_seo_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[`home_setting_seo_${lang}_title`]
                              ? 'form-field-success'
                              : ''
                          }
                          type="text"
                          name={`home_setting_seo_${lang}_title`}
                          placeholder={t('page.home_setting_seo_title_placeholder')}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `home_setting_seo_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`home_setting_seo_${lang}_title`] || ''}
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`home_setting_seo_${lang}_title`] &&
                            formik.errors[`home_setting_seo_${lang}_title`] && (
                              <div>{t(formik.errors[`home_setting_seo_${lang}_title`])}</div>
                            )}
                        </div>
                      </Form.Group>

                      <Form.Group key={lang}>
                        <Form.Label className="field-label field-label-top">
                          {t('page.home_setting_seo_description_label')} [{lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched[`home_setting_seo_${lang}_description`] &&
                            formik.errors[`home_setting_seo_${lang}_description`]
                              ? 'form-field-error'
                              : formik.touched[`home_setting_seo_${lang}_description`]
                              ? 'form-field-success'
                              : ''
                          }
                          as="textarea"
                          rows={3}
                          name={`home_setting_seo_${lang}_description`}
                          placeholder={t('page.home_setting_seo_description_placeholder')}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `home_setting_seo_${lang}_description`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`home_setting_seo_${lang}_description`] || ''}
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`home_setting_seo_${lang}_description`] &&
                            formik.errors[`home_setting_seo_${lang}_description`] && (
                              <div>{t(formik.errors[`home_setting_seo_${lang}_description`])}</div>
                            )}
                        </div>
                      </Form.Group>
                    </>
                  ))}
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

HomeSeoSetting.propTypes = {
  t: PropTypes.func,
};

export { HomeSeoSetting };

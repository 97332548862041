import client from 'libs/HttpClient';
class MotorcycleService {
  static listMotorcycle({ params }) {
    return client.get(`motorcycle/list`, { params });
  }
  static addMotorcycle(request) {
    return client.post(`motorcycle/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewMotorcycle(params) {
    return client.get(`motorcycle/get`, { params });
  }
  static updateMotorcycle(request) {
    return client.put(`motorcycle/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteMotorcycle(params) {
    return client.delete(`motorcycle/delete`, { params });
  }
  static updateMotorcycleStatus(request) {
    return client.put(`motorcycle/update-status`, request);
  }
  static viewMotorcycleName(request) {
    return client.get(`motorcycle/get-motorcycle`, request);
  }
}
export { MotorcycleService };

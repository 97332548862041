import client from 'libs/HttpClient';
class NotificationsService {
  static getNotificationsData({ params }) {
    const page_no = params[0];
    return client.get(`notifications/get?page=${page_no}`);
  }
  static notificationCount() {
    return client.get('notifications/notification-count');
  }
  static deleteNotification(request) {
    return client.post('notifications/delete', request);
  }
  static readNotification(request) {
    return client.post('notifications/read', request);
  }
}

export { NotificationsService };

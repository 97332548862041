import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAboutSettingData, useStoreSettingData } from 'hooks';
import { AboutTab } from './AboutTab';
import validationSchema from './AboutOurTeamSettingValidation';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { AlertCommon, TNButton } from 'common';
import { confirmAlert } from 'react-confirm-alert';
const languages = ['en', 'sp'];

const AboutOurTeamSetting = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aboutSettingData, setAboutSettingData] = useState(null);

  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);

  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    // refetch();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  });

  const { refetch } = useGetAboutSettingData(
    {
      tag: 'about_setting_about_our_team',
    },
    ({ data: setting }) => {
      const dataStore = {
        // about_setting_about_our_team_title: setting.about_setting_about_our_team_title,
        // about_setting_about_our_team_description: setting.about_setting_about_our_team_description,
        // about_setting_about_our_team_en_title:
        //   setting?.about_setting_about_our_team_title?.['en'] || '',
        // about_setting_about_our_team_sp_title:
        //   setting?.about_setting_about_our_team_title?.['sp'] || '',
        // about_setting_about_our_team_en_description:
        //   setting?.about_setting_about_our_team_description?.['en'] || '',
        // about_setting_about_our_team_sp_description:
        //   setting?.about_setting_about_our_team_description?.['sp'] || '',
      };
      languages.forEach((lang) => {
        formik.values[`about_setting_about_our_team_${lang}_title`] =
          setting.about_setting_about_our_team_title?.[lang] || '';
        formik.values[`about_setting_about_our_team_${lang}_description`] =
          setting.about_setting_about_our_team_description?.[lang] || '';
      });
      setAboutSettingData(dataStore);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      // about_setting_about_our_team_title: aboutSettingData?.about_setting_about_our_team_title,
      // about_setting_about_our_team_description:
      //   aboutSettingData?.about_setting_about_our_team_description,

      // about_setting_about_our_team_en_title:
      //   aboutSettingData?.about_setting_about_our_team_en_title,
      // about_setting_about_our_team_sp_title:
      //   aboutSettingData?.about_setting_about_our_team_sp_title,
      // about_setting_about_our_team_en_description:
      //   aboutSettingData?.about_setting_about_our_team_en_description,
      // about_setting_about_our_team_sp_description:
      //   aboutSettingData?.about_setting_about_our_team_sp_description,

      ...languages.reduce((acc, lang) => {
        acc[`about_setting_about_our_team_${lang}_title`] = '';
        acc[`about_setting_about_our_team_${lang}_description`] = '';
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      // const transformedValues = {
      //   about_setting_about_our_team_title: {
      //     ['en']: values.about_setting_about_our_team_en_title,
      //     ['sp']: values.about_setting_about_our_team_sp_title,
      //   },
      //   about_setting_about_our_team_description: {
      //     ['en']: values.about_setting_about_our_team_en_description,
      //     ['sp']: values.about_setting_about_our_team_sp_description,
      //   },
      // };
      // doStoreSettingData(transformedValues);

      const transformedValues = {
        about_setting_about_our_team_title: {},
        about_setting_about_our_team_description: {},
      };

      languages.forEach((lang) => {
        transformedValues.about_setting_about_our_team_title[lang] =
          values[`about_setting_about_our_team_${lang}_title`] || '';
        transformedValues.about_setting_about_our_team_description[lang] =
          values[`about_setting_about_our_team_${lang}_description`] || '';
      });

      doStoreSettingData(transformedValues);
    },
  });
  return (
    <>
      <AboutTab t={t} />
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_our_team_title_label')} [{lang.toUpperCase()}
                          ]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`about_setting_about_our_team_${lang}_title`] &&
                            formik.errors[`about_setting_about_our_team_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[`about_setting_about_our_team_${lang}_title`] &&
                                !formik.errors[`about_setting_about_our_team_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`about_setting_about_our_team_${lang}_title`}
                          placeholder={t('page.about_setting_about_our_team_title_placeholder')}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_our_team_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`about_setting_about_our_team_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`about_setting_about_our_team_${lang}_title`] &&
                          formik.errors[`about_setting_about_our_team_${lang}_title`] ? (
                            <div>
                              {t(formik.errors[`about_setting_about_our_team_${lang}_title`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_our_team_description_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched[`about_setting_about_our_team_${lang}_description`] &&
                            formik.errors[`about_setting_about_our_team_${lang}_description`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `about_setting_about_our_team_${lang}_description`
                                ] &&
                                !formik.errors[`about_setting_about_our_team_${lang}_description`]
                              ? 'form-field-success'
                              : ''
                          }
                          as="textarea"
                          rows={3}
                          type="text"
                          name={`about_setting_about_our_team_${lang}_description`}
                          placeholder={t(
                            'page.about_setting_about_our_team_description_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_our_team_${lang}_description`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`about_setting_about_our_team_${lang}_description`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.about_setting_about_our_team_en_description &&
                            formik.errors.about_setting_about_our_team_en_description && (
                              <div>
                                {t(formik.errors.about_setting_about_our_team_en_description)}
                              </div>
                            )} */}

                          {formik.touched[`about_setting_about_our_team_${lang}_description`] &&
                          formik.errors[`about_setting_about_our_team_${lang}_description`] ? (
                            <div>
                              {t(formik.errors[`about_setting_about_our_team_${lang}_description`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
AboutOurTeamSetting.propTypes = {
  t: PropTypes.func,
};

export { AboutOurTeamSetting };

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import { routes } from 'config';
import { Helmet } from 'react-helmet';
import { useGetSettingData } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { settingData } from 'store';
import { addSetting } from 'store';

const ListCouponCodePage = React.lazy(() => import('./ListCouponCodePage'));
const AddCouponCodePage = React.lazy(() => import('./AddCouponCodePage'));
const EditCouponCodePage = React.lazy(() => import('./EditCouponCodePage'));

const CouponCodeRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>{getSettingData.home_page_general_seo_title || 'Triumph Admin'} | Coupon Code</title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="description" content={getSettingData.home_page_general_seo_description || ''} />
        <meta
          name="og:description"
          content={getSettingData.home_page_general_seo_description || ''}
        />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo || ''}`} />
      </Helmet>
      <Routes>
        <Route path={routes.LIST} element={<ListCouponCodePage t={t} />} />
        <Route exact path={routes.ADD} element={<AddCouponCodePage t={t} />} />
        <Route exact path={routes.EDIT + ':id'} element={<EditCouponCodePage t={t} />} />
      </Routes>
    </>
  );
};
CouponCodeRoutes.propTypes = {
  t: PropTypes.func,
};
export default CouponCodeRoutes;

import client from 'libs/HttpClient';

class BranchService {
  static listBranch({ params }) {
    return client.get(`branch/list`, { params });
  }
  static addBranch(request) {
    return client.post(`branch/add`, request);
  }
  static viewBranch(params) {
    return client.get(`branch/get`, { params });
  }
  static updateBranch(request) {
    return client.put(`branch/edit`, request);
  }
  static deleteBranch(params) {
    return client.delete(`branch/delete`, { params });
  }
  static updateBranchStatus(request) {
    return client.put(`branch/update-status`, request);
  }
  static viewBranchName(params) {
    return client.get(`branch/list-branch-name`, { params });
  }
}
export { BranchService };

import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Home Page data Routes and pages
 */
import { ContactSettingPage } from 'pages/ContactSetting/ContactSettingPage';
import { ContactSeoSetting } from 'pages/ContactSetting/ContactSeoSettingPage';

const ContactSettingRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/general" element={<ContactSettingPage t={t} />} />
      <Route index exact path="/contact-seo" element={<ContactSeoSetting t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
ContactSettingRoutes.propTypes = {
  t: PropTypes.func,
};
export { ContactSettingRoutes };

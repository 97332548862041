import client from 'libs/HttpClient';

class BlogCategoryService {
  static listBlogCategory({ params }) {
    return client.get(`blog_category/list`, { params });
  }
  static addBlogCategory(request) {
    return client.post(`blog_category/add`, request);
  }
  static viewBlogCategory(params) {
    return client.get(`blog_category/get`, { params });
  }
  static updateBlogCategory(request) {
    return client.put(`blog_category/edit`, request);
  }
  static deleteBlogCategory(params) {
    return client.delete(`blog_category/delete`, { params });
  }
  static statusChangeBlogCategory(request) {
    return client.put(`blog_category/updateStatus`, request);
  }
}
export { BlogCategoryService };

import * as Yup from 'yup';
const languages = ['en', 'sp'];
let validationSchema = Yup.object({
  // about_setting_about_our_vision_en_title: Yup.string(
  //   'page.about_setting_about_our_vision_title_required'
  // )
  //   .required('page.about_setting_about_our_vision_title_required')
  //   .max(255, 'page.about_setting_about_our_vision_title_max'),
  // about_setting_about_our_vision_sp_title: Yup.string(
  //   'page.about_setting_about_our_vision_title_required'
  // )
  //   .required('page.about_setting_about_our_vision_title_required')
  //   .max(255, 'page.about_setting_about_our_vision_title_max'),
  // about_setting_about_our_vision_en_description: Yup.string(
  //   'page.about_setting_about_our_vision_description_required'
  // )
  //   .required('page.about_setting_about_our_vision_description_required')
  //   .max(1000, 'page.about_setting_about_our_vision_description_max'),
  // about_setting_about_our_vision_sp_description: Yup.string(
  //   'page.about_setting_about_our_vision_description_required'
  // )
  //   .required('page.about_setting_about_our_vision_description_required')
  //   .max(1000, 'page.about_setting_about_our_vision_description_max'),

  ...languages.reduce((acc, lang) => {
    acc[`about_setting_about_our_vision_${lang}_title`] = Yup.string(
      'page.about_setting_about_our_vision_title_required'
    )
      .required('page.about_setting_about_our_vision_title_required')
      .trim('page.about_setting_about_our_vision_title_required')
      .max(255, 'page.about_setting_about_our_vision_title_max');

    acc[`about_setting_about_our_vision_${lang}_description`] = Yup.string(
      'page.about_setting_about_our_vision_description_required'
    )
      .required('page.about_setting_about_our_vision_description_required')
      .trim('page.about_setting_about_our_vision_description_required')
      .max(1000, 'page.about_setting_about_our_vision_description_max');
    return acc;
  }, {}),
  about_setting_about_our_vision_image: Yup.mixed(
    'page.about_setting_about_our_vision_image_required'
  ).required('page.about_setting_about_our_vision_image_required'),
});
export default validationSchema;

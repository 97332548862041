import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { MotorcycleService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListMotorcycle = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['motorcycle-list', params], () => MotorcycleService.listMotorcycle({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useAddMotorcycle = (onSuccess, onError = onDefaultError) => {
  return useMutation(MotorcycleService.addMotorcycle, {
    onSuccess,
    onError,
  });
};
const useViewMotorcycle = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery(['motorcycle-view', _id], () => MotorcycleService.viewMotorcycle({ _id }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};
const useUpdateMotorcycle = (onSuccess, onError = onDefaultError) => {
  return useMutation(MotorcycleService.updateMotorcycle, {
    onSuccess,
    onError,
  });
};
const useUpdateMotorcycleStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(MotorcycleService.updateMotorcycleStatus, {
    onSuccess,
    onError,
  });
};
const useDeleteMotorcycle = (onSuccess, onError = onDefaultError) => {
  return useMutation(MotorcycleService.deleteMotorcycle, {
    onSuccess,
    onError,
  });
};
const useListMotorcycleName = (onSuccess, onError = onDefaultError) => {
  return useQuery('motorcycle-list-name', MotorcycleService.viewMotorcycleName, {
    onSuccess,
    onError,
  });
};
export {
  useDeleteMotorcycle,
  useUpdateMotorcycleStatus,
  useUpdateMotorcycle,
  useViewMotorcycle,
  useAddMotorcycle,
  useListMotorcycle,
  useListMotorcycleName,
};

import * as Yup from 'yup';

const languages = ['en', 'sp']; // Add other languages as needed

const validationSchema = Yup.object(
  languages.reduce((acc, lang) => {
    acc[`home_setting_seo_${lang}_title`] = Yup.string().required(
      `page.general_setting_seo_title_required`
    );

    acc[`home_setting_seo_${lang}_description`] = Yup.string().required(
      `page.general_setting_seo_description_required`
    );

    return acc;
  }, {})
);

export default validationSchema;

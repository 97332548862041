import client from 'libs/HttpClient';

class ExtraServiceService {
  static listExtraService({ params }) {
    return client.get(`extra-service/list`, { params });
  }
  static addExtraService(request) {
    return client.post(`extra-service/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewExtraService(params) {
    return client.get(`extra-service/get`, { params });
  }
  static updateExtraService(request) {
    return client.put(`extra-service/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteExtraService(params) {
    return client.delete(`extra-service/delete`, { params });
  }
  static updateExtraServiceStatus(request) {
    return client.put(`extra-service/update-status`, request);
  }
  static viewExtraServiceName(params) {
    return client.get(`extra-service/get-extra-service`, { params });
  }
}
export { ExtraServiceService };

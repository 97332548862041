// import libs
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {
  ChangePasswordPage,
  CmsRoutes,
  DashboardPage,
  EditProfilePage,
  EmailTemplateRoutes,
  FaqCategoryRoute,
  FaqRoute,
  ForgetPasswordPage,
  LoginPage,
  Notifications,
  OtpVerificationPage,
  ResetPasswordPage,
  SettingsRoutes,
  UserRoutes,
  TranslationRoutes,
  BlogCategoryRoutes,
  ServiceRoutes,
  FeatureRoutes,
  ReviewRoutes,
  BlogRoutes,
} from 'pages';
import {
  DASHBOARD,
  FORGOT_PASSWORD,
  LOGIN,
  OTP_VERIFY,
  RESET_PASSWORD,
  SETTING,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  EMAIL_TEMPLATE,
  CMS,
  TRANSLATIONS,
  FAQ,
  FAQ_CATEGORY,
  SERVICE,
  FEATURE,
  USERS,
  NOTIFICATIONS,
  REVIEW,
  SETTING_HOME,
  SETTING_ABOUT,
  OUR_TEAM,
  BLOG_CATEGORY,
  BLOG,
  SETTING_CONTACT_US,
  INSURANCE,
} from 'config';
import { useGetSettingData } from 'hooks';
import { settingData, addSetting } from 'store';
import { PageNotFound } from 'common';
import { HomeBannerSetting } from 'pages/HomeSetting/HomeBannerSettingPage';
import { AboutSettingRoutes } from 'pages/AboutSetting/AboutSettingRoute';

import { HomeSeoSetting } from 'pages/HomeSetting/HomeSeoSettingPage';
import { HomeMotoCycleLineUpSetting } from 'pages/HomeSetting/HomeMotoCycleLineUpSettingPage';
import { HomeFooterSetting } from 'pages/HomeSetting/HomeFooterSettingPage';
import { HomeWhyChooseUsSetting } from 'pages/HomeSetting/HomeWhyChooseUsSettingPage';
import OutTeamRoute from 'pages/Masters/OurTeam/OutTeamRoute';

import { ContactSettingRoutes } from 'pages/ContactSetting/ContactSettingRoute';
import { OFFICE_OPENING } from 'config';
import OfficeOpeningPage from 'pages/OfficeOpening/OfficeOpeningPage';
import { routes } from 'config';
import MotorCycleRoutes from 'pages/Masters/Motercycle/MoterCycleRoute';
import ServiceCategoryRoutes from 'pages/ServiceCategory/ServiceCategoryRoute';
import InsuranceRoutes from 'pages/Insurance/InsuranceRoutes';
import ExtraServiceRoutes from 'pages/ExtraService/ExtraServiceRoute';
import BranchRoutes from 'pages/Branch/BranchRoute';
import CouponCodeRoutes from 'pages/Masters/CouponCode/CouponCodeRoute';
import { MotorcycleRentalRoutes } from 'pages/MotorcycleRentalSetting/MotorcycleRentalSettingRoute';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  /**
   * This function will cal on page load, and will set data to redux store
   */
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  /**
   * Getting data from redux store if already exist
   */
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>{getSettingData.home_page_general_seo_title}</title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title} />
        <meta name="title" content={getSettingData.home_page_general_seo_title} />
        <meta name="description" content={getSettingData.home_page_general_seo_description} />
        <meta name="og:description" content={getSettingData.home_page_general_seo_description} />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo}`} />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path={LOGIN} element={<LoginPage t={t} />} />
          <Route exact path={FORGOT_PASSWORD} element={<ForgetPasswordPage t={t} />} />
          <Route exact path={OTP_VERIFY} element={<OtpVerificationPage t={t} />} />
          <Route exact path={RESET_PASSWORD} element={<ResetPasswordPage t={t} />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route exact path={DASHBOARD} element={<DashboardPage t={t} />} />
          <Route exact path={EDIT_PROFILE} element={<EditProfilePage t={t} />} />
          <Route exact path={CHANGE_PASSWORD} element={<ChangePasswordPage t={t} />} />
          <Route path={EMAIL_TEMPLATE + '/*'} element={<EmailTemplateRoutes t={t} />} />
          <Route path={CMS + '/*'} element={<CmsRoutes t={t} />} />
          <Route path={TRANSLATIONS + '/*'} element={<TranslationRoutes t={t} />} />
          <Route exact path={FAQ + '/*'} element={<FaqRoute t={t} />} />
          <Route exact path={OFFICE_OPENING} element={<OfficeOpeningPage t={t} />} />
          <Route exact path={FAQ_CATEGORY + '/*'} element={<FaqCategoryRoute t={t} />} />
          <Route exact path={SERVICE + '/*'} element={<ServiceRoutes t={t} />} />
          <Route exact path={FEATURE + '/*'} element={<FeatureRoutes t={t} />} />
          <Route exact path={OUR_TEAM + '/*'} element={<OutTeamRoute t={t} />} />
          <Route exact path={REVIEW + '/*'} element={<ReviewRoutes t={t} />} />
          <Route exact path={USERS + '/*'} element={<UserRoutes t={t} />} />
          <Route exact path={NOTIFICATIONS} element={<Notifications t={t} />} />
          <Route exact path={BLOG_CATEGORY + '/*'} element={<BlogCategoryRoutes t={t} />} />
          <Route exact path={BLOG + '/*'} element={<BlogRoutes t={t} />} />
          <Route exact path={INSURANCE + '/*'} element={<InsuranceRoutes t={t} />} />
          <Route path={SETTING + '/*'} element={<SettingsRoutes t={t} />} />
          <Route exact path={SETTING_HOME + '/home-banner'} element={<HomeBannerSetting t={t} />} />
          <Route exact path={SETTING_HOME + '/home-seo'} element={<HomeSeoSetting t={t} />} />
          <Route
            exact
            path={SETTING_HOME + '/home-motorcycle-lineup'}
            element={<HomeMotoCycleLineUpSetting t={t} />}
          />
          <Route exact path={SETTING_HOME + '/home-footer'} element={<HomeFooterSetting t={t} />} />
          <Route
            exact
            path={SETTING_HOME + '/home-why-choose-us'}
            element={<HomeWhyChooseUsSetting t={t} />}
          />
          <Route path={SETTING_ABOUT + '/*'} element={<AboutSettingRoutes t={t} />} />
          <Route path={SETTING_CONTACT_US + '/*'} element={<ContactSettingRoutes t={t} />} />
          <Route path={routes.BRANCH + '/*'} element={<BranchRoutes t={t} />} />
          <Route path={routes.MOTORCYCLE + '/*'} element={<MotorCycleRoutes t={t} />} />
          <Route path={routes.SERVICE_CATEGORY + '/*'} element={<ServiceCategoryRoutes t={t} />} />
          <Route path={routes.EXTRA_SERVICE + '/*'} element={<ExtraServiceRoutes t={t} />} />
          <Route path={routes.COUPON_CODE + '/*'} element={<CouponCodeRoutes t={t} />} />
          <Route
            path={routes.SETTING + routes.MOTORCYCLE_SETTINGS + '/*'}
            element={<MotorcycleRentalRoutes t={t} />}
          />
        </Route>
        <Route path="*" element={<PageNotFound t={t} />} from="admin" />
      </Routes>
    </>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
};
export default PagesRoutes;

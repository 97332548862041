import { useMutation, useQuery } from 'react-query';

const { OurTeamService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListOurTeam = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['our-team-list', params], () => OurTeamService.listOurTeam({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddOurTeam = (onSuccess, onError = onDefaultError) => {
  return useMutation(OurTeamService.addOurTeam, {
    onSuccess,
    onError,
  });
};

const useViewOurTeam = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('our-team-view', () => OurTeamService.viewOurTeam({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateOurTeam = (onSuccess, onError = onDefaultError) => {
  return useMutation(OurTeamService.updateOurTeam, {
    onSuccess,
    onError,
  });
};

const useDeleteOurTeam = (onSuccess, onError = onDefaultError) => {
  return useMutation(OurTeamService.deleteOurTeam, {
    onSuccess,
    onError,
  });
};

export { useListOurTeam, useAddOurTeam, useViewOurTeam, useUpdateOurTeam, useDeleteOurTeam };

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ShimmerTitle from '../Title';
import ShimmerText from '../Text';

const ShimmerSectionHeader = ({ title, subTitle, center, className }) => {
  if (!title && !subTitle) return null;
  return (
    <div
      className={classNames({
        'w-60 mb-40': true,
        'ml-auto mr-auto': center,
        [className]: className,
      })}>
      {title && (
        <ShimmerTitle
          className={classNames({
            'w-50': true,
            'ml-auto mr-auto': center,
          })}
          line={1}
        />
      )}
      {subTitle && (
        <div
          className={classNames({
            'flex-horizontal-center': center,
            [className]: className,
          })}>
          <ShimmerText line={1} gap={2} center={center} />
        </div>
      )}
    </div>
  );
};

ShimmerSectionHeader.propTypes = {
  title: PropTypes.bool,
  subTitle: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.any,
};

ShimmerSectionHeader.defaultProps = {
  title: true,
  subTitle: false,
  center: false,
};

export default ShimmerSectionHeader;

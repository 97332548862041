import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { BranchService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListBranch = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['branch-list', params], () => BranchService.listBranch({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddBranch = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.addBranch, {
    onSuccess,
    onError,
  });
};

const useViewBranch = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('branch-view', () => BranchService.viewBranch({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateBranch = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.updateBranch, {
    onSuccess,
    onError,
  });
};

const useUpdateBranchStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.updateBranchStatus, {
    onSuccess,
    onError,
  });
};

const useDeleteBranch = (onSuccess, onError = onDefaultError) => {
  return useMutation(BranchService.deleteBranch, {
    onSuccess,
    onError,
  });
};
const useListBranchName = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['branch-list-name', params], () => BranchService.viewBranchName({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

export {
  useDeleteBranch,
  useUpdateBranchStatus,
  useUpdateBranch,
  useViewBranch,
  useAddBranch,
  useListBranch,
  useListBranchName,
};

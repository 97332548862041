import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from 'config';

const SidebarServiceLinks = (props) => {
  const { t } = props;

  const serviceCategoryClass = location.pathname.startsWith(routes.SERVICE_CATEGORY)
    ? 'active'
    : '';
  const extraServiceClass = location.pathname.startsWith(routes.EXTRA_SERVICE) ? 'active' : '';

  return (
    <>
      <ul className={`submenu collapse ${props.services ? 'show' : ''}`}>
        <li>
          <NavLink
            className={serviceCategoryClass}
            to={routes.SERVICE_CATEGORY + routes.LIST}
            onClick={props.toggleClass}>
            {t('page.sidebar_service_category_master')}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={extraServiceClass}
            to={routes.EXTRA_SERVICE + routes.LIST}
            onClick={props.toggleClass}>
            {t('page.sidebar_extra_service_master')}
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export { SidebarServiceLinks };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col } from 'react-bootstrap'; // Assuming you're using react-bootstrap
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/scss/page/_alert_confirm.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import {
  useGetHomeSettingData,
  useStoreSettingData,
  useDeleteOfficeSettingData,
  useGetSettingData,
} from 'hooks';
import { TNButton } from 'common';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { addSetting, settingData } from 'store/features/settingSlice';
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'assets/scss/page/_alert_confirm.scss';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const OfficeOpeningPage = ({ t }) => {
  const dispatch = useDispatch();
  const [sections, setSections] = useState([
    {
      name: t('page.office_opening_pickup_rental_office_time_label'),
      slots: {
        Monday: [{ startTime: '', endTime: '' }],
        Tuesday: [{ startTime: '', endTime: '' }],
        Wednesday: [{ startTime: '', endTime: '' }],
        Thursday: [{ startTime: '', endTime: '' }],
        Friday: [{ startTime: '', endTime: '' }],
        Saturday: [{ startTime: '', endTime: '' }],
        Sunday: [{ startTime: '', endTime: '' }],
      },
    },
    {
      name: t('page.office_opening_drop_off_rental_office_time_label'),
      slots: {
        Monday: [{ startTime: '', endTime: '' }],
        Tuesday: [{ startTime: '', endTime: '' }],
        Wednesday: [{ startTime: '', endTime: '' }],
        Thursday: [{ startTime: '', endTime: '' }],
        Friday: [{ startTime: '', endTime: '' }],
        Saturday: [{ startTime: '', endTime: '' }],
        Sunday: [{ startTime: '', endTime: '' }],
      },
    },
    // {
    //   name: t('page.office_opening_drop_off_rental_equipment_time_label'),
    //   slots: {
    //     Monday: [{ startTime: '', endTime: '' }],
    //     Tuesday: [{ startTime: '', endTime: '' }],
    //     Wednesday: [{ startTime: '', endTime: '' }],
    //     Thursday: [{ startTime: '', endTime: '' }],
    //     Friday: [{ startTime: '', endTime: '' }],
    //     Saturday: [{ startTime: '', endTime: '' }],
    //     Sunday: [{ startTime: '', endTime: '' }],
    //   },
    // },
  ]);
  const handleAddSlotForDay = (sectionIndex, day) => {
    const maxSlots = 10; // Maximum allowed slots per day
    // Get the current slots for the specified day
    const currentSlots = sections[sectionIndex].slots[day];
    // Check if the number of slots exceeds the limit
    if (currentSlots.length >= maxSlots) {
      // alert(`You cannot add more than ${maxSlots} slots for ${day}.`);
      toast.error(
        t('page.office_opening_add_more_than_max_slot_error', {
          max_slot: maxSlots,
          day: t(day),
        })
      );
      return; // Exit the function
    }
    // Proceed to add a new slot
    const updatedSections = [...sections];
    const updatedSlots = { ...updatedSections[sectionIndex].slots };
    updatedSlots[day].push({ startTime: '', endTime: '' });
    updatedSections[sectionIndex].slots = updatedSlots;
    // Update the state with the new sections
    setSections(updatedSections);
  };

  const handleDeleteSlot = (sectionIndex, day, slotIndex) => {
    console.log('here delete sectionIndex ', sectionIndex);
    console.log('here delete day ', day);
    console.log('here delete slotIndex ', slotIndex);
    const updatedSections = [...sections];
    const updatedSlots = { ...updatedSections[sectionIndex].slots };
    if (updatedSlots[day].length > 1) {
      updatedSlots[day].splice(slotIndex, 1);
    } else {
      updatedSlots[day] = [{ startTime: '', endTime: '' }];
    }
    updatedSections[sectionIndex].slots = updatedSlots;

    const startTimeKey = `${
      sectionIndex === 0
        ? 'office_time_pickup_rental'
        : sectionIndex === 1
        ? 'office_time_dropoff_rental'
        : 'office_time_dropoff_equipment'
    }_${day.toLowerCase()}_slot_${slotIndex}_start_time`;
    const sectionName =
      sectionIndex === 0
        ? 'office_time_pickup_rental'
        : sectionIndex === 1
        ? 'office_time_dropoff_rental'
        : 'office_time_dropoff_equipment';
    const endTimeKey = `${
      sectionIndex === 0
        ? 'office_time_pickup_rental'
        : sectionIndex === 1
        ? 'office_time_dropoff_rental'
        : 'office_time_dropoff_equipment'
    }_${day.toLowerCase()}_slot_${slotIndex}_end_time`;

    doDeleteSettingData({
      params1: startTimeKey,
      params2: endTimeKey,
      sectionName: sectionName,
      day: day.toLowerCase(),
      slotIndex: slotIndex,
    });
    setSections(updatedSections);
  };

  const sectionNames = [
    t('page.office_opening_pickup_rental_office_time_label'),
    t('page.office_opening_drop_off_rental_office_time_label'),
    t('page.office_opening_drop_off_rental_equipment_time_label'),
  ];

  const { mutate: doDeleteSettingData } = useDeleteOfficeSettingData((response) => {
    refetch();
  });

  const validateTimes = () => {
    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      // Loop through the days of the week for each section
      const section = sections[sectionIndex];

      for (let dayIndex = 0; dayIndex < daysOfWeek.length; dayIndex++) {
        const day = daysOfWeek[dayIndex];
        const slots = section.slots[day];

        // Loop through all the slots for the current day
        for (let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
          const slot = slots[slotIndex];

          // Ensure slot is defined before accessing its properties
          if (slot && slot.startTime && slot.endTime) {
            const { startTime, endTime } = slot;

            // Convert times to 24-hour format
            const start = to24HourFormat(startTime);
            const end = to24HourFormat(endTime);

            // Compare start and end time
            if (start >= end) {
              toast.error(
                `${t('page.office_opening_end_time_error', {
                  section: sectionNames[sectionIndex],
                  day: t(day),
                })}`
              );
              return false;
            }
          }
        }
      }
    }
    return true; // Validation passed
  };
  const validateSectionSlots = () => {
    // Loop through each section
    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      const section = sections[sectionIndex];

      // Check if the section has at least one valid time slot
      let hasValidSlot = false;

      Object.keys(section.slots).forEach((dayKey) => {
        section.slots[dayKey].forEach((slot) => {
          if (slot.startTime && slot.endTime) {
            hasValidSlot = true;
          }
        });
      });

      // If no valid slot is found, show an error with the section name
      if (!hasValidSlot) {
        const sectionName = getSectionName(sectionIndex);
        toast.error(
          t('page.office_opening_atleast_one_slot_required_error', {
            section_name: sectionName,
          })
        );
        // toast.error(`Please provide at least one valid time slot for ${sectionName}.`);
        return false; // Stop validation and return an error
      }
    }

    return true; // All sections are valid
  };
  const validateSlotTimes = () => {
    // Loop through each section
    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      const section = sections[sectionIndex];

      // Loop through each day in the section's slots
      for (let dayKey in section.slots) {
        const slots = section.slots[dayKey];

        for (let slotIndex = 0; slotIndex < slots.length; slotIndex++) {
          const slot = slots[slotIndex];

          // Ensure slot is defined before accessing its properties
          if (slot) {
            // Check if the startTime or endTime is missing
            if (!slot.startTime && slot.endTime) {
              const sectionName = getSectionName(sectionIndex);
              const dayName = capitalize(dayKey);

              //toast.error(`Start time is required for the slot in ${sectionName} on ${dayName}.`);
              toast.error(
                t('page.office_opening_start_time_required_error', {
                  section_name: sectionName,
                  day: dayName,
                })
              );
              return false;
            }
            if (slot.startTime && !slot.endTime) {
              const sectionName = getSectionName(sectionIndex);
              const dayName = capitalize(dayKey);

              //toast.error(`End time is required for the slot in ${sectionName} on ${dayName}.`);
              toast.error(
                t('page.office_opening_end_time_required_error', {
                  section_name: sectionName,
                  day: dayName,
                })
              );
              return false;
            }
          }
        }
      }
    }

    return true; // All slots are valid
  };
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const getSectionName = (sectionIndex) => {
    switch (sectionIndex) {
      case 0:
        return t('page.office_opening_pickup_rental_office_time_label');
      case 1:
        return t('page.office_opening_drop_off_rental_office_time_label');
      case 2:
        return t('page.office_opening_drop_off_rental_equipment_time_label');
      default:
        return t(`Section ${sectionIndex + 1}`);
    }
  };
  const { refetch } = useGetHomeSettingData(
    {
      tag: 'office_time', // Adjust this according to your setup
    },
    ({ data: setting }) => {
      dispatch(hideLoader());

      // Map the homeSettingData to the sections state
      if (setting) {
        const updatedSections = [...sections]; // Create a copy of the current state

        // Iterate through each section
        updatedSections.forEach((section, sectionIndex) => {
          // Update the note for the section
          const noteKey = `${
            sectionIndex === 0
              ? 'office_time_pickup_rental'
              : sectionIndex === 1
              ? 'office_time_dropoff_rental'
              : 'office_time_dropoff_equipment'
          }_note`;

          const note = setting[noteKey];
          if (note) {
            updatedSections[sectionIndex].note = note;
          }
          // For each day in the section, update the time slots
          Object.keys(section.slots).forEach((day) => {
            // For each slot in a day (slot_1, slot_2, etc.), update the time
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((slotIndex) => {
              // Assuming two slots per day (slot_1 and slot_2)
              // Construct the keys for the start and end time based on the section, day, and slot number
              const startTimeKey = `${
                sectionIndex === 0
                  ? 'office_time_pickup_rental'
                  : sectionIndex === 1
                  ? 'office_time_dropoff_rental'
                  : 'office_time_dropoff_equipment'
              }_${day.toLowerCase()}_slot_${slotIndex}_start_time`;

              const endTimeKey = `${
                sectionIndex === 0
                  ? 'office_time_pickup_rental'
                  : sectionIndex === 1
                  ? 'office_time_dropoff_rental'
                  : 'office_time_dropoff_equipment'
              }_${day.toLowerCase()}_slot_${slotIndex}_end_time`;

              // Get the start and end time from the homeSettingData
              const startTime = setting[startTimeKey];
              const endTime = setting[endTimeKey];

              // If both startTime and endTime are available, update the slot
              if (startTime && endTime) {
                updatedSections[sectionIndex].slots[day][slotIndex] = { startTime, endTime };
              }
            });
          });
        });

        // Set the updated sections into the state
        setSections(updatedSections);
      }
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );
  const handleNoteChange = (sectionIndex, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].note = value.replace(/^\s+/g, '');
    setSections(updatedSections);
  };
  const [selectedOptions, setSelectedOptions] = useState(Array(sections.length).fill(''));
  const handleTimeChange = (sectionIndex, day, slotIndex, field, value) => {
    const updatedSections = [...sections];

    // Update the time value for the correct section, day, and slot
    updatedSections[sectionIndex].slots[day][slotIndex] = {
      ...updatedSections[sectionIndex].slots[day][slotIndex],
      [field]: value,
    };

    setSections(updatedSections);
  };

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDError,
    isLoading,
  } = useStoreSettingData((response) => {
    refetch();
    toast.success(response.message);
  });

  const handleSelectionChange = (sourceIndex, targetSectionIndex) => {
    // Avoid copying if the source section is the same as the target section
    if (sourceIndex === targetSectionIndex) return;

    // Check if the target section already has valid slots (e.g., with startTime or endTime)
    console.log('here slots ', sections[targetSectionIndex].slots);
    const hasValidSlots = Object.values(sections[targetSectionIndex].slots).some((daySlots) =>
      daySlots.some((slot) => slot && (slot.startTime || slot.endTime))
    );
    const sectionKey = (() => {
      switch (targetSectionIndex) {
        case 0:
          return 'office_time_pickup_rental';
        case 1:
          return 'office_time_dropoff_rental';
        case 2:
          return 'office_time_dropoff_equipment';
        default:
          return `section_${sectionIndex + 1}`; // Default section key
      }
    })();
    console.log('here slot ', hasValidSlots);
    if (hasValidSlots) {
      // Show a confirmation dialog using confirmAlert
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose(); // Close the dialog when "X" is clicked
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.confirm_slot_change_popup_message')}</h2>
                <TNButton
                  className="table-delete-button"
                  onClick={() => {
                    doDeleteSettingData({
                      sectionName: sectionKey,
                    });
                    // Perform the copy operation on confirmation
                    const updatedSections = [...sections];
                    const copiedSlots = JSON.parse(JSON.stringify(sections[sourceIndex].slots));

                    updatedSections[targetSectionIndex] = {
                      ...updatedSections[targetSectionIndex], // Preserve other properties
                      slots: copiedSlots, // Copy the slots data
                    };

                    setSections(updatedSections); // Update the state
                    onClose(); // Close the dialog
                  }}>
                  {t('page.alert_popup_yes_button')}
                </TNButton>
                <TNButton
                  className="table-primary-button"
                  onClick={() => {
                    onClose(); // Close the dialog without doing anything
                  }}>
                  {t('page.alert_popup_no_button')}
                </TNButton>
              </div>
            </div>
          );
        },
      });

      // Exit the function after showing the dialog
      return;
    }

    // If the target section is empty or has no valid slots, perform the copy directly
    const updatedSections = [...sections];
    const copiedSlots = JSON.parse(JSON.stringify(sections[sourceIndex].slots));
    updatedSections[targetSectionIndex] = {
      ...updatedSections[targetSectionIndex], // Preserve other properties
      slots: copiedSlots, // Copy the slots data
    };

    setSections(updatedSections); // Update the state
  };

  const to24HourFormat = (time) => {
    const [hours, minutes] = time.split(':'); // Split the time into hours and minutes
    let formattedHours = parseInt(hours, 10);

    // If it's a valid number and within 24-hour range
    if (formattedHours < 0 || formattedHours > 23) {
      throw new Error('Invalid hour value');
    }

    return `${String(formattedHours).padStart(2, '0')}:${minutes}`; // Ensure hours are always 2 digits
  };

  const handleSave = async () => {
    // Step 1: Validate the data before proceeding
    if (!validateTimes() || !validateSectionSlots() || !validateSlotTimes()) {
      return; // Stop execution if validation fails
    }

    // Step 2: Prepare form data for submission
    const formData = new FormData();

    // Loop through each section
    sections.forEach((section, sectionIndex) => {
      // Step 3: Generate a unique section key
      const sectionKey = (() => {
        switch (sectionIndex) {
          case 0:
            return 'office_time_pickup_rental';
          case 1:
            return 'office_time_dropoff_rental';
          case 2:
            return 'office_time_dropoff_equipment';
          default:
            return `section_${sectionIndex + 1}`; // Default section key
        }
      })();

      const noteKey = `${sectionKey}_note`;
      formData.append(noteKey, section.note != undefined ? section.note : '');
      // Step 4: Loop through each day in the 'slots' object
      Object.keys(section.slots).forEach((dayKey, dayIndex) => {
        // Convert dayKey to lowercase (e.g., 'monday', 'tuesday')
        const dayLowerCaseKey = dayKey.toLowerCase();

        // Loop through each time slot for the day
        section.slots[dayKey].forEach((timeSlot, slotIndex) => {
          // Create unique keys for start and end times for each slot
          const startTimeKey = `${sectionKey}_${dayLowerCaseKey}_slot_${slotIndex}_start_time`;
          const endTimeKey = `${sectionKey}_${dayLowerCaseKey}_slot_${slotIndex}_end_time`;

          // Step 5: Append start and end times if available (in 24-hour format)
          if (timeSlot.startTime) {
            const startTime24hr = to24HourFormat(timeSlot.startTime);
            formData.append(startTimeKey, startTime24hr);
          }

          if (timeSlot.endTime) {
            const endTime24hr = to24HourFormat(timeSlot.endTime);
            formData.append(endTimeKey, endTime24hr);
          }
        });
      });
    });

    // Step 6: Log formData for debugging purposes
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    // Step 7: Dispatch loading indicator (assuming showLoader dispatches a loading state)
    dispatch(showLoader());
    formData.append('is_office_timing_data', true);
    // Step 8: Call function to store data (e.g., doStoreSettingData) once data is ready
    doStoreSettingData(formData);
  };

  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>
          {getSettingData.home_page_general_seo_title || 'Triumph Admin'} | Office Hours
        </title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="description" content={getSettingData.home_page_general_seo_description || ''} />
        <meta
          name="og:description"
          content={getSettingData.home_page_general_seo_description || ''}
        />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo || ''}`} />
      </Helmet>
      <h1 className="text-center p-0 m-0">{t('page.office_opening_list_label')}</h1>
      <Row className="justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Col xxl={10} className="text-center">
          <div className="office-opening-sections mt-2">
            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="section mt-5 mb-4">
                {sectionIndex === 0 && <h2 className="mb-4">{section.name}</h2>}
                {sectionIndex !== 0 && <h2>{section.name}</h2>}
                {sectionIndex !== 0 && (
                  <Col md={6} className="text-center mt-4">
                    <div className="copy-options text-start mb-4">
                      <label>{t('page.office_opening_copy_data_from_label')} :</label>
                      <Select
                        className="filter-column"
                        options={sections
                          .map((_, sourceIndex) => ({
                            value: sourceIndex,
                            label: getSectionName(sourceIndex),
                          }))
                          .filter((option) => option.value < sectionIndex)} // Only include previous sections
                        placeholder={t('page.office_opening_select_section_label')}
                        value={
                          selectedOptions[sectionIndex]
                            ? {
                                value: selectedOptions[sectionIndex],
                                label: getSectionName(selectedOptions[sectionIndex]),
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          const sourceIndex = selectedOption
                            ? parseInt(selectedOption.value, 10)
                            : null;
                          if (!isNaN(sourceIndex)) {
                            handleSelectionChange(sourceIndex, sectionIndex);
                          }
                        }}
                      />
                    </div>
                  </Col>
                )}

                <Table responsive className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th>{t('page.office_opening_day_label')}</th>
                      <th>{t('page.office_timing_label')}</th>
                      <th>{t('page.actions_label')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(section.slots).map((day, dayIndex) => (
                      <tr key={dayIndex}>
                        <td>{t(day)}</td>
                        <td>
                          {section.slots[day].map((slot, slotIndex) => (
                            <div
                              key={slotIndex}
                              className="d-flex align-items-center justify-content-between m-2"
                              style={{ gap: '10px' }}>
                              <label className="me-2">Start Time : </label>
                              <input
                                type="time"
                                value={slot.startTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    sectionIndex,
                                    day,
                                    slotIndex,
                                    'startTime',
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                style={{ flex: '1' }}
                              />
                              <label className="me-2">End Time : </label>
                              <input
                                type="time"
                                value={slot.endTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    sectionIndex,
                                    day,
                                    slotIndex,
                                    'endTime',
                                    e.target.value
                                  )
                                }
                                className="form-control"
                                style={{ flex: '1' }}
                              />
                              <button
                                onClick={() => handleDeleteSlot(sectionIndex, day, slotIndex)}
                                className="table-delete-button btn btn-primary ms-2">
                                <FontAwesomeIcon icon={faRemove} />
                              </button>
                            </div>
                          ))}
                        </td>
                        <td>
                          <button
                            onClick={() => handleAddSlotForDay(sectionIndex, day)}
                            className="table-primary-button btn btn-primary">
                            {t('page.action_add_label')}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="note-section mt-4 text-start">
                  <label htmlFor={`section-note-${sectionIndex}`} className="form-label">
                    {t('page.office_opening_note_label')}
                  </label>
                  <textarea
                    id={`section-note-${sectionIndex}`}
                    value={section.note || ''}
                    onChange={(e) => handleNoteChange(sectionIndex, e.target.value)}
                    className="form-control"
                    rows="3"
                    placeholder={t('page.office_opening_note_placeholder')}
                  />
                </div>
              </div>
            ))}
          </div>
          <button className="btn btn-primary" onClick={handleSave}>
            {t('page.office_opening_save_all_label')}
          </button>
        </Col>
      </Row>
    </>
  );
};

OfficeOpeningPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default OfficeOpeningPage;

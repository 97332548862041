import React, { useEffect } from 'react';
import '../../assets/scss/loader/spinner.scss';
export default function LoadingSpinner() {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button.scss';

const ShimmerButton = ({ size, className }) => (
  <div
    className={classNames({
      'shimmer shimmer-button': true,
      [`shimmer-button--${size}`]: true,
      [className]: className,
    })}
  />
);

ShimmerButton.propTypes = {
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  className: PropTypes.any,
};

ShimmerButton.defaultProps = {
  size: 'md',
};

export default ShimmerButton;

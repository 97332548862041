import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { LIST, ADD, EDIT } from 'config';
const ListOurTeamPage = React.lazy(() => import('./ListOurTeamPage'));
const AddOurTeamPage = React.lazy(() => import('./AddOurTeamPage'));
const EditOurTeamPage = React.lazy(() => import('./EditOurTeamPage'));
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useGetSettingData } from 'hooks';
import { addSetting, settingData } from 'store/features/settingSlice';

const OurTeamRoute = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>{getSettingData.home_page_general_seo_title || 'Triumph Admin'} | Our Team</title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="description" content={getSettingData.home_page_general_seo_description || ''} />
        <meta
          name="og:description"
          content={getSettingData.home_page_general_seo_description || ''}
        />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo || ''}`} />
      </Helmet>
      <Routes>
        <Route index exact path={LIST} element={<ListOurTeamPage t={t} />} />
        <Route exact path={ADD} element={<AddOurTeamPage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditOurTeamPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
OurTeamRoute.propTypes = {
  t: PropTypes.func,
};
export default OurTeamRoute;

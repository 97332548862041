import { useMutation, useQuery } from 'react-query';

const { BlogService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListBlog = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['blog-list', params], () => BlogService.listBlog({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddBlog = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogService.addBlog, {
    onSuccess,
    onError,
  });
};

const useViewBlog = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('blog-view', () => BlogService.viewBlog({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateBlog = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogService.updateBlog, {
    onSuccess,
    onError,
  });
};

const useStatusChangeBlog = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogService.statusChangeBlog, {
    onSuccess,
    onError,
  });
};

const useDeleteBlog = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogService.deleteBlog, {
    onSuccess,
    onError,
  });
};

const useGetBlogCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('blog-category-list', BlogService.getServiceList, {
    onSuccess,
    onError,
  });
};

export {
  useListBlog,
  useAddBlog,
  useViewBlog,
  useUpdateBlog,
  useStatusChangeBlog,
  useDeleteBlog,
  useGetBlogCategoryList,
};

import * as Yup from 'yup';
const languages = ['en', 'sp'];

let validationSchema = Yup.object({
  ...languages.reduce((acc, lang) => {
    acc[`about_setting_about_our_team_${lang}_title`] = Yup.string(
      'page.about_setting_about_our_team_title_required'
    )
      .required('page.about_setting_about_our_team_title_required')
      .trim('page.about_setting_about_our_team_title_required')
      .max(255, 'page.about_setting_about_our_team_title_max');

    acc[`about_setting_about_our_team_${lang}_description`] = Yup.string(
      'page.about_setting_about_our_team_description_required'
    )
      .required('page.about_setting_about_our_team_description_required')
      .trim('page.about_setting_about_our_team_description_required')
      .max(1000, 'page.about_setting_about_our_team_description_max');
    return acc;
  }, {}),

  // about_setting_about_our_team_en_title: Yup.string(
  //   'page.about_setting_about_our_team_title_required'
  // )
  //   .required('page.about_setting_about_our_team_title_required')
  //   .max(255, 'page.about_setting_about_our_team_title_max'),
  // about_setting_about_our_team_sp_title: Yup.string(
  //   'page.about_setting_about_our_team_title_required'
  // )
  //   .required('page.about_setting_about_our_team_title_required')
  //   .max(255, 'page.about_setting_about_our_team_title_max'),
  // about_setting_about_our_team_en_description: Yup.string(
  //   'page.about_setting_about_our_team_description_required'
  // )
  //   .required('page.about_setting_about_our_team_description_required')
  //   .max(1000, 'page.about_setting_about_our_team_description_max'),
  // about_setting_about_our_team_sp_description: Yup.string(
  //   'page.about_setting_about_our_team_description_required'
  // )
  //   .required('page.about_setting_about_our_team_description_required')
  //   .max(1000, 'page.about_setting_about_our_team_description_max'),
});
export default validationSchema;

import * as Yup from 'yup';
const languages = ['en', 'sp'];

let validationSchema = Yup.object({
  ...languages.reduce((acc, lang) => {
    acc[`about_setting_about_our_offices_header_${lang}_title`] = Yup.string(
      'page.about_setting_about_our_offices_header_title_required'
    )
      .required('page.about_setting_about_our_offices_header_title_required')
      .trim('page.about_setting_about_our_offices_header_title_required')
      .max(255, 'page.about_setting_about_our_offices_header_title_max');

    acc[`about_setting_about_our_offices_one_${lang}_title`] = Yup.string(
      'page.about_setting_about_our_offices_one_title_required'
    )
      .required('page.about_setting_about_our_offices_one_title_required')
      .trim('page.about_setting_about_our_offices_one_title_required')
      .max(500, 'page.about_setting_about_our_offices_one_title_max');

    acc[`about_setting_about_our_offices_two_${lang}_title`] = Yup.string(
      'page.about_setting_about_our_offices_two_title_required'
    )
      .required('page.about_setting_about_our_offices_two_title_required')
      .trim('page.about_setting_about_our_offices_two_title_required')
      .max(1000, 'page.about_setting_about_our_offices_two_title_max');
    return acc;
  }, {}),

  // about_setting_about_our_offices_header_en_title: Yup.string(
  //   'page.about_setting_about_our_offices_header_title_required'
  // )
  //   .required('page.about_setting_about_our_offices_header_title_required')
  //   .max(255, 'page.about_setting_about_our_offices_header_title_max'),
  // about_setting_about_our_offices_header_sp_title: Yup.string(
  //   'page.about_setting_about_our_offices_header_title_required'
  // )
  //   .required('page.about_setting_about_our_offices_header_title_required')
  //   .max(255, 'page.about_setting_about_our_offices_header_title_max'),
  // about_setting_about_our_offices_one_en_title: Yup.string(
  //   'page.about_setting_about_our_offices_one_title_required'
  // )
  //   .required('page.about_setting_about_our_offices_one_title_required')
  //   .max(255, 'page.about_setting_about_our_offices_one_title_max'),
  // about_setting_about_our_offices_one_sp_title: Yup.string(
  //   'page.about_setting_about_our_offices_one_title_required'
  // )
  //   .required('page.about_setting_about_our_offices_one_title_required')
  //   .max(255, 'page.about_setting_about_our_offices_one_title_max'),
  about_setting_about_our_offices_one_en_address: Yup.string(
    'page.about_setting_about_our_offices_one_address_required'
  )
    .required('page.about_setting_about_our_offices_one_address_required')
    .max(1000, 'page.about_setting_about_our_offices_one_address_max'),
  about_setting_about_our_offices_one_sp_address: Yup.string(
    'page.about_setting_about_our_offices_one_address_required'
  )
    .required('page.about_setting_about_our_offices_one_address_required')
    .max(1000, 'page.about_setting_about_our_offices_one_address_max'),

  about_setting_about_our_offices_one_phone: Yup.string(
    'page.about_setting_about_our_offices_one_phone_required'
  )
    .required('page.about_setting_about_our_offices_one_phone_required')
    .min(14, 'page.about_setting_about_our_offices_one_phone_min')
    .max(25, 'page.about_setting_about_our_offices_one_phone_max'),
  about_setting_about_our_offices_one_email: Yup.string(
    'page.about_setting_about_our_offices_one_email_required'
  )
    .required('page.about_setting_about_our_offices_one_email_required')
    .email('page.about_setting_about_our_offices_email_invalid') // email format validation
    .test('has-domain', 'page.about_setting_about_our_offices_email_invalid', function (value) {
      // Regex to check if domain exists in the email
      const domainRegex = /@([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/;
      const isValidDomain = domainRegex.test(value);
      return (
        isValidDomain ||
        this.createError({ message: 'page.about_setting_about_our_offices_email_invalid' })
      );
    })
    .max(100, 'page.about_setting_about_our_offices_one_email_max'),

  // about_setting_about_our_offices_two_en_title: Yup.string(
  //   'page.about_setting_about_our_offices_two_title_required'
  // )
  //   .required('page.about_setting_about_our_offices_two_title_required')
  //   .max(255, 'page.about_setting_about_our_offices_two_title_max'),
  // about_setting_about_our_offices_two_sp_title: Yup.string(
  //   'page.about_setting_about_our_offices_two_title_required'
  // )
  //   .required('page.about_setting_about_our_offices_two_title_required')
  //   .max(255, 'page.about_setting_about_our_offices_two_title_max'),
  about_setting_about_our_offices_two_en_address: Yup.string(
    'page.about_setting_about_our_offices_two_address_required'
  )
    .required('page.about_setting_about_our_offices_two_address_required')
    .max(1000, 'page.about_setting_about_our_offices_two_address_max'),
  about_setting_about_our_offices_two_sp_address: Yup.string(
    'page.about_setting_about_our_offices_two_address_required'
  )
    .required('page.about_setting_about_our_offices_two_address_required')
    .max(1000, 'page.about_setting_about_our_offices_two_address_max'),

  about_setting_about_our_offices_two_phone: Yup.string(
    'page.about_setting_about_our_offices_two_phone_required'
  )
    .required('page.about_setting_about_our_offices_two_phone_required')
    .min(14, 'page.about_setting_about_our_offices_two_phone_min')
    .max(25, 'page.about_setting_about_our_offices_two_phone_max'),
  about_setting_about_our_offices_two_email: Yup.string(
    'page.about_setting_about_our_offices_two_email_required'
  )
    .required('page.about_setting_about_our_offices_two_email_required')
    .email('page.about_setting_about_our_offices_email_invalid') // email format validation
    .test('has-domain', 'page.about_setting_about_our_offices_email_invalid', function (value) {
      // Regex to check if domain exists in the email
      const domainRegex = /@([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/;
      const isValidDomain = domainRegex.test(value);
      return (
        isValidDomain ||
        this.createError({ message: 'page.about_setting_about_our_offices_email_invalid' })
      );
    })
    .max(100, 'page.about_setting_about_our_offices_two_email_max'),
});
export default validationSchema;

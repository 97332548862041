import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import GeneralSetting from './GeneralSettingPage';
import { GeneralMailNotificationsSettings } from './GeneralMailNotificationsPage';
import { GENERAL, MAIL_SETTING } from 'config';
import { SETTING_ABOUT } from 'config';
import { AboutBannerSetting } from 'pages/AboutSetting/AboutBannerSettingPage';
import { AboutOurMissionSetting } from 'pages/AboutSetting/AboutOurMissionSettingPage';
import { AboutOurVisionSetting } from 'pages/AboutSetting/AboutOurVisionSettingPage';
import { AboutOurTeamSetting } from 'pages/AboutSetting/AboutOurTeamSettingPage';
import { AboutOurOfficeSetting } from 'pages/AboutSetting/AboutOurOfficesSettingPage';

const Loading = () => (
  <div className="text-center">
    <Spinner animation="border" />
  </div>
);

const SettingsRoutes = ({ t }) => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route path={GENERAL} element={<GeneralSetting t={t} />} />
      <Route path={MAIL_SETTING} element={<GeneralMailNotificationsSettings t={t} />} />
    </Routes>
  </Suspense>
);

export default SettingsRoutes;

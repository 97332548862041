import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Home Page data Routes and pages
 */
import { AboutBannerSetting } from 'pages/AboutSetting/AboutBannerSettingPage';
import { AboutOurMissionSetting } from 'pages/AboutSetting/AboutOurMissionSettingPage';
import { AboutOurVisionSetting } from 'pages/AboutSetting/AboutOurVisionSettingPage';
import { AboutOurTeamSetting } from 'pages/AboutSetting/AboutOurTeamSettingPage';
import { AboutOurOfficeSetting } from 'pages/AboutSetting/AboutOurOfficesSettingPage';
import { AboutSeoSetting } from 'pages/AboutSetting/AboutSeoSettingPage';

const AboutSettingRoutes = ({ t }) => {
  return (
    <Routes>
      <Route index exact path="/about-banner" element={<AboutBannerSetting t={t} />} />
      <Route exact path="/our-mission" element={<AboutOurMissionSetting t={t} />} />
      <Route exact path="/our-vision" element={<AboutOurVisionSetting t={t} />} />
      <Route exact path="/our-team" element={<AboutOurTeamSetting t={t} />} />
      <Route exact path="/our-offices" element={<AboutOurOfficeSetting t={t} />} />
      <Route exact path="/about-seo" element={<AboutSeoSetting t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AboutSettingRoutes.propTypes = {
  t: PropTypes.func,
};
export { AboutSettingRoutes };

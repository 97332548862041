import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { usePlacesWidget } from 'react-google-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAboutSettingData, useStoreSettingData } from 'hooks';
import { AboutTab } from './AboutTab';
import validationSchema from './AboutOurOfficesSettingValidation';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { AlertCommon, TNButton } from 'common';
import { confirmAlert } from 'react-confirm-alert';
const languages = ['en', 'sp'];

const AboutOurOfficeSetting = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aboutSettingData, setAboutSettingData] = useState(null);

  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);

  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    // refetch();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  });

  const formik = useFormik({
    initialValues: {
      ...languages.reduce((acc, lang) => {
        acc[`about_setting_about_our_offices_header_${lang}_title`] = '';
        acc[`about_setting_about_our_offices_one_${lang}_title`] = '';
        acc[`about_setting_about_our_offices_two_${lang}_description`] = '';
        return acc;
      }, {}),
      // about_setting_about_our_offices_one_address: '',
      about_setting_about_our_offices_one_en_address: '',
      about_setting_about_our_offices_one_sp_address: '',

      // about_setting_about_our_offices_one_address_lat: '',
      about_setting_about_our_offices_one_en_address_lat: '',
      about_setting_about_our_offices_one_sp_address_lat: '',

      // about_setting_about_our_offices_one_address_long: '',
      about_setting_about_our_offices_one_en_address_long: '',
      about_setting_about_our_offices_one_sp_address_long: '',

      about_setting_about_our_offices_one_phone: '',
      about_setting_about_our_offices_one_email: '',

      // about_setting_about_our_offices_two_address: '',
      about_setting_about_our_offices_two_en_address: '',
      about_setting_about_our_offices_two_sp_address: '',

      // about_setting_about_our_offices_two_address_lat: '',
      about_setting_about_our_offices_two_en_address_lat: '',
      about_setting_about_our_offices_two_sp_address_lat: '',

      // about_setting_about_our_offices_two_address_long: '',
      about_setting_about_our_offices_two_en_address_long: '',
      about_setting_about_our_offices_two_sp_address_long: '',

      about_setting_about_our_offices_two_phone: '',
      about_setting_about_our_offices_two_email: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        about_setting_about_our_offices_header_title: {},
        about_setting_about_our_offices_one_title: {},
        about_setting_about_our_offices_two_title: {},
        about_setting_about_our_offices_one_address: {},
        about_setting_about_our_offices_two_address: {},

        // about_setting_about_our_offices_one_address_lat:
        //   values.about_setting_about_our_offices_one_address_lat,
        // about_setting_about_our_offices_one_address_long:
        //   values.about_setting_about_our_offices_one_address_long,
        // about_setting_about_our_offices_two_address_lat:
        //   values.about_setting_about_our_offices_two_address_lat,
        // about_setting_about_our_offices_two_address_long:
        //   values.about_setting_about_our_offices_two_address_long,
        about_setting_about_our_offices_one_address: {
          ['en']: values.about_setting_about_our_offices_one_en_address,
          ['sp']: values.about_setting_about_our_offices_one_sp_address,
        },
        about_setting_about_our_offices_two_address: {
          ['en']: values.about_setting_about_our_offices_two_en_address,
          ['sp']: values.about_setting_about_our_offices_two_sp_address,
        },
        about_setting_about_our_offices_one_address_lat: {
          ['en']: values.about_setting_about_our_offices_one_en_address_lat || 36.6588474,
          ['sp']: values.about_setting_about_our_offices_one_en_address_lat || 36.6588474,
        },
        about_setting_about_our_offices_two_address_lat: {
          ['en']: values.about_setting_about_our_offices_two_en_address_lat || 41.3306391,
          ['sp']: values.about_setting_about_our_offices_two_en_address_lat || 41.3306391,
        },

        about_setting_about_our_offices_one_address_long: {
          ['en']: values.about_setting_about_our_offices_one_en_address_long || -4.5811173,
          ['sp']: values.about_setting_about_our_offices_one_en_address_long || -4.5811173,
        },
        about_setting_about_our_offices_two_address_long: {
          ['en']: values.about_setting_about_our_offices_two_en_address_long || 2.0022707,
          ['sp']: values.about_setting_about_our_offices_two_en_address_long || 2.0022707,
        },
      };

      languages.forEach((lang) => {
        transformedValues.about_setting_about_our_offices_header_title[lang] =
          values[`about_setting_about_our_offices_header_${lang}_title`] || '';
        transformedValues.about_setting_about_our_offices_one_title[lang] =
          values[`about_setting_about_our_offices_one_${lang}_title`] || '';
        transformedValues.about_setting_about_our_offices_two_title[lang] =
          values[`about_setting_about_our_offices_two_${lang}_title`] || '';
      });

      // const transformedValues = {
      //   about_setting_about_our_offices_one_address_lat:
      //     values.about_setting_about_our_offices_one_address_lat,
      //   about_setting_about_our_offices_one_address_long:
      //     values.about_setting_about_our_offices_one_address_long,
      //   about_setting_about_our_offices_one_address_lat:
      //     values.about_setting_about_our_offices_two_address_lat,
      //   about_setting_about_our_offices_one_address_lat:
      //     values.about_setting_about_our_offices_two_address_long,
      //   about_setting_about_our_offices_header_title: {
      //     ['en']: values.about_setting_about_our_offices_header_en_title,
      //     ['sp']: values.about_setting_about_our_offices_header_sp_title,
      //   },
      //   about_setting_about_our_offices_one_title: {
      //     ['en']: values.about_setting_about_our_offices_one_en_title,
      //     ['sp']: values.about_setting_about_our_offices_one_sp_title,
      //   },
      //   about_setting_about_our_offices_two_title: {
      //     ['en']: values.about_setting_about_our_offices_two_en_title,
      //     ['sp']: values.about_setting_about_our_offices_two_sp_title,
      //   },
      // };
      // if (
      //   values.about_setting_about_our_offices_one_address_lat ==
      //     aboutSettingData?.contact_setting_contact_info_one_address_lat &&
      //   values.about_setting_about_our_offices_one_address_long ==
      //     aboutSettingData?.about_setting_about_our_offices_one_address_long
      // ) {
      //   transformedValues.about_setting_about_our_offices_one_address =
      //     aboutSettingData?.about_setting_about_our_offices_one_address;
      // } else {
      //   transformedValues.about_setting_about_our_offices_one_address =
      //     values.about_setting_about_our_offices_one_address;
      // }

      // if (
      //   values.about_setting_about_our_offices_two_address_lat ==
      //     aboutSettingData?.about_setting_about_our_offices_two_address_lat &&
      //   values.about_setting_about_our_offices_two_address_long ==
      //     aboutSettingData?.about_setting_about_our_offices_two_address_long
      // ) {
      //   transformedValues.about_setting_about_our_offices_two_address =
      //     aboutSettingData?.about_setting_about_our_offices_two_address;
      // } else {
      //   transformedValues.about_setting_about_our_offices_two_address =
      //     values.about_setting_about_our_offices_two_address;
      // }

      doStoreSettingData(transformedValues);
    },
  });

  const { refetch } = useGetAboutSettingData(
    {
      tag: 'about_setting_about_our_offices',
    },
    ({ data: setting }) => {
      const dataStore = {
        // about_setting_about_our_offices_header_title:
        //   setting.about_setting_about_our_offices_header_title,
        // about_setting_about_our_offices_one_title:
        //   setting.about_setting_about_our_offices_one_title,
        // about_setting_about_our_offices_one_address:
        //   setting.about_setting_about_our_offices_one_address,
        // about_setting_about_our_offices_one_address_lat:
        //   setting.about_setting_about_our_offices_one_address_lat,
        // about_setting_about_our_offices_one_address_long:
        //   setting.about_setting_about_our_offices_one_address_long,
        // about_setting_about_our_offices_one_phone:
        //   setting.about_setting_about_our_offices_one_phone,
        // about_setting_about_our_offices_one_email:
        //   setting.about_setting_about_our_offices_one_email,
        // about_setting_about_our_offices_two_title:
        //   setting.about_setting_about_our_offices_two_title,
        // about_setting_about_our_offices_two_address:
        //   setting.about_setting_about_our_offices_two_address,
        // about_setting_about_our_offices_one_address_lat:
        //   setting.about_setting_about_our_offices_one_address_lat,
        // about_setting_about_our_offices_two_address_long:
        //   setting.about_setting_about_our_offices_two_address_long,
        // about_setting_about_our_offices_two_phone:
        //   setting.about_setting_about_our_offices_two_phone,
        // about_setting_about_our_offices_two_email:
        //   setting.about_setting_about_our_offices_two_email,
      };

      formik.values.about_setting_about_our_offices_one_en_address =
        setting.about_setting_about_our_offices_one_address?.['en'] || '';
      formik.values.about_setting_about_our_offices_one_sp_address =
        setting.about_setting_about_our_offices_one_address?.['sp'] || '';

      formik.values.about_setting_about_our_offices_one_en_address_lat =
        setting.about_setting_about_our_offices_one_address_lat?.['en'] || 36.6588474;
      formik.values.about_setting_about_our_offices_one_sp_address_lat =
        setting.about_setting_about_our_offices_one_address_lat?.['sp'] || 36.6588474;

      formik.values.about_setting_about_our_offices_one_en_address_long =
        setting.about_setting_about_our_offices_one_address_long?.['en'] || -4.5811173;
      formik.values.about_setting_about_our_offices_one_sp_address_long =
        setting.about_setting_about_our_offices_one_address_long?.['sp'] || -4.5811173;

      formik.values.about_setting_about_our_offices_one_phone =
        setting.about_setting_about_our_offices_one_phone || '';
      formik.values.about_setting_about_our_offices_one_email =
        setting.about_setting_about_our_offices_one_email || '';

      formik.values.about_setting_about_our_offices_two_en_address =
        setting.about_setting_about_our_offices_two_address?.['en'] || '';
      formik.values.about_setting_about_our_offices_two_sp_address =
        setting.about_setting_about_our_offices_two_address?.['sp'] || '';

      formik.values.about_setting_about_our_offices_two_en_address_lat =
        setting.about_setting_about_our_offices_two_address_lat?.['en'] || 41.3306391;
      formik.values.about_setting_about_our_offices_two_sp_address_lat =
        setting.about_setting_about_our_offices_two_address_lat?.['sp'] || 41.3306391;

      formik.values.about_setting_about_our_offices_two_en_address_long =
        setting.about_setting_about_our_offices_two_address_long?.['en'] || 2.0022707;
      formik.values.about_setting_about_our_offices_two_sp_address_long =
        setting.about_setting_about_our_offices_two_address_long?.['sp'] || 2.0022707;

      formik.values.about_setting_about_our_offices_two_phone =
        setting.about_setting_about_our_offices_two_phone || '';
      formik.values.about_setting_about_our_offices_two_email =
        setting.about_setting_about_our_offices_two_email || '';
      languages.forEach((lang) => {
        formik.values[`about_setting_about_our_offices_header_${lang}_title`] =
          setting.about_setting_about_our_offices_header_title?.[lang] || '';
        formik.values[`about_setting_about_our_offices_one_${lang}_title`] =
          setting.about_setting_about_our_offices_one_title?.[lang] || '';
        formik.values[`about_setting_about_our_offices_two_${lang}_title`] =
          setting.about_setting_about_our_offices_two_title?.[lang] || '';
      }),
        setAboutSettingData(dataStore);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const { ref: firstENAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        // about_setting_about_our_offices_one_address
        formik.setFieldValue(
          'about_setting_about_our_offices_one_en_address',
          place.formatted_address
        );
        formik.setFieldValue('about_setting_about_our_offices_one_en_address_lat', latitude);
        formik.setFieldValue('about_setting_about_our_offices_one_en_address_long', longitude);
      } else {
        formik.setFieldValue(
          'about_setting_about_our_offices_one_en_address',
          formik.values.about_setting_about_our_offices_one_en_address
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_one_en_address_lat',
          formik.values.about_setting_about_our_offices_one_en_address_lat
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_one_en_address_long',
          formik.values.about_setting_about_our_offices_one_en_address_long
        );
      }
    },
    options: {
      language: 'en',
      types: ['geocode'],
    },
  });

  const { ref: firstSPAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        // about_setting_about_our_offices_one_address
        formik.setFieldValue(
          'about_setting_about_our_offices_one_sp_address',
          place.formatted_address
        );
        formik.setFieldValue('about_setting_about_our_offices_one_sp_address_lat', latitude);
        formik.setFieldValue('about_setting_about_our_offices_one_sp_address_long', longitude);
      } else {
        formik.setFieldValue(
          'about_setting_about_our_offices_one_sp_address',
          formik.values.about_setting_about_our_offices_one_sp_address
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_one_sp_address_lat',
          formik.values.about_setting_about_our_offices_one_sp_address_lat
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_one_sp_address_long',
          formik.values.about_setting_about_our_offices_one_sp_address_long
        );
      }
    },
    options: {
      language: 'es',
      types: ['geocode'],
    },
  });

  const { ref: secondENAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        formik.setFieldValue(
          'about_setting_about_our_offices_two_en_address',
          place.formatted_address
        );
        formik.setFieldValue('about_setting_about_our_offices_two_en_address_lat', latitude);
        formik.setFieldValue('about_setting_about_our_offices_two_en_address_long', longitude);
      } else {
        formik.setFieldValue(
          'about_setting_about_our_offices_two_en_address',
          formik.values.about_setting_about_our_offices_two_en_address
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_two_en_address_lat',
          formik.values.about_setting_about_our_offices_two_en_address_lat
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_two_en_address_long',
          formik.values.about_setting_about_our_offices_two_en_address_long
        );
      }
    },
    options: {
      language: 'en',
      types: ['geocode'],
    },
  });

  const { ref: secondSPAddress } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        formik.setFieldValue(
          'about_setting_about_our_offices_two_sp_address',
          place.formatted_address
        );
        formik.setFieldValue('about_setting_about_our_offices_two_sp_address_lat', latitude);
        formik.setFieldValue('about_setting_about_our_offices_two_sp_address_long', longitude);
      } else {
        formik.setFieldValue(
          'about_setting_about_our_offices_two_sp_address',
          formik.values.about_setting_about_our_offices_two_sp_address
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_two_sp_address_lat',
          formik.values.about_setting_about_our_offices_two_sp_address_lat
        );
        formik.setFieldValue(
          'about_setting_about_our_offices_two_sp_address_long',
          formik.values.about_setting_about_our_offices_two_sp_address_long
        );
      }
    },
    options: {
      language: 'es',
      types: ['geocode'],
    },
  });

  return (
    <>
      <AboutTab t={t} />
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_our_offices_header_title_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[
                              `about_setting_about_our_offices_header_${lang}_title`
                            ] &&
                            formik.errors[`about_setting_about_our_offices_header_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `about_setting_about_our_offices_header_${lang}_title`
                                ] &&
                                !formik.errors[
                                  `about_setting_about_our_offices_header_${lang}_title`
                                ]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`about_setting_about_our_offices_header_${lang}_title`}
                          placeholder={t(
                            'page.about_setting_about_our_offices_header_title_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_our_offices_header_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values[`about_setting_about_our_offices_header_${lang}_title`]
                          }
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`about_setting_about_our_offices_header_${lang}_title`] &&
                          formik.errors[`about_setting_about_our_offices_header_${lang}_title`] ? (
                            <div>
                              {t(
                                formik.errors[
                                  `about_setting_about_our_offices_header_${lang}_title`
                                ]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_our_offices_one_title_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`about_setting_about_our_offices_one_${lang}_title`] &&
                            formik.errors[`about_setting_about_our_offices_one_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `about_setting_about_our_offices_one_${lang}_title`
                                ] &&
                                !formik.errors[`about_setting_about_our_offices_one_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`about_setting_about_our_offices_one_${lang}_title`}
                          placeholder={t(
                            'page.about_setting_about_our_offices_one_title_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_our_offices_one_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`about_setting_about_our_offices_one_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`about_setting_about_our_offices_one_${lang}_title`] &&
                          formik.errors[`about_setting_about_our_offices_one_${lang}_title`] ? (
                            <div>
                              {t(
                                formik.errors[`about_setting_about_our_offices_one_${lang}_title`]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_one_address_label')} [EN]
                    </Form.Label>
                    <Form.Control
                      ref={firstENAddress}
                      className={
                        formik.touched.about_setting_about_our_offices_one_en_address &&
                        formik.errors.about_setting_about_our_offices_one_en_address
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_one_en_address
                          ? 'form-field-success'
                          : ''
                      }
                      as="textarea"
                      rows={1}
                      type="text"
                      name="about_setting_about_our_offices_one_en_address"
                      placeholder={t(
                        'page.about_setting_about_our_offices_one_address_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_one_en_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_one_en_address}
                    />

                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_one_en_address &&
                        formik.errors.about_setting_about_our_offices_one_en_address && (
                          <div>
                            {t(formik.errors.about_setting_about_our_offices_one_en_address)}
                          </div>
                        )}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_one_address_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      ref={firstSPAddress}
                      className={
                        formik.touched.about_setting_about_our_offices_one_sp_address &&
                        formik.errors.about_setting_about_our_offices_one_sp_address
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_one_sp_address
                          ? 'form-field-success'
                          : ''
                      }
                      as="textarea"
                      rows={1}
                      type="text"
                      name="about_setting_about_our_offices_one_address"
                      placeholder={t(
                        'page.about_setting_about_our_offices_one_address_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_one_sp_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_one_sp_address}
                    />

                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_one_sp_address &&
                        formik.errors.about_setting_about_our_offices_one_sp_address && (
                          <div>
                            {t(formik.errors.about_setting_about_our_offices_one_sp_address)}
                          </div>
                        )}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_one_phone_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.about_setting_about_our_offices_one_phone &&
                        formik.errors.about_setting_about_our_offices_one_phone
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_one_phone
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="about_setting_about_our_offices_one_phone"
                      placeholder={t('page.about_setting_about_our_offices_one_phone_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_one_phone',
                          value.trimStart()
                        );
                      }}
                      onInput={(e) => {
                        // var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        // e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        var value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
                        var formattedValue = '';

                        // Check for the different possible formats and apply the mask
                        if (value.length > 0) {
                          formattedValue = '(' + value.substring(0, 3);
                          if (value.length > 3) {
                            formattedValue += ') ' + value.substring(3, 6);
                            if (value.length > 6) {
                              formattedValue += '-' + value.substring(6, 10);
                            }
                          }
                        }
                        e.target.value = formattedValue;
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_one_phone}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_one_phone &&
                        formik.errors.about_setting_about_our_offices_one_phone && (
                          <div>{t(formik.errors.about_setting_about_our_offices_one_phone)}</div>
                        )}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_one_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.about_setting_about_our_offices_one_email &&
                        formik.errors.about_setting_about_our_offices_one_email
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_one_email
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="about_setting_about_our_offices_one_email"
                      placeholder={t('page.about_setting_about_our_offices_one_email_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_one_email',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_one_email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_one_email &&
                        formik.errors.about_setting_about_our_offices_one_email && (
                          <div>{t(formik.errors.about_setting_about_our_offices_one_email)}</div>
                        )}
                    </div>
                  </Form.Group>

                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_our_offices_two_title_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`about_setting_about_our_offices_two_${lang}_title`] &&
                            formik.errors[`about_setting_about_our_offices_two_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `about_setting_about_our_offices_two_${lang}_title`
                                ] &&
                                !formik.errors[`about_setting_about_our_offices_two_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`about_setting_about_our_offices_two_${lang}_title`}
                          placeholder={t(
                            'page.about_setting_about_our_offices_two_title_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_our_offices_two_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`about_setting_about_our_offices_two_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`about_setting_about_our_offices_two_${lang}_title`] &&
                          formik.errors[`about_setting_about_our_offices_two_${lang}_title`] ? (
                            <div>
                              {t(
                                formik.errors[`about_setting_about_our_offices_two_${lang}_title`]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_two_address_label')} [EN]
                    </Form.Label>
                    <Form.Control
                      ref={secondENAddress}
                      className={
                        formik.touched.about_setting_about_our_offices_two_en_address &&
                        formik.errors.about_setting_about_our_offices_two_en_address
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_two_en_address
                          ? 'form-field-success'
                          : ''
                      }
                      as="textarea"
                      rows={1}
                      type="text"
                      name="about_setting_about_our_offices_two_en_address"
                      placeholder={t(
                        'page.about_setting_about_our_offices_two_address_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_two_en_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_two_en_address}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_two_en_address &&
                        formik.errors.about_setting_about_our_offices_two_en_address && (
                          <div>
                            {t(formik.errors.about_setting_about_our_offices_two_en_address)}
                          </div>
                        )}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_two_address_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      ref={secondSPAddress}
                      className={
                        formik.touched.about_setting_about_our_offices_two_sp_address &&
                        formik.errors.about_setting_about_our_offices_two_sp_address
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_two_sp_address
                          ? 'form-field-success'
                          : ''
                      }
                      as="textarea"
                      rows={1}
                      type="text"
                      name="about_setting_about_our_offices_two_address"
                      placeholder={t(
                        'page.about_setting_about_our_offices_two_address_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_two_sp_address',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_two_sp_address}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_two_sp_address &&
                        formik.errors.about_setting_about_our_offices_two_sp_address && (
                          <div>
                            {t(formik.errors.about_setting_about_our_offices_two_sp_address)}
                          </div>
                        )}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_two_phone_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.about_setting_about_our_offices_two_phone &&
                        formik.errors.about_setting_about_our_offices_two_phone
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_two_phone
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="about_setting_about_our_offices_two_phone"
                      placeholder={t('page.about_setting_about_our_offices_two_phone_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_two_phone',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      onInput={(e) => {
                        // var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        // e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        var value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
                        var formattedValue = '';

                        // Check for the different possible formats and apply the mask
                        if (value.length > 0) {
                          formattedValue = '(' + value.substring(0, 3);
                          if (value.length > 3) {
                            formattedValue += ') ' + value.substring(3, 6);
                            if (value.length > 6) {
                              formattedValue += '-' + value.substring(6, 10);
                            }
                          }
                        }
                        e.target.value = formattedValue;
                      }}
                      value={formik.values.about_setting_about_our_offices_two_phone}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_two_phone &&
                        formik.errors.about_setting_about_our_offices_two_phone && (
                          <div>{t(formik.errors.about_setting_about_our_offices_two_phone)}</div>
                        )}
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_our_offices_two_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.about_setting_about_our_offices_two_email &&
                        formik.errors.about_setting_about_our_offices_two_email
                          ? 'form-field-error'
                          : formik.touched.about_setting_about_our_offices_two_email
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="about_setting_about_our_offices_two_email"
                      placeholder={t('page.about_setting_about_our_offices_two_email_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'about_setting_about_our_offices_two_email',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.about_setting_about_our_offices_two_email}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.about_setting_about_our_offices_two_email &&
                        formik.errors.about_setting_about_our_offices_two_email && (
                          <div>{t(formik.errors.about_setting_about_our_offices_two_email)}</div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
AboutOurOfficeSetting.propTypes = {
  t: PropTypes.func,
};

export { AboutOurOfficeSetting };

import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { CouponCodeService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListCouponCode = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['coupon-code-list', params],
    () => CouponCodeService.listCouponCode({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddCouponCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeService.addCouponCode, {
    onSuccess,
    onError,
  });
};

const useViewCouponCode = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('coupon-code-view', () => CouponCodeService.viewCouponCode({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateCouponCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeService.updateCouponCode, {
    onSuccess,
    onError,
  });
};

const useUpdateCouponCodeStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeService.updateCouponCodeStatus, {
    onSuccess,
    onError,
  });
};

const useDeleteCouponCode = (onSuccess, onError = onDefaultError) => {
  return useMutation(CouponCodeService.deleteCouponCode, {
    onSuccess,
    onError,
  });
};

export {
  useDeleteCouponCode,
  useUpdateCouponCodeStatus,
  useUpdateCouponCode,
  useViewCouponCode,
  useAddCouponCode,
  useListCouponCode,
};

import * as Yup from 'yup';
const languages = ['en', 'sp'];

let validationSchema = Yup.object({
  // contact_setting_contact_seo_en_title: Yup.string(
  //   'page.general_setting_seo_title_required'
  // ).required('page.general_setting_seo_title_required'),

  // contact_setting_contact_seo_sp_title: Yup.string(
  //   'page.general_setting_seo_title_required'
  // ).required('page.general_setting_seo_title_required'),
  // contact_setting_contact_seo_en_description: Yup.string(
  //   'page.general_setting_seo_description_required'
  // ).required('page.general_setting_seo_description_required'),
  // contact_setting_contact_seo_sp_description: Yup.string(
  //   'page.general_setting_seo_description_required'
  // ).required('page.general_setting_seo_description_required'),

  ...languages.reduce((acc, lang) => {
    acc[`contact_setting_contact_seo_${lang}_title`] = Yup.string(
      'page.general_setting_seo_title_required'
    )
      .required('page.general_setting_seo_title_required')
      .max(255, 'page.general_setting_seo_title_max');
    acc[`contact_setting_contact_seo_${lang}_description`] = Yup.string(
      'page.general_setting_seo_description_required'
    )
      .required('page.general_setting_seo_description_required')
      .max(500, 'page.general_setting_seo_description_max');
    return acc;
  }, {}),
});
export default validationSchema;

import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ExtraServiceService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListExtraService = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['ExtraService-list', params],
    () => ExtraServiceService.listExtraService({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddExtraService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ExtraServiceService.addExtraService, {
    onSuccess,
    onError,
  });
};

const useViewExtraService = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['Extra-service-view', _id],
    () => ExtraServiceService.viewExtraService({ _id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useUpdateExtraService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ExtraServiceService.updateExtraService, {
    onSuccess,
    onError,
  });
};

const useUpdateExtraServiceStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(ExtraServiceService.updateExtraServiceStatus, {
    onSuccess,
    onError,
  });
};

const useDeleteExtraService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ExtraServiceService.deleteExtraService, {
    onSuccess,
    onError,
  });
};

const useListExtraServiceName = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['ExtraService-list-name', params],
    () => ExtraServiceService.viewExtraServiceName({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

export {
  useDeleteExtraService,
  useUpdateExtraServiceStatus,
  useUpdateExtraService,
  useViewExtraService,
  useAddExtraService,
  useListExtraService,
  useListExtraServiceName,
};

import * as Yup from 'yup';
const languages = ['en', 'sp'];

let validationSchema = Yup.object({
  ...languages.reduce((acc, lang) => {
    acc[`contact_setting_contact_banner_${lang}_title`] = Yup.string(
      'page.contact_setting_contact_banner_title_required'
    )
      .required('page.contact_setting_contact_banner_title_required')
      .max(255, 'page.contact_setting_contact_banner_title_max');

    acc[`contact_setting_contact_info_request_${lang}_title`] = Yup.string(
      'page.contact_setting_contact_info_request_title_required'
    )
      .required('page.contact_setting_contact_info_request_title_required')
      .max(255, 'page.contact_setting_contact_info_request_title_max');

    acc[`contact_setting_contact_info_${lang}_description`] = Yup.string(
      'page.contact_setting_contact_info_description_required'
    )
      .required('page.contact_setting_contact_info_description_required')
      .max(255, 'page.contact_setting_contact_info_description_max');

    acc[`contact_setting_contact_one_address_${lang}_title`] = Yup.string(
      'page.contact_setting_contact_info_one_address_title_required'
    )
      .required('page.contact_setting_contact_info_one_address_title_required')
      .max(255, 'page.contact_setting_contact_info_one_address_title_max');

    acc[`contact_setting_contact_two_address_${lang}_title`] = Yup.string(
      'page.contact_setting_contact_info_two_address_title_required'
    )
      .required('page.contact_setting_contact_info_two_address_title_required')
      .max(255, 'page.contact_setting_contact_info_two_address_title_max');

    return acc;
  }, {}),

  // contact_setting_contact_banner_en_title: Yup.string(
  //   'page.contact_setting_contact_banner_title_required'
  // )
  //   .required('page.contact_setting_contact_banner_title_required')
  //   .max(255, 'page.contact_setting_contact_banner_title_max'),
  // contact_setting_contact_banner_sp_title: Yup.string(
  //   'page.contact_setting_contact_banner_title_required'
  // )
  //   .required('page.contact_setting_contact_banner_title_required')
  // //   .max(255, 'page.contact_setting_contact_banner_title_max'),
  // contact_setting_contact_info_request_en_title: Yup.string(
  //   'page.contact_setting_contact_info_request_title_required'
  // )
  //   .required('page.contact_setting_contact_info_request_title_required')
  //   .max(255, 'page.contact_setting_contact_info_request_title_max'),
  // contact_setting_contact_info_request_sp_title: Yup.string(
  //   'page.contact_setting_contact_info_request_title_required'
  // )
  //   .required('page.contact_setting_contact_info_request_title_required')
  //   .max(255, 'page.contact_setting_contact_info_request_title_max'),
  // contact_setting_contact_info_en_description: Yup.string(
  //   'page.contact_setting_contact_info_description_required'
  // )
  //   .required('page.contact_setting_contact_info_description_required')
  //   .max(500, 'page.contact_setting_contact_info_description_max'),
  // contact_setting_contact_info_sp_description: Yup.string(
  //   'page.contact_setting_contact_info_description_required'
  // )
  //   .required('page.contact_setting_contact_info_description_required')
  //   .max(500, 'page.contact_setting_contact_info_description_max'),
  contact_setting_contact_info_one_en_address: Yup.string(
    'page.contact_setting_contact_info_one_address_required'
  )
    .required('page.contact_setting_contact_info_one_address_required')
    .max(255, 'page.contact_setting_contact_info_one_address_max'),
  contact_setting_contact_info_one_sp_address: Yup.string(
    'page.contact_setting_contact_info_one_address_required'
  )
    .required('page.contact_setting_contact_info_one_address_required')
    .max(255, 'page.contact_setting_contact_info_one_address_max'),

  contact_setting_contact_info_two_en_address: Yup.string(
    'page.contact_setting_contact_info_two_address_required'
  )
    .required('page.contact_setting_contact_info_two_address_required')
    .max(255, 'page.contact_setting_contact_info_two_address_max'),
  contact_setting_contact_info_two_sp_address: Yup.string(
    'page.contact_setting_contact_info_two_address_required'
  )
    .required('page.contact_setting_contact_info_two_address_required')
    .max(255, 'page.contact_setting_contact_info_two_address_max'),

  contact_setting_contact_info_phone: Yup.string('page.contact_setting_contact_info_phone_required')
    .required('page.contact_setting_contact_info_phone_required')
    .min(14, 'page.contact_setting_contact_info_phone_min')
    .max(25, 'page.contact_setting_contact_info_phone_max'),
  contact_setting_contact_info_email: Yup.string('page.contact_setting_contact_info_email_required')
    .required('page.contact_setting_contact_info_email_required')
    .email('page.contact_setting_contact_info_email_invalid') // email format validation
    .test('has-domain', 'page.contact_setting_contact_info_email_invalid', function (value) {
      // Regex to check if domain exists in the email
      const domainRegex = /@([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/;
      const isValidDomain = domainRegex.test(value);
      return (
        isValidDomain ||
        this.createError({ message: 'page.contact_setting_contact_info_email_invalid' })
      );
    })
    .max(100, 'page.contact_setting_contact_info_email_max'),
  contact_setting_contact_banner_image: Yup.mixed(
    'page.contact_setting_contact_banner_image_required'
  ).required('page.contact_setting_contact_banner_image_required'),
});
export default validationSchema;

import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PrivateLayout } from 'common/layouts';
import { loggedUser } from 'store/features/authSlice';

const PrivateRoute = () => {
  // Calling selector hook from redux, to get data from stores, like loader, login info
  const checkLoggedInUser = useSelector(loggedUser);
  const location = useLocation();
  const navigate = useNavigate();
  window.addEventListener('storage', (e) => {
    if (e.isLogout !== undefined && e.isLogout !== '' && e.isLogout === 1) {
      navigate('/login');
    }
  });
  useEffect(() => {
    if (window.localStorage.isShowingError) {
      // toast.error(window.localStorage.isShowingError);
      window.localStorage.removeItem('isShowingError');
    }
  });
  /**
   * Checking if user is registered user or not
   */
  const isAdminLoggedIn = checkLoggedInUser.isAdminLoggedIn;
  return isAdminLoggedIn ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;

import * as Yup from 'yup';

const languages = ['en', 'sp']; // Add other languages as needed

let validationSchema = Yup.object(
  languages.reduce((acc, lang) => {
    acc[`home_setting_why_choose_us_one_${lang}_title`] = Yup.string()
      .required(`page.home_setting_why_choose_us_one_title_required`)
      .max(100, `page.home_setting_why_choose_us_one_title_max`);

    acc[`home_setting_why_choose_us_one_${lang}_description`] = Yup.string()
      .required(`page.home_setting_why_choose_us_one_description_required`)
      .max(255, `page.home_setting_why_choose_us_one_description_max`);

    acc[`home_setting_why_choose_us_two_${lang}_title`] = Yup.string()
      .required(`page.home_setting_why_choose_us_two_title_required`)
      .max(100, `page.home_setting_why_choose_us_two_title_max`);

    acc[`home_setting_why_choose_us_two_${lang}_description`] = Yup.string()
      .required(`page.home_setting_why_choose_us_two_description_required`)
      .max(255, `page.home_setting_why_choose_us_two_description_max`);

    acc[`home_setting_why_choose_us_three_${lang}_title`] = Yup.string()
      .required(`page.home_setting_why_choose_us_three_title_required`)
      .max(100, `page.home_setting_why_choose_us_three_title_max`);

    acc[`home_setting_why_choose_us_three_${lang}_description`] = Yup.string()
      .required(`page.home_setting_why_choose_us_three_description_required`)
      .max(255, `page.home_setting_why_choose_us_three_description_max`);

    acc[`home_setting_why_choose_us_four_${lang}_title`] = Yup.string()
      .required(`page.home_setting_why_choose_us_four_title_required`)
      .max(100, `page.home_setting_why_choose_us_four_title_max`);

    acc[`home_setting_why_choose_us_four_${lang}_description`] = Yup.string()
      .required(`page.home_setting_why_choose_us_four_description_required`)
      .max(255, `page.home_setting_why_choose_us_four_description_max`);

    acc[`home_setting_why_choose_us_one_image`] = Yup.mixed().required(
      'page.home_setting_why_choose_us_one_image_required'
    );
    acc[`home_setting_why_choose_us_two_image`] = Yup.mixed().required(
      'page.home_setting_why_choose_us_two_image_required'
    );
    acc[`home_setting_why_choose_us_three_image`] = Yup.mixed().required(
      'page.home_setting_why_choose_us_three_image_required'
    );
    acc[`home_setting_why_choose_us_four_image`] = Yup.mixed().required(
      'page.home_setting_why_choose_us_four_image_required'
    );
    return acc;
  }, {})
);

export default validationSchema;

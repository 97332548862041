import client from 'libs/HttpClient';

class FeatureService {
  static listFeature({ params }) {
    return client.get(`feature/list`, { params });
  }
  static addFeature(request) {
    return client.post(`feature/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewFeature(params) {
    return client.get(`feature/get`, {
      params,
    });
  }
  static statusChangeFeature(request) {
    return client.put(`feature/status_change`, request);
  }
  static updateFeature(request) {
    console.log('here sending', request);
    return client.post(`feature/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteFeature(params) {
    return client.delete(`feature/delete`, { params });
  }
  static getServiceList() {
    return client.get(`feature/get_services`);
  }
}
export { FeatureService };

import React from 'react';
import { useDropzone } from 'react-dropzone';

const VideoDropzone = ({ setFieldValue, handleFileChange, t }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles); // Custom handler for files
      setFieldValue('images', acceptedFiles); // Set field in Formik
    },
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogg'],
    },
    multiple: true,
  });

  return (
    <div {...getRootProps()} className={`dropzone mt-3 ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      <svg
        width="65"
        height="65"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.6041 54.1663C13.4964 54.1663 9.9869 52.7445 7.07544 49.9007C4.16398 47.057 2.70825 43.5813 2.70825 39.4736C2.70825 35.9528 3.76902 32.8156 5.89054 30.0622C8.01207 27.3087 10.7881 25.5483 14.2187 24.7809C15.3471 20.6281 17.6041 17.2653 20.9895 14.6924C24.3749 12.1195 28.2117 10.833 32.4999 10.833C37.7812 10.833 42.2612 12.6724 45.94 16.3512C49.6189 20.0301 51.4583 24.5101 51.4583 29.7913C54.5728 30.1525 57.157 31.4953 59.2109 33.82C61.2647 36.1446 62.2916 38.8643 62.2916 41.9788C62.2916 45.3643 61.1067 48.2419 58.7369 50.6117C56.3671 52.9814 53.4895 54.1663 50.1041 54.1663H35.2083C33.7187 54.1663 32.4435 53.636 31.3827 52.5752C30.322 51.5144 29.7916 50.2393 29.7916 48.7497V34.8018L25.4583 38.9997L21.6666 35.208L32.4999 24.3747L43.3333 35.208L39.5416 38.9997L35.2083 34.8018V48.7497H50.1041C51.9999 48.7497 53.6024 48.0952 54.9114 46.7861C56.2204 45.4771 56.8749 43.8747 56.8749 41.9788C56.8749 40.083 56.2204 38.4806 54.9114 37.1716C53.6024 35.8625 51.9999 35.208 50.1041 35.208H46.0416V29.7913C46.0416 26.0448 44.7213 22.8512 42.0807 20.2106C39.44 17.57 36.2465 16.2497 32.4999 16.2497C28.7534 16.2497 25.5598 17.57 22.9192 20.2106C20.2786 22.8512 18.9583 26.0448 18.9583 29.7913H17.6041C14.986 29.7913 12.7517 30.7167 10.901 32.5674C9.05027 34.4181 8.12492 36.6525 8.12492 39.2705C8.12492 41.8886 9.05027 44.1229 10.901 45.9736C12.7517 47.8243 14.986 48.7497 17.6041 48.7497H24.3749V54.1663H17.6041Z"
          fill="white"
        />
      </svg>
      <div className="upload-box">
        <p>
          {t('page.Video_dropzone_drag_and_drop_files')}{' '}
          <span className="browse-link">{t('page.video_dropzone_browser')}</span>
        </p>
        <small>{t('page.video_dropzone_accessors')}</small>
      </div>
    </div>
  );
};

export default VideoDropzone;

export const commonOption = (t) => {
  return [
    { value: '', label: `${t('page.select_status')}` },
    { value: true, label: `${t('page.active_status_name')}` },
    { value: false, label: `${t('page.in_active_status_name')}` },
  ];
};

export const userStatusOption = (t) => {
  return [
    { value: '', label: `${t('page.users_user_status_name')}` },
    { value: true, label: `${t('page.active_status_name')}` },
    { value: false, label: `${t('page.in_active_status_name')}` },
  ];
};
export const adminStatusOption = (t) => {
  return [
    { value: '', label: `${t('page.users_admin_status_name')}` },
    { value: true, label: `${t('page.active_status_name')}` },
    { value: false, label: `${t('page.in_active_status_name')}` },
  ];
};
export const userAccessOptions = (t) => {
  return [
    { value: '', label: `${t('page.users_access_name')}` },
    { value: 2, label: `${t('page.users_user_type_user')}` },
  ];
};
export const userSignupTypeOptions = (t) => {
  return [
    { value: '', label: `${t('page.users_signup_type_name')}` },
    { value: 1, label: `${t('page.users_signup_type_email')}` },
    { value: 2, label: `${t('page.users_signup_type_google')}` },
    { value: 3, label: `${t('page.users_signup_type_facebook')}` },
  ];
};
export const typeDayOrUnitOptions = (t) => {
  return [
    { value: '', label: `${t('page.user_select_type')}` },
    { value: 1, label: `${t('page.user_select_type_day')}` },
    { value: 2, label: `${t('page.user_select_type_unit')}` },
  ];
};

export const couponType = (t) => {
  return [
    { value: '', label: `${t('page.coupon_code_select_type')}` },
    { value: 1, label: `${t('page.coupon_code_type_fixed')}` },
    { value: 2, label: `${t('page.coupon_code_type_percentage')}` },
  ];
};

export const couponOption = (t) => {
  return [
    { value: '', label: `${t('page.select_status')}` },
    { value: 1, label: `${t('page.active_status_name')}` },
    { value: 2, label: `${t('page.in_active_status_name')}` },
  ];
};

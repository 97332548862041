import { useMutation, useQuery } from 'react-query';

const { ServiceService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListService = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['service-list', params], () => ServiceService.listService({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceService.addService, {
    onSuccess,
    onError,
  });
};

const useViewService = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('service-view', () => ServiceService.viewService({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceService.updateService, {
    onSuccess,
    onError,
  });
};

const useStatusChangeService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceService.statusChangeService, {
    onSuccess,
    onError,
  });
};

const useDeleteService = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceService.deleteService, {
    onSuccess,
    onError,
  });
};

export {
  useListService,
  useAddService,
  useViewService,
  useUpdateService,
  useStatusChangeService,
  useDeleteService,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import { routes } from 'config';

const ListBranchPage = React.lazy(() => import('./ListServiceCategoryPage'));
const AddBranchPage = React.lazy(() => import('./AddServiceCategoryPage'));
const EditBranchPage = React.lazy(() => import('./EditServiceCategoryPage'));

const ServiceCategoryRoutes = ({ t }) => {
  return (
    <Routes>
      <Route path={routes.LIST} element={<ListBranchPage t={t} />} />
      <Route exact path={routes.ADD} element={<AddBranchPage t={t} />} />
      <Route exact path={routes.EDIT + ':id'} element={<EditBranchPage t={t} />} />
    </Routes>
  );
};
ServiceCategoryRoutes.propTypes = {
  t: PropTypes.func,
};
export default ServiceCategoryRoutes;

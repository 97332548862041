import * as Yup from 'yup';

const languages = ['en', 'sp']; // Add other languages as needed

const validationSchema = Yup.object(
  languages.reduce((acc, lang) => {
    acc[`home_setting_home_motorcycle_lineup_${lang}_title`] = Yup.string()
      .required(`page.home_setting_home_motorcycle_lineup_title_required`)
      .max(255, `page.home_setting_home_motorcycle_lineup_title_max`);

    acc[`home_setting_home_motorcycle_lineup_${lang}_description`] = Yup.string()
      .required(`page.home_setting_home_motorcycle_lineup_description_required`)
      .max(500, `page.home_setting_home_motorcycle_lineup_description_max`);

    return acc;
  }, {})
);

export default validationSchema;

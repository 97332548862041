import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import CustomSelect from 'react-select';
import { t } from 'i18next';
import '../../assets/scss/components/_TNinput.scss';
import TNDatePicker from './TNDatePicker';
import CurrencyInput from 'react-currency-input-field';

const TNInput = ({
  type = 'text',
  name,
  label,
  placeholder,
  optionsArray = [],
  isPasswordField = false,
  className = '',
  classNameIcon = '',
  classNameGroup = '',
  classNameLabel = '',
  classNamePrefix = '',
  inputGroupText = null,
  touched = {},
  errors = {},
  children,
  labelSwitch,
  optionValue,
  labelCheck,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  // Determine the input type for password visibility toggle
  const inputTypeField = isPasswordField && showPassword ? 'text' : type;

  // Get class based on validation state
  const getClass = () => {
    if (_.get(touched, name) && !_.get(errors, name)) {
      return `${className} ${type === 'password' ? 'icon-field-success' : 'input-field-success'}`;
    }
    if (_.get(touched, name) && _.get(errors, name)) {
      return `${className} ${type === 'password' ? 'icon-field-error' : 'input-field-error'}`;
    }
    return className;
  };

  const getInputGroupClass = () => {
    if (_.get(touched, name) && _.get(errors, name)) {
      return `${classNameIcon} input-icon-error`;
    }
    if (_.get(touched, name) && !_.get(errors, name)) {
      return `${classNameIcon} input-icon-success`;
    }
    return classNameIcon;
  };

  // Render based on input type
  const renderInputField = () => {
    switch (type) {
      case 'select':
        return (
          <CustomSelect
            {...props}
            name={name}
            id={name}
            className={getClass()}
            classNamePrefix={`${classNamePrefix ? classNamePrefix : 'dropdown-common-prefix'}`}
          />
        );
      case 'textarea':
        return (
          <Form.Control
            {...props}
            as="textarea"
            name={name}
            id={name}
            placeholder={placeholder}
            className={getClass()}
          />
        );
      case 'checkbox':
        return optionsArray.map((option, index) => (
          <Form.Check
            {...props}
            type="checkbox"
            key={index}
            id={`${name}-${index}`}
            label={labelCheck}
            name={name}
            value={option.value}
            className={getClass()}
          />
        ));
      case 'radio':
        return optionsArray.map((option, index) => (
          <Form.Check
            {...props}
            type="radio"
            key={index}
            id={`${name}-${index}`}
            label={option.label}
            name={name}
            checked={option.value === optionValue ? true : false} // Compare the option value with the value to check
            value={option.value}
            className={getClass()}
          />
        ));
      case 'switch':
        return (
          <Form.Check
            {...props}
            type="switch"
            id={`${name}`}
            label={labelSwitch}
            name={name}
            className={getClass()}
          />
        );
      case 'number':
        return (
          <Form.Control
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            {...props}
            name={name}
            id={name}
            type={'text'}
            placeholder={placeholder}
            className={getClass()}
          />
        );
      case 'otp':
        return (
          <Form.Control
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
              if (event.target.value.length >= 6) {
                event.preventDefault();
              }
            }}
            {...props}
            name={name}
            id={name}
            type={'text'}
            placeholder={placeholder}
            className={getClass()}
          />
        );
      case 'search':
        return (
          <Form.Group className="search-form-group">
            <Form.Control
              {...props}
              name={name}
              id={name}
              type={'text'}
              placeholder={placeholder}
              className={getClass()}
            />
            <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
          </Form.Group>
        );
      case 'datepicker':
        return <TNDatePicker {...props} />;
      case 'currency': // Add currency case for euro masking
        return (
          <CurrencyInput
            {...props}
            name={name}
            id={name}
            placeholder={placeholder}
            className={getClass()}
            prefix="€"
            decimalsLimit={2}
            intlConfig={{ locale: 'en-IE', currency: 'EUR' }}
          />
        );
      case 'password':
      case 'text':
      case 'email':
      default:
        return isPasswordField || inputGroupText ? (
          <InputGroup>
            <Form.Control
              {...props}
              name={name}
              id={name}
              type={inputTypeField}
              placeholder={placeholder}
              className={getClass()}
            />
            <InputGroup.Text className={getInputGroupClass()}>
              {inputGroupText ? (
                inputGroupText
              ) : (
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        ) : (
          <Form.Control
            {...props}
            name={name}
            id={name}
            type={type}
            placeholder={placeholder}
            className={getClass()}
          />
        );
    }
  };

  return (
    <>
      <Form.Group className={classNameGroup}>
        {label && (
          <Form.Label
            htmlFor={name}
            className={` ${classNameLabel ? classNameLabel : 'field-label field-label-top'}`}>
            {label}
          </Form.Label>
        )}
        {renderInputField()}
        {/* Error Message */}
        {children && <div>{children}</div>}
        <div className="form-field-error-text">
          {_.get(touched, name) && _.get(errors, name) && (
            <div className="input-error-message">{t(_.get(errors, name))}</div>
          )}
        </div>
      </Form.Group>
    </>
  );
};
TNInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  optionsArray: PropTypes.array,
  isPasswordField: PropTypes.bool,
  className: PropTypes.string,
  classNameIcon: PropTypes.string,
  inputGroupText: PropTypes.node,
  touched: PropTypes.object,
  errors: PropTypes.object,
  children: PropTypes.node,
  classNameGroup: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNamePrefix: PropTypes.string,
  labelSwitch: PropTypes.string,
  labelCheck: PropTypes.string,
  optionValue: PropTypes.number,
};
const Text = (props) => <TNInput type="text" {...props} />;
const Number = (props) => <TNInput type="number" {...props} />;
const Password = (props) => <TNInput type="password" isPasswordField {...props} />;
const Select = (props) => <TNInput type="select" {...props} />;
const TextArea = (props) => <TNInput type="textarea" {...props} />;
const Radio = (props) => <TNInput type="radio" {...props} />;
const Checkbox = (props) => <TNInput type="checkbox" {...props} />;
const Switch = (props) => <TNInput type="switch" {...props} />;
const Otp = (props) => <TNInput type="otp" {...props} />;
const Search = (props) => <TNInput type="search" {...props} />;
const DatePicker = (props) => <TNInput type="datepicker" {...props} />;
const Currency = (props) => <TNInput type="currency" {...props} />;
TNInput.Text = Text;
TNInput.Password = Password;
TNInput.Select = Select;
TNInput.TextArea = TextArea;
TNInput.Radio = Radio;
TNInput.Checkbox = Checkbox;
TNInput.Switch = Switch;
TNInput.Number = Number;
TNInput.Otp = Otp;
TNInput.Search = Search;
TNInput.DatePicker = DatePicker;
TNInput.Currency = Currency;
export default TNInput;

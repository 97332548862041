// NotificationContext.js
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

const NotificationContext = createContext();

// Custom hook for accessing notification context
export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  const updateNotificationCount = (count) => {
    setNotificationCount(count);
  };

  return (
    <NotificationContext.Provider value={{ notificationCount, updateNotificationCount }}>
      {children}
    </NotificationContext.Provider>
  );
};
NotificationProvider.propTypes = {
  children: PropTypes.any,
};

export default NotificationProvider;

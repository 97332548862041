import client from 'libs/HttpClient';

class CouponCodeService {
  static listCouponCode({ params }) {
    return client.get(`coupon-code/list`, { params });
  }
  static addCouponCode(request) {
    return client.post(`coupon-code/add`, request);
  }
  static viewCouponCode(params) {
    return client.get(`coupon-code/get`, { params });
  }
  static updateCouponCode(request) {
    return client.put(`coupon-code/edit`, request);
  }
  static deleteCouponCode(params) {
    return client.delete(`coupon-code/delete`, { params });
  }
  static updateCouponCodeStatus(request) {
    return client.put(`coupon-code/update-status`, request);
  }
}
export { CouponCodeService };

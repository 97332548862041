import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import validationSchema from './HomeBannerSettingValidation';
import { FieldArray, Formik } from 'formik';
import { useStoreSettingData, useGetHomeSettingData, useDeleteHomeSettingData } from 'hooks';
import { TNButton } from 'common/components/TNButton';
import { confirmAlert } from 'react-confirm-alert';
import { AlertCommon } from 'common/components/AlertCommon';
import 'assets/scss/page/_generalsetting.scss';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
const languages = ['en', 'sp']; // Add more languages here if needed

const HomeBannerSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    sections: [{ title: '', section: '', image: '', join_us_link: '', join_us_btn_txt: '' }],
  });

  const fileInputRefs = useRef([]);
  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);
  const { refetch } = useGetHomeSettingData(
    {
      tag: 'home_setting_home_banner',
    },
    ({ data: setting }) => {
      // Start with an empty array for sections
      const sections = [];

      // Loop through the potential section indices (e.g., 1_, 2_, etc.)
      let index = 1;
      while (
        setting[`home_setting_home_banner_${index}_title`] ||
        setting[`home_setting_home_banner_${index}_description`] ||
        setting[`home_setting_home_banner_${index}_image`] ||
        setting[`home_setting_home_banner_${index}_join_us_btn_link`] ||
        setting[`home_setting_home_banner_${index}_join_us_btn_txt`]
      ) {
        const sectionData = {};

        // Loop through each language
        languages.forEach((lang) => {
          sectionData[`title_${lang}`] =
            setting[`home_setting_home_banner_${index}_title`]?.[lang] || '';
          sectionData[`section_${lang}`] =
            setting[`home_setting_home_banner_${index}_description`]?.[lang] || '';
          sectionData[`join_us_btn_txt_${lang}`] =
            setting[`home_setting_home_banner_${index}_join_us_btn_txt`]?.[lang] || '';
        });

        sectionData.image = setting[`home_setting_home_banner_${index}_image`] || '';
        sectionData.join_us_link =
          setting[`home_setting_home_banner_${index}_join_us_btn_link`] || '';
        sections.push(sectionData);
        index++;
      }

      // If no sections exist, push a default empty section
      if (sections.length === 0) {
        const defaultSection = {};
        languages.forEach((lang) => {
          defaultSection[`title_${lang}`] = '';
          defaultSection[`section_${lang}`] = '';
          defaultSection[`join_us_btn_txt_${lang}`] = '';
        });
        defaultSection.image = '';
        defaultSection.join_us_link = '';
        sections.push(defaultSection);
      }

      // Prepare the initial values to set in the form
      const fetchedInitialValues = {
        sections: sections,
      };

      console.log('Transformed Initial Values:', fetchedInitialValues);

      // Set the transformed initial values in the state
      setInitialValues(fetchedInitialValues);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    refetch();
  });

  const { mutate: doDeleteSettingData } = useDeleteHomeSettingData((response) => {
    toast.success(response.message);
    refetch();
  });
  /**
   * !This block is for image preview
   */
  const imagePreviewFromik = (file) => {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  };
  const handleCancel = (dirty) => {
    if (dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/setting/home/home-banner">
            {t('page.general_setting_tab_home_banner_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-motorcycle-lineup">
            {t('page.general_setting_tab_home_motorcycle_lineup_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-why-choose-us">
            {t('page.general_setting_tab_home_why_choose_us_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-footer">
            {t('page.general_setting_tab_home_footer_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-seo">
            {t('page.general_setting_tab_home_seo_info_section')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings banner-set" style={{ width: '70%' }}>
          <div className="general-setting">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={async (values) => {
                console.log('Here Submitting ' + values);
                console.log('Here Submitting ' + JSON.stringify(values));
                dispatch(showLoader());
                // Format sections into the required format
                const formattedValues = values.sections.map((section, index) => {
                  const sectionData = {};

                  // Initialize title and description objects for each section and each language
                  languages.forEach((lang) => {
                    // Initialize the keys as empty objects if they don't already exist
                    if (!sectionData[`home_setting_home_banner_${index + 1}_title`]) {
                      sectionData[`home_setting_home_banner_${index + 1}_title`] = {};
                    }
                    if (!sectionData[`home_setting_home_banner_${index + 1}_description`]) {
                      sectionData[`home_setting_home_banner_${index + 1}_description`] = {};
                    }
                    if (!sectionData[`home_setting_home_banner_${index + 1}_join_us_btn_txt`]) {
                      sectionData[`home_setting_home_banner_${index + 1}_join_us_btn_txt`] = {};
                    }
                    // For each language, create key-value pairs like home_setting_home_banner_1_title[en] = value
                    sectionData[`home_setting_home_banner_${index + 1}_title`][lang] =
                      section[`title_${lang}`] || '';

                    sectionData[`home_setting_home_banner_${index + 1}_description`][lang] =
                      section[`section_${lang}`] || '';

                    sectionData[`home_setting_home_banner_${index + 1}_join_us_btn_txt`][lang] =
                      section[`join_us_btn_txt_${lang}`] || '';
                  });

                  // Handle the image, check if it's a file or a URL
                  sectionData[`home_setting_home_banner_${index + 1}_image`] =
                    section.image instanceof File ? section.image : section.image || undefined;
                  sectionData[`home_setting_home_banner_${index + 1}_join_us_btn_link`] =
                    section.join_us_link || '';
                  return sectionData;
                });

                console.log(formattedValues);
                // Flatten the formatted values into a single object
                const submitData = {
                  ...formattedValues.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                  home_setting_banner_store: true,
                };

                console.log('Submitting formatted data:', submitData);

                // Call your function to store the data
                doStoreSettingData(submitData);
              }}
              enableReinitialize>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <FieldArray
                        name="sections"
                        render={(arrayHelpers) => (
                          <>
                            {values.sections && values.sections.length > 0 ? (
                              values.sections.map((section, index) => (
                                <div key={index} className="section-group mt-3">
                                  <h3 className="fs-4 fw-bolder">
                                    {t('page.banner_details')} {index + 1}
                                  </h3>

                                  {languages.map((lang) => (
                                    <div key={lang}>
                                      <Form.Group>
                                        <Form.Label className="field-label field-label-top">
                                          {t('page.home_setting_home_banner_title_label')} [
                                          {lang.toUpperCase()}]
                                        </Form.Label>
                                        <Form.Control
                                          className={
                                            touched.sections?.[index]?.[`title_${lang}`] &&
                                            errors.sections?.[index]?.[`title_${lang}`]
                                              ? 'form-field-error'
                                              : touched.sections?.[index]?.[`title_${lang}`]
                                              ? 'form-field-success'
                                              : ''
                                          }
                                          type="text"
                                          name={`sections.${index}.title_${lang}`}
                                          placeholder={t(
                                            'page.home_setting_home_banner_title_placeholder'
                                          )}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.sections[index][`title_${lang}`] || ''}
                                        />
                                        <div className="form-field-error-text">
                                          {touched.sections?.[index]?.[`title_${lang}`] &&
                                            errors.sections?.[index]?.[`title_${lang}`] && (
                                              <div>
                                                {t(errors.sections[index][`title_${lang}`])}
                                              </div>
                                            )}
                                        </div>
                                      </Form.Group>

                                      <Form.Group>
                                        <Form.Label className="field-label field-label-top">
                                          {t('page.home_setting_home_banner_description_label')} [
                                          {lang.toUpperCase()}]
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          className={
                                            touched.sections?.[index]?.[`section_${lang}`] &&
                                            errors.sections?.[index]?.[`section_${lang}`]
                                              ? 'form-field-error'
                                              : touched.sections?.[index]?.[`section_${lang}`]
                                              ? 'form-field-success'
                                              : ''
                                          }
                                          name={`sections.${index}.section_${lang}`}
                                          placeholder={t(
                                            'page.home_setting_home_banner_description_placeholder'
                                          )}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.sections[index][`section_${lang}`] || ''}
                                        />
                                        <div className="form-field-error-text">
                                          {touched.sections?.[index]?.[`section_${lang}`] &&
                                            errors.sections?.[index]?.[`section_${lang}`] && (
                                              <div>
                                                {t(errors.sections[index][`section_${lang}`])}
                                              </div>
                                            )}
                                        </div>
                                      </Form.Group>
                                    </div>
                                  ))}

                                  {/* Image field */}
                                  <Form.Group className="change-align">
                                    <Form.Label className="field-label field-label-top">
                                      {t('page.home_setting_home_banner_image')}
                                    </Form.Label>
                                    <Form.Control
                                      type="file"
                                      hidden
                                      name={`sections.${index}.image`}
                                      accept=".jpg, .jpeg, .png, .svg"
                                      onChange={(event) => {
                                        const file = event.currentTarget.files[0];
                                        const allowedFileTypes = [
                                          'image/jpeg',
                                          'image/jpg',
                                          'image/png',
                                          'image/svg+xml',
                                        ];
                                        if (file && allowedFileTypes.includes(file.type)) {
                                          setFieldValue(
                                            `sections.${index}.image`,
                                            event.currentTarget.files[0]
                                          );
                                        } else {
                                          toast.error(t('page.invalid_upload_format_of_image'));
                                          event.target.value = '';
                                        }
                                      }}
                                      ref={(el) => (fileInputRefs.current[index] = el)}
                                    />
                                    <Button
                                      type="button"
                                      className="upload-button"
                                      onClick={() => fileInputRefs.current[index]?.click()}>
                                      {t('page.file_upload')}
                                    </Button>
                                    <div className="preview-image change-align">
                                      {values.sections[index].image && (
                                        <div>
                                          <img
                                            src={imagePreviewFromik(values.sections[index].image)}
                                            width="100px"
                                            alt="profile_img"
                                          />
                                          <FontAwesomeIcon
                                            icon={faClose}
                                            onClick={() => {
                                              setFieldValue(`sections.${index}.image`, null);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </Form.Group>

                                  {/* Error message for image field */}
                                  <div className="form-field-error-text">
                                    {touched.sections?.[index]?.image &&
                                    errors.sections?.[index]?.image ? (
                                      <div>{t(errors.sections?.[index]?.image)}</div>
                                    ) : null}
                                  </div>

                                  {languages.map((lang) => (
                                    <Form.Group key={lang}>
                                      <Form.Label className="field-label field-label-top">
                                        {t(`page.home_setting_home_banner_join_us_btn_txt_label`)} [
                                        {lang.toUpperCase()}]
                                      </Form.Label>
                                      <Form.Control
                                        className={
                                          touched.sections?.[index]?.[`join_us_btn_txt_${lang}`] &&
                                          errors.sections?.[index]?.[`join_us_btn_txt_${lang}`]
                                            ? 'form-field-error'
                                            : touched.sections?.[index]?.[`join_us_btn_txt_${lang}`]
                                            ? 'form-field-success'
                                            : ''
                                        }
                                        name={`sections.${index}.join_us_btn_txt_${lang}`}
                                        type="text"
                                        placeholder={t(
                                          `page.home_setting_home_banner_join_us_btn_txt_placeholder`
                                        )}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.sections[index][`join_us_btn_txt_${lang}`] || ''
                                        }
                                      />

                                      {/* Display error for each language */}
                                      <div className="form-field-error-text">
                                        {touched.sections?.[index]?.[`join_us_btn_txt_${lang}`] &&
                                          errors.sections?.[index]?.[`join_us_btn_txt_${lang}`] && (
                                            <div>
                                              {t(errors.sections[index][`join_us_btn_txt_${lang}`])}
                                            </div>
                                          )}
                                      </div>
                                    </Form.Group>
                                  ))}
                                  <Form.Group>
                                    <Form.Label className="field-label field-label-top">
                                      {t('page.home_setting_home_banner_join_us_btn_link_label')}
                                    </Form.Label>
                                    <Form.Control
                                      className={
                                        touched.sections?.[index]?.[`join_us_link`] &&
                                        errors.sections?.[index]?.[`join_us_link`]
                                          ? 'form-field-error'
                                          : touched.sections?.[index]?.[`join_us_link`]
                                          ? 'form-field-success'
                                          : ''
                                      }
                                      type="text"
                                      name={`sections.${index}.join_us_link`}
                                      placeholder={t(
                                        'page.home_setting_home_banner_join_us_btn_link_placeholder'
                                      )}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue(
                                          `sections.${index}.join_us_link`,
                                          value.trimStart()
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={values.sections[index][`join_us_link`] || ''}
                                    />
                                    <div className="form-field-error-text">
                                      {touched.sections?.[index]?.join_us_link &&
                                      errors.sections?.[index]?.join_us_link ? (
                                        <div>{t(errors.sections?.[index]?.join_us_link)}</div>
                                      ) : null}
                                    </div>
                                  </Form.Group>
                                  {/* Remove section button */}
                                  {index > 0 && (
                                    <div className="m-2 d-flex justify-content-end">
                                      <Button
                                        variant="primary"
                                        onClick={() => {
                                          const index_current = index + 1;
                                          doDeleteSettingData({ index_current });
                                          arrayHelpers.remove(index);
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              <div>No sections available</div>
                            )}

                            {/* Add section button */}
                            <div className="m-2 d-flex justify-content-end">
                              <Button
                                className="btn btn-primary upload-button"
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({ title: '', section: '', image: '' })
                                }>
                                {t('page.add_section_button')}
                              </Button>
                            </div>
                          </>
                        )}
                      />
                    </Col>
                    <div className="primary-button">
                      <span className="link-center" onClick={() => handleCancel(dirty)}>
                        {t('page.general_setting_cancel_link')}
                      </span>
                      <TNButton
                        type="submit"
                        isDirtyForm={dirty && dirty !== undefined ? 1 : 0}
                        loading={isLoading}>
                        {t('page.general_setting_save_button')}
                      </TNButton>
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Card>
    </>
  );
};

HomeBannerSetting.propTypes = {
  t: PropTypes.func,
};

export { HomeBannerSetting };

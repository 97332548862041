import client from 'libs/HttpClient';

class ServiceCategoryService {
  static listServiceCategory({ params }) {
    return client.get(`service-category/list`, { params });
  }
  static addServiceCategory(request) {
    return client.post(`service-category/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewServiceCategory(params) {
    return client.get(`service-category/get`, { params });
  }
  static updateServiceCategory(request) {
    return client.put(`service-category/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteServiceCategory(params) {
    return client.delete(`service-category/delete`, { params });
  }
  static updateServiceCategoryStatus(request) {
    return client.put(`service-category/update-status`, request);
  }
  static viewServiceCategoryName(request) {
    return client.get(`service-category/get-service-category`, request);
  }
}
export { ServiceCategoryService };

import * as Yup from 'yup';
const languages = ['en', 'sp'];
let validationSchema = Yup.object({
  home_setting_footer_call_icon_text: Yup.string(
    'page.home_setting_footer_call_icon_text_required'
  ).required('page.home_setting_footer_call_icon_text_required'),

  home_setting_footer_mail_icon_text: Yup.string(
    'page.home_setting_footer_mail_icon_text_required'
  ).required('page.home_setting_footer_mail_icon_text_required'),

  ...languages.reduce((acc, lang) => {
    acc[`home_setting_footer_location_one_icon_${lang}_text`] = Yup.string().required(
      `page.home_setting_footer_location_one_icon_text_required`
    );
    acc[`home_setting_footer_location_two_icon_${lang}_text`] = Yup.string().required(
      `page.home_setting_footer_location_two_icon_text_required`
    );
    return acc;
  }, {}),

  home_setting_footer_first_icon_link: Yup.string(
    'page.home_setting_footer_first_icon_link_required'
  ).url('page.home_setting_footer_first_icon_link_invalid'),
  // .required('page.home_setting_footer_first_icon_link_required'),

  home_setting_footer_second_icon_link: Yup.string(
    'page.home_setting_footer_second_icon_link_required'
  ).url('page.home_setting_footer_second_icon_link_invalid'),
  // .required('page.home_setting_footer_second_icon_link_required'),

  home_setting_footer_third_icon_link: Yup.string(
    'page.home_setting_footer_third_icon_link_required'
  ).url('page.home_setting_footer_third_icon_link_invalid'),
  // .required('page.home_setting_footer_third_icon_link_required'),

  home_setting_footer_forth_icon_link: Yup.string(
    'page.home_setting_footer_forth_icon_link_required'
  ).url('page.home_setting_footer_forth_icon_link_invalid'),
  // .required('page.home_setting_footer_forth_icon_link_required'),

  home_setting_footer_fifth_icon_link: Yup.string(
    'page.home_setting_footer_fifth_icon_link_required'
  ).url('page.home_setting_footer_fifth_icon_link_invalid'),
  // .required('page.home_setting_footer_fifth_icon_link_required'),

  home_setting_footer_six_icon_link: Yup.string(
    'page.home_setting_footer_six_icon_link_required'
  ).url('page.home_setting_footer_six_icon_link_invalid'),
  // .required('page.home_setting_footer_six_icon_link_required'),

  home_setting_footer_logo_image: Yup.mixed(
    'page.home_setting_footer_logo_image_required'
  ).required('page.home_setting_footer_logo_image_required'),
});

export default validationSchema;

import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { ServiceCategoryService } from 'api';

const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListServiceCategory = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['service-category-list', params],
    () => ServiceCategoryService.listServiceCategory({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddServiceCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceCategoryService.addServiceCategory, {
    onSuccess,
    onError,
  });
};

const useViewServiceCategory = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'service-category-view',
    () => ServiceCategoryService.viewServiceCategory({ _id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useUpdateServiceCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceCategoryService.updateServiceCategory, {
    onSuccess,
    onError,
  });
};

const useUpdateServiceCategoryStatus = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceCategoryService.updateServiceCategoryStatus, {
    onSuccess,
    onError,
  });
};

const useDeleteServiceCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(ServiceCategoryService.deleteServiceCategory, {
    onSuccess,
    onError,
  });
};

const useListServiceCategoryName = (onSuccess, onError = onDefaultError) => {
  return useQuery('service-category-list-name', ServiceCategoryService.viewServiceCategoryName, {
    onSuccess,
    onError,
  });
};

export {
  useDeleteServiceCategory,
  useUpdateServiceCategoryStatus,
  useUpdateServiceCategory,
  useViewServiceCategory,
  useAddServiceCategory,
  useListServiceCategory,
  useListServiceCategoryName,
};

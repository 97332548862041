import client from 'libs/HttpClient';

class ReviewService {
  static listReview({ params }) {
    return client.get(`review/list`, { params });
  }
  static addReview(request) {
    return client.post(`review/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewReview(params) {
    return client.get(`review/get`, {
      params,
    });
  }
  static statusChangeReview(request) {
    return client.put(`review/status_change`, request);
  }
  static updateReview(request) {
    console.log('here sending', request);
    return client.post(`review/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteReview(params) {
    return client.delete(`review/delete`, { params });
  }
}
export { ReviewService };

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { useStoreSettingData, useGetHomeSettingData } from 'hooks';
import validationSchema from './HomeWhyChooseUsSettingValidation';
import { addSetting } from 'store/features/settingSlice';
import { TNButton } from 'common/components/TNButton';
import { confirmAlert } from 'react-confirm-alert';
import { AlertCommon } from 'common/components/AlertCommon';
import 'assets/scss/page/_generalsetting.scss';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
const languages = ['en', 'sp'];

const HomeWhyChooseUsSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let Image1 = useRef();
  let Image2 = useRef();
  let Image3 = useRef();
  let Image4 = useRef();
  const [homeSettingData, setHomeSettingData] = useState(null);
  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);
  useGetHomeSettingData(
    {
      tag: 'home_setting_why_choose_us',
    },
    ({ data: setting }) => {
      const dataStore = {
        home_setting_why_choose_us_one_image: setting.home_setting_why_choose_us_one_image,
        home_setting_why_choose_us_two_image: setting.home_setting_why_choose_us_two_image,
        home_setting_why_choose_us_three_image: setting.home_setting_why_choose_us_three_image,
        home_setting_why_choose_us_four_image: setting.home_setting_why_choose_us_four_image,
      };
      languages.forEach((lang) => {
        dataStore[`home_setting_why_choose_us_one_${lang}_title`] =
          setting.home_setting_why_choose_us_one_title?.[lang] || '';
        dataStore[`home_setting_why_choose_us_one_${lang}_description`] =
          setting.home_setting_why_choose_us_one_description?.[lang] || '';

        dataStore[`home_setting_why_choose_us_two_${lang}_title`] =
          setting.home_setting_why_choose_us_two_title?.[lang] || '';
        dataStore[`home_setting_why_choose_us_two_${lang}_description`] =
          setting.home_setting_why_choose_us_two_description?.[lang] || '';

        dataStore[`home_setting_why_choose_us_three_${lang}_title`] =
          setting.home_setting_why_choose_us_three_title?.[lang] || '';
        dataStore[`home_setting_why_choose_us_three_${lang}_description`] =
          setting.home_setting_why_choose_us_three_description?.[lang] || '';

        dataStore[`home_setting_why_choose_us_four_${lang}_title`] =
          setting.home_setting_why_choose_us_four_title?.[lang] || '';
        dataStore[`home_setting_why_choose_us_four_${lang}_description`] =
          setting.home_setting_why_choose_us_four_description?.[lang] || '';
      });
      setHomeSettingData(dataStore);
      formik.setValues(dataStore);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  });
  /**
   * !This block is for image preview
   */
  const imagePreviewFromik = (file) => {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  };
  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };
  const sections = ['one', 'two', 'three', 'four'];
  const initialValues = sections.reduce((acc, section) => {
    languages.forEach((lang) => {
      acc[`home_setting_why_choose_us_${section}_${lang}_title`] =
        homeSettingData?.[`home_setting_why_choose_us_${section}_${lang}_title`] || '';
      acc[`home_setting_why_choose_us_${section}_${lang}_description`] =
        homeSettingData?.[`home_setting_why_choose_us_${section}_${lang}_description`] || '';
    });

    acc[`home_setting_why_choose_us_${section}_image`] =
      homeSettingData?.[`home_setting_why_choose_us_${section}_image`] || '';

    return acc;
  }, {});

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log('here submit');

      const transformedValues = {
        home_setting_why_choose_us_one_title: {},
        home_setting_why_choose_us_one_description: {},
        home_setting_why_choose_us_two_title: {},
        home_setting_why_choose_us_two_description: {},
        home_setting_why_choose_us_three_title: {},
        home_setting_why_choose_us_three_description: {},
        home_setting_why_choose_us_four_title: {},
        home_setting_why_choose_us_four_description: {},
      };

      // Loop over languages and dynamically build the transformedValues object
      languages.forEach((lang) => {
        ['one', 'two', 'three', 'four'].forEach((section) => {
          // Initialize the nested objects for each language and section
          transformedValues[`home_setting_why_choose_us_${section}_title`][lang] =
            values[`home_setting_why_choose_us_${section}_${lang}_title`] || '';
          transformedValues[`home_setting_why_choose_us_${section}_description`][lang] =
            values[`home_setting_why_choose_us_${section}_${lang}_description`] || '';
        });
      });

      console.log('Transformed Values:', transformedValues);

      const updatedValues = {
        ...transformedValues,
        home_setting_why_choose_us_one_image:
          values.home_setting_why_choose_us_one_image instanceof File
            ? values.home_setting_why_choose_us_one_image
            : undefined,
        home_setting_why_choose_us_two_image:
          values.home_setting_why_choose_us_two_image instanceof File
            ? values.home_setting_why_choose_us_two_image
            : undefined,
        home_setting_why_choose_us_three_image:
          values.home_setting_why_choose_us_three_image instanceof File
            ? values.home_setting_why_choose_us_three_image
            : undefined,
        home_setting_why_choose_us_four_image:
          values.home_setting_why_choose_us_four_image instanceof File
            ? values.home_setting_why_choose_us_four_image
            : undefined,
        home_setting_why_choose_us_store: true,
      };

      // Remove fields that are not file objects (those set to undefined)
      Object.keys(updatedValues).forEach((key) => {
        if (updatedValues[key] === undefined) {
          delete updatedValues[key];
        }
      });
      console.log('here values ', updatedValues);
      doStoreSettingData(updatedValues);
    },
  });

  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/setting/home/home-banner">
            {t('page.general_setting_tab_home_banner_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-motorcycle-lineup">
            {t('page.general_setting_tab_home_motorcycle_lineup_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-why-choose-us">
            {t('page.general_setting_tab_home_why_choose_us_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-footer">
            {t('page.general_setting_tab_home_footer_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-seo">
            {t('page.general_setting_tab_home_seo_info_section')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <div className="section-group mt-3">
                  <Col lg={12}>
                    <Form.Group className="change-align">
                      <Form.Label className="field-label field-label-top">
                        {t('page.home_setting_why_choose_us_one_image')}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        hidden
                        ref={Image1}
                        name="home_setting_why_choose_us_one_image"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          const allowedFileTypes = [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'image/svg+xml',
                          ];
                          if (file && allowedFileTypes.includes(file.type)) {
                            formik.setFieldValue(
                              'home_setting_why_choose_us_one_image',
                              event.currentTarget.files[0]
                            );
                          } else {
                            toast.error(t('page.invalid_upload_format_of_image'));
                            event.target.value = '';
                          }
                        }}
                      />
                      <Button
                        type="button"
                        className="upload-button"
                        onClick={() => Image1.current?.click()}>
                        {t('page.file_upload')}
                      </Button>
                      <div className="preview-image change-align">
                        {formik.values.home_setting_why_choose_us_one_image && (
                          <div>
                            <img
                              src={imagePreviewFromik(
                                formik.values.home_setting_why_choose_us_one_image
                              )}
                              width="100px"
                              alt="profile_img"
                            />
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                formik.setFieldValue('home_setting_why_choose_us_one_image', '');
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_why_choose_us_one_image &&
                      formik.errors.home_setting_why_choose_us_one_image ? (
                        <div>{t(formik.errors.home_setting_why_choose_us_one_image)}</div>
                      ) : null}
                    </div>
                    {languages.map((lang) => (
                      <>
                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_one_title_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[`home_setting_why_choose_us_one_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_one_${lang}_title`]
                                ? 'form-field-error'
                                : formik.touched[`home_setting_why_choose_us_one_${lang}_title`]
                                ? 'form-field-success'
                                : ''
                            }
                            type="text"
                            name={`home_setting_why_choose_us_one_${lang}_title`} // Dynamically setting name based on lang
                            placeholder={t('page.home_setting_why_choose_us_one_title_placeholder')}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_one_${lang}_title`, // Dynamically setting field name based on lang
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values[`home_setting_why_choose_us_one_${lang}_title`]} // Dynamically setting value based on lang
                          />
                          <div className="form-field-error-text">
                            {formik.touched[`home_setting_why_choose_us_one_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_one_${lang}_title`] && (
                                <div>
                                  {t(formik.errors[`home_setting_why_choose_us_one_${lang}_title`])}
                                </div>
                              )}
                          </div>
                        </Form.Group>
                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_one_description_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[
                                `home_setting_why_choose_us_one_${lang}_description`
                              ] &&
                              formik.errors[`home_setting_why_choose_us_one_${lang}_description`]
                                ? 'form-field-error'
                                : formik.touched[
                                    `home_setting_why_choose_us_one_${lang}_description`
                                  ]
                                ? 'form-field-success'
                                : ''
                            }
                            as="textarea"
                            rows={3}
                            type="text"
                            name={`home_setting_why_choose_us_one_${lang}_description`} // Dynamically setting name based on lang
                            placeholder={t(
                              'page.home_setting_why_choose_us_one_description_placeholder'
                            )}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_one_${lang}_description`, // Dynamically setting field name based on lang
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values[`home_setting_why_choose_us_one_${lang}_description`]
                            } // Dynamically setting value based on lang
                          />
                          <div className="form-field-error-text">
                            {formik.touched[`home_setting_why_choose_us_one_${lang}_description`] &&
                              formik.errors[
                                `home_setting_why_choose_us_one_${lang}_description`
                              ] && (
                                <div>
                                  {t(
                                    formik.errors[
                                      `home_setting_why_choose_us_one_${lang}_description`
                                    ]
                                  )}
                                </div>
                              )}
                          </div>
                        </Form.Group>
                      </>
                    ))}
                  </Col>
                </div>
                <div className="section-group mt-3">
                  <Col lg={12}>
                    <Form.Group className="change-align">
                      <Form.Label className="field-label field-label-top">
                        {t('page.home_setting_why_choose_us_two_image')}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        hidden
                        ref={Image2}
                        name="home_setting_why_choose_us_two_image"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          const allowedFileTypes = [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'image/svg+xml',
                          ];
                          if (file && allowedFileTypes.includes(file.type)) {
                            formik.setFieldValue(
                              'home_setting_why_choose_us_two_image',
                              event.currentTarget.files[0]
                            );
                          } else {
                            toast.error(t('page.invalid_upload_format_of_image'));
                            event.target.value = '';
                          }
                        }}
                      />
                      <Button
                        type="button"
                        className="upload-button"
                        onClick={() => Image2.current?.click()}>
                        {t('page.file_upload')}
                      </Button>
                      <div className="preview-image change-align">
                        {formik.values.home_setting_why_choose_us_two_image && (
                          <div>
                            <img
                              src={imagePreviewFromik(
                                formik.values.home_setting_why_choose_us_two_image
                              )}
                              width="100px"
                              alt="profile_img"
                            />
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                formik.setFieldValue('home_setting_why_choose_us_two_image', '');
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_why_choose_us_two_image &&
                      formik.errors.home_setting_why_choose_us_two_image ? (
                        <div>{t(formik.errors.home_setting_why_choose_us_two_image)}</div>
                      ) : null}
                    </div>
                    {languages.map((lang) => (
                      <>
                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_two_title_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[`home_setting_why_choose_us_two_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_two_${lang}_title`]
                                ? 'form-field-error'
                                : formik.touched[`home_setting_why_choose_us_two_${lang}_title`]
                                ? 'form-field-success'
                                : ''
                            }
                            type="text"
                            name={`home_setting_why_choose_us_two_${lang}_title`} // Dynamic name based on lang
                            placeholder={t('page.home_setting_why_choose_us_two_title_placeholder')}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_two_${lang}_title`, // Dynamic field name
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values[`home_setting_why_choose_us_two_${lang}_title`]} // Dynamic field value
                          />
                          <div className="form-field-error-text">
                            {formik.touched[`home_setting_why_choose_us_two_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_two_${lang}_title`] && (
                                <div>
                                  {t(formik.errors[`home_setting_why_choose_us_two_${lang}_title`])}
                                </div>
                              )}
                          </div>
                        </Form.Group>

                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_two_description_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[
                                `home_setting_why_choose_us_two_${lang}_description`
                              ] &&
                              formik.errors[`home_setting_why_choose_us_two_${lang}_description`]
                                ? 'form-field-error'
                                : formik.touched[
                                    `home_setting_why_choose_us_two_${lang}_description`
                                  ]
                                ? 'form-field-success'
                                : ''
                            }
                            as="textarea"
                            rows={3}
                            type="text"
                            name={`home_setting_why_choose_us_two_${lang}_description`} // Dynamic name based on lang
                            placeholder={t(
                              'page.home_setting_why_choose_us_two_description_placeholder'
                            )}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_two_${lang}_description`, // Dynamic field name
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values[`home_setting_why_choose_us_two_${lang}_description`]
                            } // Dynamic field value
                          />
                          <div className="form-field-error-text">
                            {formik.touched[`home_setting_why_choose_us_two_${lang}_description`] &&
                              formik.errors[
                                `home_setting_why_choose_us_two_${lang}_description`
                              ] && (
                                <div>
                                  {t(
                                    formik.errors[
                                      `home_setting_why_choose_us_two_${lang}_description`
                                    ]
                                  )}
                                </div>
                              )}
                          </div>
                        </Form.Group>
                      </>
                    ))}
                  </Col>
                </div>
                <div className="section-group mt-3">
                  <Col lg={12}>
                    <Form.Group className="change-align">
                      <Form.Label className="field-label field-label-top">
                        {t('page.home_setting_why_choose_us_three_image')}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        hidden
                        ref={Image3}
                        name="home_setting_why_choose_us_three_image"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          const allowedFileTypes = [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'image/svg+xml',
                          ];
                          if (file && allowedFileTypes.includes(file.type)) {
                            formik.setFieldValue(
                              'home_setting_why_choose_us_three_image',
                              event.currentTarget.files[0]
                            );
                          } else {
                            toast.error(t('page.invalid_upload_format_of_image'));
                            event.target.value = '';
                          }
                        }}
                      />
                      <Button
                        type="button"
                        className="upload-button"
                        onClick={() => Image3.current?.click()}>
                        {t('page.file_upload')}
                      </Button>
                      <div className="preview-image change-align">
                        {formik.values.home_setting_why_choose_us_three_image && (
                          <div>
                            <img
                              src={imagePreviewFromik(
                                formik.values.home_setting_why_choose_us_three_image
                              )}
                              width="100px"
                              alt="profile_img"
                            />
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                formik.setFieldValue('home_setting_why_choose_us_three_image', '');
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_why_choose_us_three_image &&
                      formik.errors.home_setting_why_choose_us_three_image ? (
                        <div>{t(formik.errors.home_setting_why_choose_us_three_image)}</div>
                      ) : null}
                    </div>
                    {languages.map((lang) => (
                      <>
                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_three_title_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[`home_setting_why_choose_us_three_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_three_${lang}_title`]
                                ? 'form-field-error'
                                : formik.touched[`home_setting_why_choose_us_three_${lang}_title`]
                                ? 'form-field-success'
                                : ''
                            }
                            type="text"
                            name={`home_setting_why_choose_us_three_${lang}_title`} // Dynamic name based on lang
                            placeholder={t(
                              'page.home_setting_why_choose_us_three_title_placeholder'
                            )}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_three_${lang}_title`, // Dynamic field name
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values[`home_setting_why_choose_us_three_${lang}_title`]} // Dynamic field value
                          />
                          <div className="form-field-error-text">
                            {formik.touched[`home_setting_why_choose_us_three_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_three_${lang}_title`] && (
                                <div>
                                  {t(
                                    formik.errors[`home_setting_why_choose_us_three_${lang}_title`]
                                  )}
                                </div>
                              )}
                          </div>
                        </Form.Group>

                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_three_description_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[
                                `home_setting_why_choose_us_three_${lang}_description`
                              ] &&
                              formik.errors[`home_setting_why_choose_us_three_${lang}_description`]
                                ? 'form-field-error'
                                : formik.touched[
                                    `home_setting_why_choose_us_three_${lang}_description`
                                  ]
                                ? 'form-field-success'
                                : ''
                            }
                            as="textarea"
                            rows={3}
                            type="text"
                            name={`home_setting_why_choose_us_three_${lang}_description`} // Dynamic name based on lang
                            placeholder={t(
                              'page.home_setting_why_choose_us_three_description_placeholder'
                            )}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_three_${lang}_description`, // Dynamic field name
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values[`home_setting_why_choose_us_three_${lang}_description`]
                            } // Dynamic field value
                          />
                          <div className="form-field-error-text">
                            {formik.touched[
                              `home_setting_why_choose_us_three_${lang}_description`
                            ] &&
                              formik.errors[
                                `home_setting_why_choose_us_three_${lang}_description`
                              ] && (
                                <div>
                                  {t(
                                    formik.errors[
                                      `home_setting_why_choose_us_three_${lang}_description`
                                    ]
                                  )}
                                </div>
                              )}
                          </div>
                        </Form.Group>
                      </>
                    ))}
                  </Col>
                </div>
                <div className="section-group mt-3">
                  <Col lg={12}>
                    <Form.Group className="change-align">
                      <Form.Label className="field-label field-label-top">
                        {t('page.home_setting_why_choose_us_four_image')}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        hidden
                        ref={Image4}
                        name="home_setting_why_choose_us_four_image"
                        accept=".jpg, .jpeg, .png, .svg"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          const allowedFileTypes = [
                            'image/jpeg',
                            'image/jpg',
                            'image/png',
                            'image/svg+xml',
                          ];
                          if (file && allowedFileTypes.includes(file.type)) {
                            formik.setFieldValue(
                              'home_setting_why_choose_us_four_image',
                              event.currentTarget.files[0]
                            );
                          } else {
                            toast.error(t('page.invalid_upload_format_of_image'));
                            event.target.value = '';
                          }
                        }}
                      />
                      <Button
                        type="button"
                        className="upload-button"
                        onClick={() => Image4.current?.click()}>
                        {t('page.file_upload')}
                      </Button>
                      <div className="preview-image change-align">
                        {formik.values.home_setting_why_choose_us_four_image && (
                          <div>
                            <img
                              src={imagePreviewFromik(
                                formik.values.home_setting_why_choose_us_four_image
                              )}
                              width="100px"
                              alt="profile_img"
                            />
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                formik.setFieldValue('home_setting_why_choose_us_four_image', '');
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Form.Group>
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_why_choose_us_four_image &&
                      formik.errors.home_setting_why_choose_us_four_image ? (
                        <div>{t(formik.errors.home_setting_why_choose_us_four_image)}</div>
                      ) : null}
                    </div>
                    {languages.map((lang) => (
                      <>
                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_four_title_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[`home_setting_why_choose_us_four_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_four_${lang}_title`]
                                ? 'form-field-error'
                                : formik.touched[`home_setting_why_choose_us_four_${lang}_title`]
                                ? 'form-field-success'
                                : ''
                            }
                            type="text"
                            name={`home_setting_why_choose_us_four_${lang}_title`} // Dynamic name based on lang
                            placeholder={t(
                              'page.home_setting_why_choose_us_four_title_placeholder'
                            )}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_four_${lang}_title`, // Dynamic field name
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values[`home_setting_why_choose_us_four_${lang}_title`]} // Dynamic field value
                          />
                          <div className="form-field-error-text">
                            {formik.touched[`home_setting_why_choose_us_four_${lang}_title`] &&
                              formik.errors[`home_setting_why_choose_us_four_${lang}_title`] && (
                                <div>
                                  {t(
                                    formik.errors[`home_setting_why_choose_us_four_${lang}_title`]
                                  )}
                                </div>
                              )}
                          </div>
                        </Form.Group>

                        <Form.Group key={lang}>
                          <Form.Label className="field-label field-label-top">
                            {t('page.home_setting_why_choose_us_four_description_label')} [
                            {lang.toUpperCase()}]
                          </Form.Label>
                          <Form.Control
                            className={
                              formik.touched[
                                `home_setting_why_choose_us_four_${lang}_description`
                              ] &&
                              formik.errors[`home_setting_why_choose_us_four_${lang}_description`]
                                ? 'form-field-error'
                                : formik.touched[
                                    `home_setting_why_choose_us_four_${lang}_description`
                                  ]
                                ? 'form-field-success'
                                : ''
                            }
                            as="textarea"
                            rows={3}
                            type="text"
                            name={`home_setting_why_choose_us_four_${lang}_description`} // Dynamic name based on lang
                            placeholder={t(
                              'page.home_setting_why_choose_us_four_description_placeholder'
                            )}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `home_setting_why_choose_us_four_${lang}_description`, // Dynamic field name
                                value.trimStart()
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values[`home_setting_why_choose_us_four_${lang}_description`]
                            } // Dynamic field value
                          />
                          <div className="form-field-error-text">
                            {formik.touched[
                              `home_setting_why_choose_us_four_${lang}_description`
                            ] &&
                              formik.errors[
                                `home_setting_why_choose_us_four_${lang}_description`
                              ] && (
                                <div>
                                  {t(
                                    formik.errors[
                                      `home_setting_why_choose_us_four_${lang}_description`
                                    ]
                                  )}
                                </div>
                              )}
                          </div>
                        </Form.Group>
                      </>
                    ))}
                  </Col>
                </div>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

HomeWhyChooseUsSetting.propTypes = {
  t: PropTypes.func,
};

export { HomeWhyChooseUsSetting };

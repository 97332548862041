import client from 'libs/HttpClient';

class ServiceService {
  static listService({ params }) {
    return client.get(`service/list`, { params });
  }
  static addService(request) {
    return client.post(`service/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewService(params) {
    return client.get(`service/get`, { params });
  }
  static updateService(request) {
    return client.post(`service/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteService(params) {
    return client.delete(`service/delete`, { params });
  }
  static statusChangeService(request) {
    return client.put(`service/status_change`, request);
  }
}
export { ServiceService };

import client from 'libs/HttpClient';

class BlogService {
  static listBlog({ params }) {
    return client.get(`blog/list`, { params });
  }
  static addBlog(request) {
    return client.post(`blog/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewBlog(params) {
    return client.get(`blog/get`, {
      params,
    });
  }
  static statusChangeBlog(request) {
    return client.put(`blog/status_change`, request);
  }
  static updateBlog(request) {
    console.log('here sending', request);
    return client.post(`blog/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteBlog(params) {
    return client.delete(`blog/delete`, { params });
  }
  static getServiceList() {
    return client.get(`blog/get_blog_category`);
  }
}
export { BlogService };

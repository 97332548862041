import React from 'react';
import { NavLink } from 'react-router-dom';

import { SETTING, GENERAL, SETTING_HOME, SETTING_ABOUT, SETTING_CONTACT_US } from 'config';
import { routes } from 'config';

const SidebarSettingLinks = (props) => {
  const { t } = props;
  const settingClass =
    location.pathname === `${SETTING}/general` ||
    location.pathname === `${SETTING}/email_and_notification`
      ? 'active'
      : '';
  const settingHomeClass = location.pathname.match(/^\/setting\/home/) ? 'active' : '';
  const settingAboutClass = location.pathname.match(/^\/setting\/about/) ? 'active' : '';
  const settingContactClass = location.pathname.match(/^\/setting\/contact/) ? 'active' : '';

  return (
    <>
      <ul className={`submenu collapse ${props.setting ? 'show' : ''}`}>
        <li>
          <NavLink className={settingClass} to={SETTING + GENERAL} onClick={props.toggleClass}>
            {t('page.sidebar_generals')}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={settingHomeClass}
            to={SETTING_HOME + '/home-banner'}
            onClick={props.toggleClass}>
            {t('page.sidebar_home_settings')}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={settingAboutClass}
            to={SETTING_ABOUT + '/about-banner'}
            onClick={props.toggleClass}>
            {t('page.sidebar_about_settings')}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={settingContactClass}
            to={SETTING_CONTACT_US + '/general'}
            onClick={props.toggleClass}>
            {t('page.sidebar_contact_settings')}
          </NavLink>
        </li>
        <li>
          <NavLink to={routes.SETTING_RENTAL} onClick={props.toggleClass}>
            {t('page.sidebar_motorcycle_rental_settings')}
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export { SidebarSettingLinks };

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import { routes } from 'config';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSettingData } from 'hooks';
import { addSetting } from 'store';
import { settingData } from 'store';

const ListExtraServicePage = React.lazy(() => import('./ListExtraServicePage'));
const AddExtraServicePage = React.lazy(() => import('./AddExtraServicePage'));
const EditExtraServicePage = React.lazy(() => import('./EditExtraServicePage'));

const ExtraServiceRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>
          {getSettingData.home_page_general_seo_title || 'Triumph Admin'} | Extra Service
        </title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="description" content={getSettingData.home_page_general_seo_description || ''} />
        <meta
          name="og:description"
          content={getSettingData.home_page_general_seo_description || ''}
        />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo || ''}`} />
      </Helmet>
      <Routes>
        <Route path={routes.LIST} element={<ListExtraServicePage t={t} />} />
        <Route exact path={routes.ADD} element={<AddExtraServicePage t={t} />} />
        <Route exact path={routes.EDIT + ':id'} element={<EditExtraServicePage t={t} />} />
      </Routes>
    </>
  );
};
ExtraServiceRoutes.propTypes = {
  t: PropTypes.func,
};
export default ExtraServiceRoutes;

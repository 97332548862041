import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'config';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { settingData } from 'store';
import { addSetting } from 'store';
import { useGetSettingData } from 'hooks';

/**
 * Motorcycle and Rental setting
 */
const RentalSettingPage = React.lazy(() => import('./RentalSettingPage'));
const MotorcycleListSettingPage = React.lazy(() => import('./MotorcycleListSettingPage'));

const MotorcycleRentalRoutes = ({ t }) => {
  const dispatch = useDispatch();
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>
          {getSettingData.home_page_general_seo_title || 'Triumph Admin'} | Motorcycle Settings
        </title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="title" content={getSettingData.home_page_general_seo_title || ''} />
        <meta name="description" content={getSettingData.home_page_general_seo_description || ''} />
        <meta
          name="og:description"
          content={getSettingData.home_page_general_seo_description || ''}
        />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo || ''}`} />
      </Helmet>

      <Routes>
        <Route index exact path={routes.RENTAL_SETTING} element={<RentalSettingPage t={t} />} />
        <Route
          index
          exact
          path={routes.MOTORCYCLE_DETAIL}
          element={<MotorcycleListSettingPage t={t} />}
        />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
MotorcycleRentalRoutes.propTypes = {
  t: PropTypes.func,
};
export { MotorcycleRentalRoutes };

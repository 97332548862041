import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { CKEditor } from 'ckeditor4-react';
import { useGetAboutSettingData, useStoreSettingData } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TNButton } from 'common/components/TNButton';
import { AlertCommon } from 'common/components/AlertCommon';
import validationSchema from './AboutBannerSettingValidation';
import { AboutTab } from './AboutTab';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { confirmAlert } from 'react-confirm-alert';
const languages = ['en', 'sp'];

const AboutBannerSetting = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let BannerImage = useRef();
  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);

  const formik = useFormik({
    initialValues: {
      // about_setting_about_banner_en_title: '',
      // about_setting_about_banner_sp_title: '',
      // about_setting_about_banner_en_short_description: '',
      // about_setting_about_banner_sp_short_description: '',
      // about_setting_about_banner_en_description: '',
      // about_setting_about_banner_sp_description: '',

      ...languages.reduce((acc, lang) => {
        acc[`about_setting_about_banner_${lang}_title`] = '';
        acc[`about_setting_about_banner_${lang}_short_description`] = '';
        acc[`about_setting_about_banner_${lang}_description`] = '';
        return acc;
      }, {}),
      about_setting_about_banner_image: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      // const transformedValues = {
      //   about_setting_about_banner_title: {
      //     ['en']: values.about_setting_about_banner_en_title,
      //     ['sp']: values.about_setting_about_banner_sp_title,
      //   },
      //   about_setting_about_banner_short_description: {
      //     ['en']: values.about_setting_about_banner_en_short_description,
      //     ['sp']: values.about_setting_about_banner_sp_short_description,
      //   },
      //   about_setting_about_banner_description: {
      //     ['en']: values.about_setting_about_banner_en_description,
      //     ['sp']: values.about_setting_about_banner_sp_description,
      //   },
      //   about_setting_about_banner_image:
      //     values.about_setting_about_banner_image instanceof File
      //       ? values.about_setting_about_banner_image
      //       : undefined,
      // };
      // const updatedValues = {
      //   ...values,
      //   about_setting_about_banner_image:
      //     values.about_setting_about_banner_image instanceof File
      //       ? values.about_setting_about_banner_image
      //       : undefined,
      // };
      // // Remove fields that are not file objects (those set to undefined)
      // Object.keys(updatedValues).forEach((key) => {
      //   if (updatedValues[key] === undefined) {
      //     delete updatedValues[key];
      //   }
      // });
      // doStoreSettingData(transformedValues);

      const transformedValues = {
        about_setting_about_banner_title: {},
        about_setting_about_banner_short_description: {},
        about_setting_about_banner_description: {},
        about_setting_about_banner_image:
          values.about_setting_about_banner_image instanceof File
            ? values.about_setting_about_banner_image
            : undefined,
      };

      languages.forEach((lang) => {
        transformedValues.about_setting_about_banner_title[lang] =
          values[`about_setting_about_banner_${lang}_title`] || '';
        transformedValues.about_setting_about_banner_short_description[lang] =
          values[`about_setting_about_banner_${lang}_short_description`] || '';
        transformedValues.about_setting_about_banner_description[lang] =
          values[`about_setting_about_banner_${lang}_description`] || '';
      });

      doStoreSettingData(transformedValues);
    },
  });

  const { refetch, isLoading: getDataLoading } = useGetAboutSettingData(
    {
      tag: 'about_setting_about_banner',
    },
    ({ data: setting }) => {
      // formik.values.about_setting_about_banner_en_title =
      //   setting.about_setting_about_banner_title?.['en'] || '';
      // formik.values.about_setting_about_banner_sp_title =
      //   setting.about_setting_about_banner_title?.['sp'] || '';
      // formik.values.about_setting_about_banner_en_short_description =
      //   setting.about_setting_about_banner_short_description?.['en'] || '';
      // formik.values.about_setting_about_banner_sp_short_description =
      //   setting.about_setting_about_banner_short_description?.['sp'] || '';
      // formik.values.about_setting_about_banner_en_description =
      //   setting.about_setting_about_banner_description?.['en'] || '';
      // formik.values.about_setting_about_banner_sp_description =
      //   setting.about_setting_about_banner_description?.['sp'] || '';

      languages.forEach((lang) => {
        formik.values[`about_setting_about_banner_${lang}_title`] =
          setting.about_setting_about_banner_title?.[lang] || '';
        formik.values[`about_setting_about_banner_${lang}_short_description`] =
          setting.about_setting_about_banner_short_description?.[lang] || '';
        formik.values[`about_setting_about_banner_${lang}_description`] =
          setting.about_setting_about_banner_description?.[lang] || '';
      });
      formik.values.about_setting_about_banner_image = setting.about_setting_about_banner_image;

      // formik.values.about_setting_about_banner_title = setting.about_setting_about_banner_title;
      // formik.values.about_setting_about_banner_short_description =
      //   setting.about_setting_about_banner_short_description;
      // formik.values.about_setting_about_banner_description =
      //   setting.about_setting_about_banner_description;
      // formik.values.about_setting_about_banner_image = setting.about_setting_about_banner_image;
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  /**
   * !This block is for image preview
   */
  const imagePreviewFormik = (file) => {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  };

  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    // refetch();
    setTimeout(function () {
      window.location.reload();
    }, 500);
  });

  // const [editor, setEditor] = useState(null);
  // const [editorTwo, setEditorTwo] = useState(null);

  // if (editor && !editor.getData()) {
  //   editor.setData(formik.values.about_setting_about_banner_en_description);
  // }

  // if (editorTwo && !editorTwo.getData()) {
  //   editorTwo.setData(formik.values.about_setting_about_banner_sp_description);
  // }

  const handleEditorChange = (lang, value) => {
    formik.setFieldValue(
      `about_setting_about_banner_${lang}_description`,
      value.editor.getData().replace(/<p>(&nbsp;|\s)*<\/p>/g, '')
    );
  };

  return (
    <>
      <AboutTab t={t} />
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_banner_title_label')} [{lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`about_setting_about_banner_${lang}_title`] &&
                            formik.errors[`about_setting_about_banner_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[`about_setting_about_banner_${lang}_title`] &&
                                !formik.errors[`about_setting_about_banner_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`about_setting_about_banner_${lang}_title`}
                          placeholder={t('page.about_setting_about_banner_title_placeholder')}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_banner_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`about_setting_about_banner_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`about_setting_about_banner_${lang}_title`] &&
                          formik.errors[`about_setting_about_banner_${lang}_title`] ? (
                            <div>
                              {t(formik.errors[`about_setting_about_banner_${lang}_title`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_banner_short_description_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched[
                              `about_setting_about_banner_${lang}_short_description`
                            ] &&
                            formik.errors[`about_setting_about_banner_${lang}_short_description`]
                              ? 'form-field-error'
                              : formik.touched[
                                  `about_setting_about_banner_${lang}_short_description`
                                ] &&
                                !formik.errors[
                                  `about_setting_about_banner_${lang}_short_description`
                                ]
                              ? 'form-field-success'
                              : ''
                          }
                          as="textarea"
                          rows={2}
                          type="text"
                          name={`about_setting_about_banner_${lang}_short_description`}
                          placeholder={t(
                            'page.about_setting_about_banner_short_description_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `about_setting_about_banner_${lang}_short_description`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values[`about_setting_about_banner_${lang}_short_description`]
                          }
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`about_setting_about_banner_${lang}_short_description`] &&
                          formik.errors[`about_setting_about_banner_${lang}_short_description`] ? (
                            <div>
                              {t(
                                formik.errors[
                                  `about_setting_about_banner_${lang}_short_description`
                                ]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.about_setting_about_banner_description_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        {!getDataLoading && (
                          <>
                            <CKEditor
                              // onLoaded={onBeforeLoad}
                              // onReady={({ editor }) => {
                              //   editor.setData(
                              //     formik.values.about_setting_about_banner_en_description
                              //   );
                              //   editor.updateElement();
                              // }}
                              onReady={({ editor }) => {
                                editor.setData(
                                  formik.values[`about_setting_about_banner_${lang}_description`] ||
                                    ''
                                );
                                editor.updateElement();
                              }}
                              onBeforeLoad={(CKEDITOR) => {
                                CKEDITOR.disableAutoInline = true;
                              }}
                              onInstanceReady={({ editor }) => {
                                editor.setData(
                                  formik.values[`about_setting_about_banner_${lang}_description`] ||
                                    ''
                                );
                                if (editor.mode === 'wysiwyg') {
                                  editor.insertHtml(
                                    formik.values[
                                      `about_setting_about_banner_${lang}_description`
                                    ] || ''
                                  );
                                } else {
                                  editor.setMode('wysiwyg', () => {
                                    editor.insertHtml(
                                      formik.values[
                                        `about_setting_about_banner_${lang}_description`
                                      ] || ''
                                    );
                                  });
                                }
                                editor.updateElement();
                              }}
                              // onInstanceReady={({ editor }) => {
                              //   editor.setData(
                              //     formik.values.about_setting_about_banner_en_description
                              //   );
                              //   if (editor.mode == 'wysiwyg') {
                              //     editor.insertHtml(
                              //       formik.values.about_setting_about_banner_en_description
                              //     );
                              //   } else {
                              //     editor.setMode('wysiwyg', function () {
                              //       editor.insertHtml(
                              //         formik.values.about_setting_about_banner_en_description
                              //       );
                              //     });
                              //   }
                              //   editor.updateElement();
                              // }}
                              onChange={(value) => handleEditorChange(lang, value)}
                              // onChange={(value) => {
                              //   formik.setFieldValue(
                              //     'about_setting_about_banner_en_description',
                              //     value.editor.getData().replace(/<p>(&nbsp;|\s)*<\/p>/g, '')
                              //   );
                              // }}
                              config={{
                                allowedContent: true,
                                fillEmptyBlocks: false,
                                forcePasteAsPlainText: true,
                                versionCheck: false,
                              }}
                              name={`about_setting_about_banner_${lang}_description`}
                              initData={
                                formik.values[`about_setting_about_banner_${lang}_description`] ||
                                ''
                              }
                              data={
                                formik.values[`about_setting_about_banner_${lang}_description`] ||
                                ''
                              }
                            />
                          </>
                        )}

                        <div className="form-field-error-text">
                          {/* {formik.touched.about_setting_about_banner_en_description &&
                            formik.errors.about_setting_about_banner_en_description && (
                              <div>
                                {t(formik.errors.about_setting_about_banner_en_description)}
                              </div>
                            )} */}
                          {formik.touched[`about_setting_about_banner_${lang}_description`] &&
                          formik.errors[`about_setting_about_banner_${lang}_description`] ? (
                            <div>
                              {t(formik.errors[`about_setting_about_banner_${lang}_description`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.about_setting_about_banner_image')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={BannerImage}
                      name="about_setting_about_banner_image"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        const allowedFileTypes = [
                          'image/jpeg',
                          'image/jpg',
                          'image/png',
                          'image/svg+xml',
                        ];
                        if (file && allowedFileTypes.includes(file.type)) {
                          formik.setFieldValue(
                            'about_setting_about_banner_image',
                            event.currentTarget.files[0]
                          );
                        } else {
                          toast.error(t('page.invalid_upload_format_of_image'));
                          event.target.value = '';
                        }
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => BannerImage.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.about_setting_about_banner_image && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.about_setting_about_banner_image)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('about_setting_about_banner_image', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.about_setting_about_banner_image &&
                    formik.errors.about_setting_about_banner_image ? (
                      <div>{t(formik.errors.about_setting_about_banner_image)}</div>
                    ) : null}
                  </div>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

AboutBannerSetting.propTypes = {
  t: PropTypes.func,
};

export { AboutBannerSetting };

import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { EmailTemplateService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
/**
 * Hooks for Email Templates
 */
const useListEmailTemplate = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['email-template-list', [params]],
    () => EmailTemplateService.listEmailTemplate(params),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useListEmailTemplateWithoutPagination = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'email-template-list',
    () => EmailTemplateService.listEmailTemplateWithoutPagination(),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useAddEmailTemplate = (onSuccess, onError = onDefaultError) => {
  return useMutation(EmailTemplateService.addEmailTemplate, {
    onSuccess,
    onError,
  });
};
const useViewEmailTemplate = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('email-template-view', () => EmailTemplateService.viewEmailTemplate({ _id }), {
    onSuccess,
    onError,
  });
};
const useUpdateEmailTemplate = (onSuccess, onError = onDefaultError) => {
  return useMutation(EmailTemplateService.updateEmailTemplate, {
    onSuccess,
    onError,
  });
};
const useGetEmailCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery('get-email-category-list', EmailTemplateService.getEmailCategoryList, {
    onSuccess,
    onError,
  });
};

export {
  useListEmailTemplate,
  useAddEmailTemplate,
  useViewEmailTemplate,
  useUpdateEmailTemplate,
  useGetEmailCategoryList,
  useListEmailTemplateWithoutPagination,
};

import { useMutation, useQuery } from 'react-query';

const { InsuranceService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListInsurance = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['insurance-list', params], () => InsuranceService.listInsurance({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddInsurance = (onSuccess, onError = onDefaultError) => {
  return useMutation(InsuranceService.addInsurance, {
    onSuccess,
    onError,
  });
};

const useViewInsurance = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('insurance-view', () => InsuranceService.viewInsurance({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateInsurance = (onSuccess, onError = onDefaultError) => {
  return useMutation(InsuranceService.updateInsurance, {
    onSuccess,
    onError,
  });
};

const useStatusChangeInsurance = (onSuccess, onError = onDefaultError) => {
  return useMutation(InsuranceService.statusChangeInsurance, {
    onSuccess,
    onError,
  });
};

const useDeleteInsurance = (onSuccess, onError = onDefaultError) => {
  return useMutation(InsuranceService.deleteInsurance, {
    onSuccess,
    onError,
  });
};

export {
  useListInsurance,
  useAddInsurance,
  useViewInsurance,
  useUpdateInsurance,
  useStatusChangeInsurance,
  useDeleteInsurance,
};

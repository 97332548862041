import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateFormatCommon } from 'helpers';
const TNDatePicker = (props) => {
  const dateFromRef = useRef();

  return (
    <div className="custom-datepicker">
      <DatePicker
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        {...props}
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        adjustDateOnChange
        className="form-control filter-column form-field date-picker-input"
        ref={dateFromRef}
        dateFormat={dateFormatCommon()}
        closeOnScroll={false}
        popperPlacement="bottom-end"
        popperModifiers={{
          flip: {
            behavior: ['bottom'], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
      />
      <svg
        width="20"
        height="20"
        onClick={() => dateFromRef.current.setOpen(true)}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="datepicker-calendar">
        <path
          d="M11.875 2.5H3.125C2.43464 2.5 1.875 3.05964 1.875 3.75V12.5C1.875 13.1904 2.43464 13.75 3.125 13.75H11.875C12.5654 13.75 13.125 13.1904 13.125 12.5V3.75C13.125 3.05964 12.5654 2.5 11.875 2.5Z"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#aaaaaa"></path>
        <path
          d="M10 1.25V3.75"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#aaaaaa"></path>
        <path
          d="M5 1.25V3.75"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#aaaaaa"></path>
        <path
          d="M1.875 6.25H13.125"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#aaaaaa"></path>
      </svg>
    </div>
  );
};
export default TNDatePicker;

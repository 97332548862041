import { useMutation, useQuery } from 'react-query';

const { BlogCategoryService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListBlogCategory = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['blog-category-list', params],
    () => BlogCategoryService.listBlogCategory({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.addBlogCategory, {
    onSuccess,
    onError,
  });
};

const useViewBlogCategory = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('blog-category-view', () => BlogCategoryService.viewBlogCategory({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.updateBlogCategory, {
    onSuccess,
    onError,
  });
};

const useStatusChangeBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.statusChangeBlogCategory, {
    onSuccess,
    onError,
  });
};

const useDeleteBlogCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(BlogCategoryService.deleteBlogCategory, {
    onSuccess,
    onError,
  });
};

export {
  useListBlogCategory,
  useAddBlogCategory,
  useViewBlogCategory,
  useUpdateBlogCategory,
  useStatusChangeBlogCategory,
  useDeleteBlogCategory,
};

import client from 'libs/HttpClient';

class OurTeamService {
  static listOurTeam({ params }) {
    return client.get(`our-team/list`, { params });
  }
  static addOurTeam(request) {
    return client.post(`our-team/add`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static viewOurTeam(params) {
    return client.get(`our-team/get`, {
      params,
    });
  }
  static updateOurTeam(request) {
    return client.post(`our-team/edit`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static deleteOurTeam(params) {
    return client.delete(`our-team/delete`, { params });
  }
}
export { OurTeamService };

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import validationSchema from './ContactSeoSettingValidation';
import { useFormik, Formik } from 'formik';
import { useStoreSettingData, useGetContactSettingData } from 'hooks';
import { TNButton } from 'common/components/TNButton';
import { confirmAlert } from 'react-confirm-alert';
import { AlertCommon } from 'common/components/AlertCommon';
import 'assets/scss/page/_generalsetting.scss';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { ContactTab } from './ContactTab';
const languages = ['en', 'sp'];

const ContactSeoSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);

  useGetContactSettingData(
    {
      tag: 'contact_setting_contact',
    },
    ({ data: setting }) => {
      // const dataStore = {
      //   contact_setting_contact_seo_en_title: setting.contact_setting_contact_seo_title['en'],
      //   contact_setting_contact_seo_sp_title: setting.contact_setting_contact_seo_title['sp'],
      //   contact_setting_contact_seo_en_description:
      //     setting.contact_setting_contact_seo_description['en'],
      //   contact_setting_contact_seo_sp_description:
      //     setting.contact_setting_contact_seo_description['sp'],
      // };
      // setContactSettingData(dataStore);

      languages.forEach((lang) => {
        formik.values[`contact_setting_contact_seo_${lang}_title`] =
          setting.contact_setting_contact_seo_title?.[lang] || '';
        formik.values[`contact_setting_contact_seo_${lang}_description`] =
          setting.contact_setting_contact_seo_description?.[lang] || '';
      });

      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    setTimeout(function () {
      window.location.reload();
    }, 500);
  });

  const formik = useFormik({
    initialValues: {
      // contact_setting_contact_seo_en_title:
      //   contactSettingData?.contact_setting_contact_seo_en_title || '',
      // contact_setting_contact_seo_sp_title:
      //   contactSettingData?.contact_setting_contact_seo_sp_title || '',
      // contact_setting_contact_seo_en_description:
      //   contactSettingData?.contact_setting_contact_seo_en_description || '',
      // contact_setting_contact_seo_sp_description:
      //   contactSettingData?.contact_setting_contact_seo_sp_description || '',

      ...languages.reduce((acc, lang) => {
        acc[`contact_setting_contact_seo_${lang}_title`] = '';
        acc[`contact_setting_contact_seo_${lang}_description`] = '';
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const transformedValues = {
        contact_setting_contact_seo_title: {},
        contact_setting_contact_seo_description: {},
      };

      languages.forEach((lang) => {
        transformedValues.contact_setting_contact_seo_title[lang] =
          values[`contact_setting_contact_seo_${lang}_title`] || '';
        transformedValues.contact_setting_contact_seo_description[lang] =
          values[`contact_setting_contact_seo_${lang}_description`] || '';
      });

      // const tranFormValue = {
      //   contact_setting_contact_seo_title: {
      //     ['en']: values.contact_setting_contact_seo_en_title,
      //     ['sp']: values.contact_setting_contact_seo_sp_title,
      //   },
      //   contact_setting_contact_seo_description: {
      //     ['en']: values.contact_setting_contact_seo_en_description,
      //     ['sp']: values.contact_setting_contact_seo_sp_description,
      //   },
      // };
      doStoreSettingData(tranFormValue);
    },
  });

  // useEffect(() => {
  //   if (contactSettingData) {
  //     formik.setValues({
  //       contact_setting_contact_seo_en_title:
  //         contactSettingData?.contact_setting_contact_seo_en_title,
  //       contact_setting_contact_seo_sp_title:
  //         contactSettingData?.contact_setting_contact_seo_sp_title,
  //       contact_setting_contact_seo_en_description:
  //         contactSettingData?.contact_setting_contact_seo_en_description,
  //       contact_setting_contact_seo_sp_description:
  //         contactSettingData?.contact_setting_contact_seo_sp_description,
  //     });
  //   }
  // }, [contactSettingData]);

  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  return (
    <>
      <ContactTab t={t} />
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.contact_setting_contact_seo_title_label')} [{lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`contact_setting_contact_seo_${lang}_title`] &&
                            formik.errors[`contact_setting_contact_seo_${lang}_title`]
                              ? 'form-field-error'
                              : formik.touched[`contact_setting_contact_seo_${lang}_title`] &&
                                !formik.errors[`contact_setting_contact_seo_${lang}_title`]
                              ? 'form-field-success'
                              : '')
                          }
                          type="text"
                          name={`contact_setting_contact_seo_${lang}_title`}
                          placeholder={t('page.contact_setting_contact_seo_title_placeholder')}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `contact_setting_contact_seo_${lang}_title`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`contact_setting_contact_seo_${lang}_title`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.contact_setting_contact_seo_en_title &&
                            formik.errors.contact_setting_contact_seo_en_title && (
                              <div>{t(formik.errors.contact_setting_contact_seo_en_title)}</div>
                            )} */}
                          {formik.touched[`contact_setting_contact_seo_${lang}_title`] &&
                          formik.errors[`contact_setting_contact_seo_${lang}_title`] ? (
                            <div>
                              {t(formik.errors[`contact_setting_contact_seo_${lang}_title`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_seo_title_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_seo_sp_title &&
                        formik.errors.contact_setting_contact_seo_sp_title
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_seo_sp_title
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="contact_setting_contact_seo_sp_title"
                      placeholder={t('page.contact_setting_contact_seo_title_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_seo_sp_title',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_seo_sp_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_seo_sp_title &&
                        formik.errors.contact_setting_contact_seo_sp_title && (
                          <div>{t(formik.errors.contact_setting_contact_seo_sp_title)}</div>
                        )}
                    </div>
                  </Form.Group> */}

                  {languages.map((lang) => (
                    <>
                      <Form.Group>
                        <Form.Label className="field-label field-label-top">
                          {t('page.contact_setting_contact_seo_description_label')} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            'form-field-height ' +
                            (formik.touched[`contact_setting_contact_seo_${lang}_description`] &&
                            formik.errors[`contact_setting_contact_seo_${lang}_description`]
                              ? 'form-field-error'
                              : formik.touched[`contact_setting_contact_seo_${lang}_description`] &&
                                !formik.errors[`contact_setting_contact_seo_${lang}_description`]
                              ? 'form-field-success'
                              : '')
                          }
                          as="textarea"
                          rows={3}
                          type="text"
                          name={`contact_setting_contact_seo_${lang}_description`}
                          placeholder={t(
                            'page.contact_setting_contact_seo_description_placeholder'
                          )}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `contact_setting_contact_seo_${lang}_description`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[`contact_setting_contact_seo_${lang}_description`]}
                        />
                        <div className="form-field-error-text">
                          {/* {formik.touched.contact_setting_contact_seo_en_description &&
                            formik.errors.contact_setting_contact_seo_en_description && (
                              <div>
                                {t(formik.errors.contact_setting_contact_seo_en_description)}
                              </div>
                            )} */}
                          {formik.touched[`contact_setting_contact_seo_${lang}_description`] &&
                          formik.errors[`contact_setting_contact_seo_${lang}_description`] ? (
                            <div>
                              {t(formik.errors[`contact_setting_contact_seo_${lang}_description`])}
                            </div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.contact_setting_contact_seo_description_label')} [SP]
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.contact_setting_contact_seo_sp_description &&
                        formik.errors.contact_setting_contact_seo_sp_description
                          ? 'form-field-error'
                          : formik.touched.contact_setting_contact_seo_sp_description
                          ? 'form-field-success'
                          : ''
                      }
                      as="textarea"
                      rows={3}
                      type="text"
                      name="contact_setting_contact_seo_sp_description"
                      placeholder={t('page.contact_setting_contact_seo_description_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'contact_setting_contact_seo_sp_description',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_setting_contact_seo_sp_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.contact_setting_contact_seo_sp_description &&
                        formik.errors.contact_setting_contact_seo_sp_description && (
                          <div>{t(formik.errors.contact_setting_contact_seo_sp_description)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton
                    type="submit"
                    isDirtyForm={formik.dirty && formik.dirty !== undefined ? 1 : 0}
                    loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
ContactSeoSetting.propTypes = {
  t: PropTypes.func,
};

export { ContactSeoSetting };

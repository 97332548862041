import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './text.scss';

const ShimmerText = ({ line, gap, center, className }) => {
  const renderLines = () => {
    const text_lines = [];

    for (let index = 0; index < line; index++) {
      text_lines.push(
        <div
          className={classNames({
            'shimmer shimmer-text-line': true,
            'ml-auto mr-auto': center,
          })}
          key={index}
        />
      );
    }

    return text_lines;
  };

  return (
    <div
      className={classNames({
        'grid shimmer-text': true,
        [`grid-gap-${gap}`]: gap,
        [className]: className,
      })}>
      {renderLines()}
    </div>
  );
};

ShimmerText.propTypes = {
  line: PropTypes.number,
  gap: PropTypes.oneOf([10, 15, 20, 30]),
  className: PropTypes.string,
  center: PropTypes.bool,
};

ShimmerText.defaultProps = {
  line: 5,
  gap: 15,
  center: false,
};

export default ShimmerText;

import React from 'react';
import PropTypes from 'prop-types';
import ShimmerTableRow from './TableRow';

const ShimmerTable = ({ row, col, header }) => {
  const renderRows = () => {
    const rows = [];

    for (let index = 0; index < row; index++) {
      rows.push(<ShimmerTableRow col={col} key={index} />);
    }

    return rows;
  };

  return (
    <div>
      {header && <ShimmerTableRow col={col} header={header} />}
      {renderRows()}
    </div>
  );
};

ShimmerTable.propTypes = {
  row: PropTypes.number,
  col: PropTypes.number,
  header: PropTypes.bool,
};

ShimmerTable.defaultProps = {
  row: 5,
  col: 5,
  header: false,
};

export default ShimmerTable;

import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeleteNotification, useGetNotifications, useReadNotification } from 'hooks';
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { faClose } from '@fortawesome/free-solid-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setFormatDateAndTime } from 'helpers';
import { useNotification } from '../../context/NotificationContext';

const Notifications = ({ t }) => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [unreadCount, setUnReadCount] = useState(0);
  const { updateNotificationCount } = useNotification();
  const { mutate: dodeleteclearnotification } = useDeleteNotification(
    (res) => {
      toast.success(res.message);
      refetch();
    },
    (err) => {
      toast.error(err.message);
    }
  );

  const { mutate: doreadnotification } = useReadNotification(
    (res) => {
      if (res.message) {
        toast.success(res.message);
      }
      refetch();
      updateNotificationCount(res.data);
    },
    (err) => {
      toast.error(err.message);
    }
  );

  const { refetch, isLoading } = useGetNotifications(
    [currentPage],
    (res) => {
      setNotificationData(res.data.notifications_list);
      setTotalPages(res.data.pagination.last_page);
      setUnReadCount(res.data.notification_count);
    },
    (error) => {
      if (error.data.redirect == true) {
        toast.error(error.message);
        navigate('/dashboard');
      }
    }
  );

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  const handleDeleteNotification = (_id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box" style={{ width: '890px' }}>
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>
                {_id
                  ? t('page.user_delete_notification_alert_popup_message')
                  : t('page.user_delete_notification_alert_popup_message_no_id')}
              </h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  const data = {
                    _id,
                    is_clear: !_id,
                  };
                  dodeleteclearnotification(data);
                  onClose();
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        );
      },
    });
  };
  const handleReadNotification = (_id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-box" style={{ width: '890px' }}>
            <FontAwesomeIcon
              className="alert-close"
              icon={faClose}
              onClick={() => {
                onClose();
              }}
            />
            <div className="alert-popup">
              <h2>{t('page.user_read_notification_alert_popup_message')}</h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  const data = {
                    _id,
                    is_clear: !_id,
                  };
                  doreadnotification(data);
                  onClose();
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <Row>
        {notificationData.length > 0 && (
          <Row className="px-md-5 m-2">
            <Col xs={12} className="d-flex justify-content-end">
              <Button
                type="button"
                className="secondary-remove-button m-2"
                onClick={() => handleDeleteNotification()}>
                {t('page.clear_notification_button')}
              </Button>
              {unreadCount > 0 && (
                <Button
                  type="button"
                  className="secondary-button m-2"
                  onClick={() => handleReadNotification()}>
                  {t('page.read_all_notification_button')}
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Row className="px-md-5 m-2">
          <Col xs={12} className="cards-list">
            {isLoading ? (
              // Loading state (e.g., spinner or loading text)
              <div className="card p-4 mt-4 text-center">
                {t('page.loading_text')} {/* You can replace this with a spinner if you prefer */}
              </div>
            ) : notificationData.length ? (
              notificationData.map((list, index) => (
                <Row
                  key={index}
                  className={`new-card p-4 mt-4 ${list.is_read === true ? 'read' : 'unread'}`}>
                  <Col md={9}>
                    <h5 className="notification-title">{list.title}</h5>
                    <p className="notification-description">{list.description}</p>
                  </Col>
                  <Col md={3}>
                    <div className="d-flex justify-content-end sub-title-text mt-2">
                      {setFormatDateAndTime(list.created_at)}
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                      <Button
                        type="button"
                        className="me-2 py-1"
                        onClick={() => {
                          const data = {
                            _id: list._id,
                          };
                          doreadnotification(data);
                          const { redirection_url, redirect_id } = list;
                          if (redirection_url === 'user-view') {
                            navigate(`/users/view/${redirect_id}`);
                          } else {
                            navigate(`/dashboard/${redirect_id}`);
                          }
                        }}>
                        {/* {t('page.view_button')} */}
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        className=" py-1 dlt-card-btn"
                        onClick={() => handleDeleteNotification(list._id)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              ))
            ) : (
              <div className={`card p-4 mt-4 text-center`}>
                {t('page.no_notification_found_text')}
              </div>
            )}
          </Col>
        </Row>
        {notificationData.length > 0 && (
          <Row className="m-2">
            <Col xs={12} className="d-flex justify-content-center align-items-center">
              <Button
                type="button"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                className="pagination-button">
                Previous
              </Button>
              <span className="mx-2">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                type="button"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className="pagination-button">
                Next
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    </>
  );
};

Notifications.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Notifications;

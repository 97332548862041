import React, { useReducer, useEffect } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Button, Table, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ShimmerTableCol } from 'common/shimmer';

const initialState = {
  queryPageIndex: 0,
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return { ...state, queryPageIndex: payload };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

const TNTable = ({
  columns,
  data,
  paginationData,
  t,
  onSelectPage,
  idName = '',
  pageIndexGet = 0,
  isLoading,
}) => {
  initialState.queryPageIndex = pageIndexGet;
  const [{ queryPageIndex }, dispatch] = useReducer(reducer, initialState);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: queryPageIndex },
      autoResetFilters: false,
      manualPagination: true,
      pageCount: paginationData.last_page,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onSelectPage(pageIndex);
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex, onSelectPage]);

  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <Table
        {...getTableProps()}
        responsive
        className="text-center table-sortable booking-list"
        id={idName}>
        <thead className="align-middle">
          {headerGroups.map((headerGroup, index) => (
            <tr key={index}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  className="table-ex-font-head"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={columnIndex}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isLoading ? (
            Array.from({ length: 2 }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((_, colIndex) => (
                  <td className="table-ex-font-body" key={colIndex}>
                    <ShimmerTableCol col={1} />
                  </td>
                ))}
              </tr>
            ))
          ) : firstPageRows.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>{t('page.no_data_found_table')}</td>
            </tr>
          ) : (
            firstPageRows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, cellIndex) => (
                    <td {...cell.getCellProps()} key={cellIndex}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <br />
      <div className="pagination_table">
        <Row className={firstPageRows.length === 0 ? 'd-none' : ''}>
          <Col lg={12} xs={12}>
            <div className="table_pagination">
              <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="mx-2">
                {'<<'}
              </Button>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage} className="mx-2">
                {'<'}
              </Button>
              <span>
                Page
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <Button onClick={() => nextPage()} disabled={!canNextPage} className="mx-2">
                {'>'}
              </Button>
              <Button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                className="mx-2">
                {'>>'}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

TNTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  paginationData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  idName: PropTypes.string,
  pageIndexGet: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

export { TNTable };

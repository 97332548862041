export const API_URL = process.env.REACT_APP_API_URL;
export const WEB_URL = process.env.REACT_APP_WEB_URL;
export * from './routes';
export * from './option';
export * as routes from './routes';

import {
  aboutNavItems,
  contactNavItems,
  faqNavItems,
  homeNavItems,
  mailNotificationNavItems,
  accountNavItems,
} from './navConfig';
export {
  aboutNavItems,
  contactNavItems,
  faqNavItems,
  homeNavItems,
  mailNotificationNavItems,
  accountNavItems,
};

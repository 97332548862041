import client from 'libs/HttpClient';
class SettingService {
  static getSettingData() {
    return client.get('setting/get');
  }
  static getAllSettingData() {
    return client.get('setting/all-data');
  }
  static storeSettingData(request) {
    return client.post('setting/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeCommonSettingData(request) {
    return client.post('setting/store-setting-data', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getMailNotificationData() {
    return client.get('setting/mail-notification');
  }
  static storeMailNotificationData(request) {
    return client.post('setting/mail-notification/store', request);
  }
  static getHomeSettingData({ params }) {
    return client.get(`setting/home-settings`, { params });
  }
  static deleteSettingData(request) {
    return client.post('setting/home-settings/delete', request);
  }
  static deleteOfficeSettingData(request) {
    return client.post('setting/office-settings/delete', request);
  }
  static getAboutSettingData({ params }) {
    return client.get(`setting/about-settings`, { params });
  }
  static getMotorcycleSettingData({ params }) {
    return client.get(`setting/motorcycle-settings`, { params });
  }
  static getContactSettingData({ params }) {
    return client.get(`setting/contact-settings`, { params });
  }
}

export { SettingService };

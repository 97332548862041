import { useMutation, useQuery } from 'react-query';

const { FeatureService } = require('api');
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListFeature = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['feature-list', params], () => FeatureService.listFeature({ params }), {
    onSuccess,
    keepPreviousData: true,
    onError,
  });
};

const useAddFeature = (onSuccess, onError = onDefaultError) => {
  return useMutation(FeatureService.addFeature, {
    onSuccess,
    onError,
  });
};

const useViewFeature = (_id, onSuccess, onError = onDefaultError) => {
  return useQuery('feature-view', () => FeatureService.viewFeature({ _id }), {
    onSuccess,
    onError,
  });
};

const useUpdateFeature = (onSuccess, onError = onDefaultError) => {
  return useMutation(FeatureService.updateFeature, {
    onSuccess,
    onError,
  });
};

const useStatusChangeFeature = (onSuccess, onError = onDefaultError) => {
  return useMutation(FeatureService.statusChangeFeature, {
    onSuccess,
    onError,
  });
};

const useDeleteFeature = (onSuccess, onError = onDefaultError) => {
  return useMutation(FeatureService.deleteFeature, {
    onSuccess,
    onError,
  });
};

const useGetServiceList = (onSuccess, onError = onDefaultError) => {
  return useQuery('service-list', FeatureService.getServiceList, {
    onSuccess,
    onError,
  });
};

export {
  useListFeature,
  useAddFeature,
  useViewFeature,
  useUpdateFeature,
  useStatusChangeFeature,
  useDeleteFeature,
  useGetServiceList,
};

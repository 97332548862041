import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { NotificationsService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetNotifications = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['notification-list', params],
    () => NotificationsService.getNotificationsData({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};
const useNotificationCount = (onSuccess, onError = onDefaultError) => {
  return useQuery('notification-counter', NotificationsService.notificationCount, {
    onSuccess,
    onError,
  });
};

const useDeleteNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.deleteNotification, {
    onSuccess,
    onError,
  });
};

const useReadNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.readNotification, {
    onSuccess,
    onError,
  });
};

export { useGetNotifications, useDeleteNotification, useNotificationCount, useReadNotification };

import * as Yup from 'yup';

const languages = ['en', 'sp']; // Example language codes (can be dynamically fetched)

let validationSchema = Yup.object({
  sections: Yup.array().of(
    Yup.object(
      languages.reduce((acc, lang) => {
        // Dynamically add validation for each language's title and section
        acc[`title_${lang}`] = Yup.string()
          .required(`page.home_setting_home_banner_title_required`)
          .max(60, `page.home_setting_home_banner_title_max`);

        acc[`section_${lang}`] = Yup.string()
          .required(`page.home_setting_home_banner_description_required`)
          .max(160, `page.home_setting_home_banner_description_max`);

        acc[`join_us_btn_txt_${lang}`] = Yup.string()
          .required(`page.home_setting_home_banner_join_us_btn_txt_required`)
          .max(100, `page.home_setting_home_banner_join_us_btn_txt_max`);
        return acc;
      }, {})
    ).shape({
      image: Yup.mixed().required('page.home_setting_home_banner_image_required'), // Image field validation for all languages
      join_us_link: Yup.string()
        .url('page.home_setting_home_banner_join_us_btn_link_invalid')
        .required('page.home_setting_home_banner_join_us_btn_link_required'),
    })
  ),

  // home_setting_home_banner_join_us_btn_link: Yup.string()
  //   .url('page.home_setting_home_banner_join_us_btn_link_invalid')
  //   .required('page.home_setting_home_banner_join_us_btn_link_required'),
});

export default validationSchema;

import client from 'libs/HttpClient';

class InsuranceService {
  static listInsurance({ params }) {
    return client.get(`insurance/list`, { params });
  }
  static addInsurance(request) {
    return client.post(`insurance/add`, request);
  }
  static viewInsurance(params) {
    return client.get(`insurance/get`, { params });
  }
  static updateInsurance(request) {
    return client.put(`insurance/edit`, request);
  }
  static deleteInsurance(params) {
    return client.delete(`insurance/delete`, { params });
  }
  static statusChangeInsurance(request) {
    return client.put(`insurance/updateStatus`, request);
  }
}
export { InsuranceService };

import React from 'react';
import { Link } from 'react-router-dom';

import { BLOG_CATEGORY, LIST, BLOG } from 'config';

const SidebarBlogsLinks = (props) => {
  const { t } = props;
  const blogCategoryClass = location.pathname.startsWith('/blog-category/') ? 'active' : '';
  const blogClass = location.pathname.startsWith('/blog/') ? 'active' : '';
  return (
    <>
      <ul className={`submenu collapse ${props.blog ? 'show' : ''}`}>
        <li>
          <Link
            className={blogCategoryClass}
            to={BLOG_CATEGORY + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_blog_category')}
          </Link>
        </li>
        <li>
          <Link
            className={blogClass}
            to={BLOG + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_blog')}
          </Link>
        </li>
      </ul>
    </>
  );
};

export { SidebarBlogsLinks };

import * as Yup from 'yup';
const languages = ['en', 'sp'];

let validationSchema = Yup.object({
  // about_setting_seo_en_title: Yup.string('page.general_setting_seo_title_required').required(
  //   'page.general_setting_seo_title_required'
  // ),
  // about_setting_seo_sp_title: Yup.string('page.general_setting_seo_title_required').required(
  //   'page.general_setting_seo_title_required'
  // ),
  // about_setting_seo_en_description: Yup.string(
  //   'page.general_setting_seo_description_required'
  // ).required('page.general_setting_seo_description_required'),
  // about_setting_seo_sp_description: Yup.string(
  //   'page.general_setting_seo_description_required'
  // ).required('page.general_setting_seo_description_required'),

  ...languages.reduce((acc, lang) => {
    acc[`about_setting_seo_${lang}_title`] = Yup.string('page.general_setting_seo_title_required')
      .required('page.general_setting_seo_title_required')
      .trim('page.general_setting_seo_title_required');

    acc[`about_setting_seo_${lang}_description`] = Yup.string(
      'page.general_setting_seo_description_required'
    )
      .required('page.general_setting_seo_description_required')
      .trim('page.general_setting_seo_description_required');
    return acc;
  }, {}),
});
export default validationSchema;
